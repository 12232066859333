import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm, ValidationError } from '@formspree/react';
import Select from 'react-select';
import { useLanguage } from 'Contexts/LanguageContext';

// Extended translations including room type labels, examples arrays, and SEO-friendly text
const translations = {
  english: {
    page_title: 'Taki Rental Pricing | Futuristic & Sophisticated Event Spaces',
    meta_description:
      'Explore transparent rental pricing for Taki Taki (Cave Room or Secret Room) and Taki House. Book elegant event spaces with clear pricing details for Fridays and Saturdays.',
    meta_keywords:
      'Taki Rental, Taki Taki, Taki House, Event Space, Cave Room, Secret Room, Rental Pricing, Futuristic Venue, Elegant Venue, Event Booking',
    canonical_url: 'https://yourwebsite.com/taki-rental-pricing',
    hero_title: 'Experience the Future of Event Spaces',
    hero_description:
      'Book between Taki Taki (choose Cave Room or Secret Room) and Taki House for your next event. Our transparent pricing and futuristic design guarantee an elegant and sophisticated experience.',
    contact_title: 'Get in Touch',
    rental_section_title: 'Rental Pricing Details',
    base_period: '19:00 – 22:00',
    additional_rate_label: 'Each additional hour after 22:00',
    time_range: 'Time Range',
    price: 'Price',
    // Taki Taki details
    takitaki_title: 'Taki Taki',
    select_room_type: 'Select Room Type',
    cave_room: 'Cave Room',
    secret_room: 'Secret Room',
    takitaki_friday_desc: 'Fridays: Base 1000 zł, extra 300 zł per hour after 22:00.',
    takitaki_friday_examples: [
      { time: '19:00–23:00', price: '1300 zł' },
      { time: '19:00–00:00', price: '1600 zł' },
      { time: '19:00–03:00', price: '2500 zł' },
    ],
    takitaki_saturday_desc: 'Saturdays: Base 2000 zł, extra 600 zł per hour after 22:00.',
    takitaki_saturday_examples: [
      { time: '19:00–23:00', price: '2600 zł' },
      { time: '19:00–00:00', price: '3200 zł' },
      { time: '19:00–03:00', price: '5000 zł' },
    ],
    // Taki House details
    takihouse_title: 'Taki House',
    takihouse_friday_desc: 'Fridays: Base 500 zł, extra 150 zł per hour after 22:00.',
    takihouse_friday_examples: [
      { time: '19:00–23:00', price: '650 zł' },
      { time: '19:00–00:00', price: '800 zł' },
      { time: '19:00–03:00', price: '1250 zł' },
    ],
    takihouse_saturday_desc: 'Saturdays: Base 1000 zł, extra 300 zł per hour after 22:00.',
    takihouse_saturday_examples: [
      { time: '19:00–23:00', price: '1300 zł' },
      { time: '19:00–00:00', price: '1600 zł' },
      { time: '19:00–03:00', price: '2500 zł' },
    ],
    select_end_time: 'Select Rental End Time',
    total_price: 'Total Price',
    contact_form_title: 'Contact Us',
    contact_form_submit: 'Send Message',
    contact_form_success: "Thank you for reaching out! We'll get back to you soon.",
    final_cta_title: 'Ready to Book Your Space?',
    call_now: 'Call Now',
    direct_email: 'Email Us',
    direct_whatsapp: 'Chat on WhatsApp',
  },
  spanish: {
    page_title: 'Precios de Alquiler Taki | Espacios para Eventos Futuristas y Sofisticados',
    meta_description:
      'Descubre los precios de alquiler para Taki Taki (Sala de la Cueva o Sala Secreta) y Taki House. Reserva espacios elegantes para eventos con detalles claros de precios para viernes y sábados.',
    meta_keywords:
      'Alquiler Taki, Taki Taki, Taki House, Espacio para Eventos, Sala de la Cueva, Sala Secreta, Precios de Alquiler, Lugar Futurista, Evento Elegante, Reserva de Eventos',
    canonical_url: 'https://yourwebsite.com/taki-rental-pricing',
    hero_title: 'Vive el Futuro de los Espacios para Eventos',
    hero_description:
      'Reserva entre Taki Taki (elige Sala de la Cueva o Sala Secreta) y Taki House para tu próximo evento. Nuestra política de precios transparente y diseño futurista garantizan una experiencia elegante y sofisticada.',
    contact_title: 'Contáctanos',
    rental_section_title: 'Detalles de Precios de Alquiler',
    base_period: '19:00 – 22:00',
    additional_rate_label: 'Cada hora adicional después de las 22:00',
    time_range: 'Horario',
    price: 'Precio',
    takitaki_title: 'Taki Taki',
    select_room_type: 'Selecciona el tipo de sala',
    cave_room: 'Sala de la Cueva',
    secret_room: 'Sala Secreta',
    takitaki_friday_desc: 'Viernes: Base 1000 zł, extra 300 zł por hora adicional.',
    takitaki_friday_examples: [
      { time: '19:00–23:00', price: '1300 zł' },
      { time: '19:00–00:00', price: '1600 zł' },
      { time: '19:00–03:00', price: '2500 zł' },
    ],
    takitaki_saturday_desc: 'Sábados: Base 2000 zł, extra 600 zł por hora adicional.',
    takitaki_saturday_examples: [
      { time: '19:00–23:00', price: '2600 zł' },
      { time: '19:00–00:00', price: '3200 zł' },
      { time: '19:00–03:00', price: '5000 zł' },
    ],
    takihouse_title: 'Taki House',
    takihouse_friday_desc: 'Viernes: Base 500 zł, extra 150 zł por hora adicional.',
    takihouse_friday_examples: [
      { time: '19:00–23:00', price: '650 zł' },
      { time: '19:00–00:00', price: '800 zł' },
      { time: '19:00–03:00', price: '1250 zł' },
    ],
    takihouse_saturday_desc: 'Sábados: Base 1000 zł, extra 300 zł por hora adicional.',
    takihouse_saturday_examples: [
      { time: '19:00–23:00', price: '1300 zł' },
      { time: '19:00–00:00', price: '1600 zł' },
      { time: '19:00–03:00', price: '2500 zł' },
    ],
    select_end_time: 'Selecciona la hora de finalización',
    total_price: 'Precio total',
    contact_form_title: 'Contáctanos',
    contact_form_submit: 'Enviar Mensaje',
    contact_form_success: '¡Gracias por contactarnos! Nos pondremos en contacto contigo pronto.',
    final_cta_title: '¿Listo para Reservar tu Espacio?',
    call_now: 'Llama Ahora',
    direct_email: 'Envíanos un Email',
    direct_whatsapp: 'Chatea por WhatsApp',
  },
  polish: {
    page_title: 'Cennik Wynajmu Taki | Przestrzenie Wydarzeń Przyszłościowych i Eleganckich',
    meta_description:
      'Poznaj cennik wynajmu dla Taki Taki (Sala Jaskiniowa lub Sala Tajemnicza) oraz Taki House. Zarezerwuj eleganckie przestrzenie na wydarzenia z przejrzystymi cenami na piątki i soboty.',
    meta_keywords:
      'Wynajem Taki, Taki Taki, Taki House, Przestrzeń Wydarzeń, Sala Jaskiniowa, Sala Tajemnicza, Cennik Wynajmu, Przestrzeń Futurystyczna, Elegancki Lokal, Rezerwacja Wydarzeń',
    canonical_url: 'https://yourwebsite.com/taki-rental-pricing',
    hero_title: 'Doświadcz Przyszłości Przestrzeni Wydarzeń',
    hero_description:
      'Wybierz między Taki Taki (wybierz Sala Jaskiniowa lub Sala Tajemnicza) a Taki House na swoje kolejne wydarzenie. Transparentny cennik i futurystyczny design gwarantują eleganckie i nowoczesne doświadczenie.',
    contact_title: 'Skontaktuj się z Nami',
    rental_section_title: 'Szczegóły Cennika Wynajmu',
    base_period: '19:00 – 22:00',
    additional_rate_label: 'Każda dodatkowa godzina po 22:00',
    time_range: 'Przedział czasowy',
    price: 'Cena',
    takitaki_title: 'Taki Taki',
    select_room_type: 'Wybierz typ sali',
    cave_room: 'Sala Jaskiniowa',
    secret_room: 'Sala Tajemnicza',
    takitaki_friday_desc: 'Piątki: Base 1000 zł, dodatkowo 300 zł za każdą godzinę po 22:00.',
    takitaki_friday_examples: [
      { time: '19:00–23:00', price: '1300 zł' },
      { time: '19:00–00:00', price: '1600 zł' },
      { time: '19:00–03:00', price: '2500 zł' },
    ],
    takitaki_saturday_desc: 'Soboty: Base 2000 zł, dodatkowo 600 zł za każdą godzinę po 22:00.',
    takitaki_saturday_examples: [
      { time: '19:00–23:00', price: '2600 zł' },
      { time: '19:00–00:00', price: '3200 zł' },
      { time: '19:00–03:00', price: '5000 zł' },
    ],
    takihouse_title: 'Taki House',
    takihouse_friday_desc: 'Piątki: Base 500 zł, dodatkowo 150 zł za każdą godzinę po 22:00.',
    takihouse_friday_examples: [
      { time: '19:00–23:00', price: '650 zł' },
      { time: '19:00–00:00', price: '800 zł' },
      { time: '19:00–03:00', price: '1250 zł' },
    ],
    takihouse_saturday_desc: 'Soboty: Base 1000 zł, dodatkowo 300 zł za każdą godzinę po 22:00.',
    takihouse_saturday_examples: [
      { time: '19:00–23:00', price: '1300 zł' },
      { time: '19:00–00:00', price: '1600 zł' },
      { time: '19:00–03:00', price: '2500 zł' },
    ],
    select_end_time: 'Wybierz godzinę zakończenia',
    total_price: 'Łączna cena',
    contact_form_title: 'Skontaktuj się z Nami',
    contact_form_submit: 'Wyślij Wiadomość',
    contact_form_success: 'Dziękujemy za wiadomość! Wkrótce się odezwiemy.',
    final_cta_title: 'Gotowy na Rezerwację Przestrzeni?',
    call_now: 'Zadzwoń teraz',
    direct_email: 'Napisz do nas',
    direct_whatsapp: 'Czat przez WhatsApp',
  },
};

// Options for the rental end time (from 10PM to 5AM)
const endTimeOptions = [
  { value: 22, label: '10:00 PM' },
  { value: 23, label: '11:00 PM' },
  { value: 0, label: '12:00 AM' },
  { value: 1, label: '1:00 AM' },
  { value: 2, label: '2:00 AM' },
  { value: 3, label: '3:00 AM' },
  { value: 4, label: '4:00 AM' },
  { value: 5, label: '5:00 AM' },
];

// Component to display an examples table given an array of example objects
interface Example {
  time: string;
  price: string;
}
interface ExamplesTableProps {
  examples: Example[];
  t: typeof translations['english'];
}
const ExamplesTable: React.FC<ExamplesTableProps> = ({ examples, t }) => {
  return (
    <table className="mt-2 w-full text-xs border border-gray-600">
      <thead>
        <tr className="bg-gray-700">
          <th className="px-2 py-1 border-r border-gray-600">{t.time_range}</th>
          <th className="px-2 py-1">{t.price}</th>
        </tr>
      </thead>
      <tbody>
        {examples.map((ex, idx) => (
          <tr key={idx} className="odd:bg-gray-800 even:bg-gray-700">
            <td className="px-2 py-1 border-r border-gray-600">{ex.time}</td>
            <td className="px-2 py-1">{ex.price}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// Modified Pricing Calculator that now includes a day selector.
// Monday–Friday use the Friday pricing and Saturday–Sunday use the Saturday pricing.
interface PricingCalculatorProps {
  fridayPricing: {
    base: number;
    extraRate: number;
    examples: Example[];
  };
  saturdayPricing: {
    base: number;
    extraRate: number;
    examples: Example[];
  };
  t: typeof translations['english'];
}
const PricingCalculator: React.FC<PricingCalculatorProps> = ({ fridayPricing, saturdayPricing, t }) => {
  const dayOptions = [
    { value: 'monday', label: 'Monday' },
    { value: 'tuesday', label: 'Tuesday' },
    { value: 'wednesday', label: 'Wednesday' },
    { value: 'thursday', label: 'Thursday' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' },
    { value: 'sunday', label: 'Sunday' },
  ];
  
  const [selectedDay, setSelectedDay] = useState(dayOptions.find(opt => opt.value === 'friday'));
  const [selectedEndTime, setSelectedEndTime] = useState(endTimeOptions[0]);

  // Monday-Friday (including Friday) use the Friday pricing;
  // Saturday and Sunday use the Saturday pricing.
  const isFridayPricing = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].includes(selectedDay?.value || '');
  const pricing = isFridayPricing ? fridayPricing : saturdayPricing;

  // Calculate extra hours and total cost (logic remains the same)
  const selectedHour = selectedEndTime.value < 22 ? selectedEndTime.value + 24 : selectedEndTime.value;
  const extraHours = selectedHour - 22;
  const total = pricing.base + extraHours * pricing.extraRate;

  return (
    <div className="mt-4 p-4 bg-gray-800 bg-opacity-80 rounded-lg shadow-inner">
      <div className="mb-4">
        <label className="block mb-2 font-medium">Select Day:</label>
        <Select
          value={selectedDay}
          onChange={(option) => setSelectedDay(option as any)}
          options={dayOptions}
          className="text-black"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2 font-medium">{t.select_end_time}:</label>
        <Select
          value={selectedEndTime}
          onChange={(option) => setSelectedEndTime(option as any)}
          options={endTimeOptions}
          className="text-black"
        />
      </div>
      <div className="mt-3">
        <span className="font-semibold">{t.total_price}:</span> {total} zł
      </div>
      <div className="mt-4">
        <ExamplesTable examples={pricing.examples} t={t} />
      </div>
    </div>
  );
};

// Contact Form component using Formspree with optimized id "xbldppyg"
interface ContactFormProps {
  t: typeof translations['english'];
}
const ContactForm: React.FC<ContactFormProps> = ({ t }) => {
  const [state, handleSubmit] = useForm('xbldppyg');
  if (state.succeeded) {
    return <p className="text-green-400 mb-4">{t.contact_form_success}</p>;
  }
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        id="name"
        type="text"
        name="name"
        placeholder="Your Name"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <input
        id="email"
        type="email"
        name="email"
        placeholder="Your Email"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <textarea
        id="message"
        name="message"
        placeholder="Your Message"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        rows={3}
      ></textarea>
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button
        type="submit"
        disabled={state.submitting}
        className="w-full px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
      >
        {t.contact_form_submit}
      </button>
    </form>
  );
};

const TakiRentalPricing: React.FC = () => {
  const { language } = useLanguage();
  const lang = (['english', 'spanish', 'polish'].includes(language)
    ? language
    : 'english') as 'english' | 'spanish' | 'polish';
  const t = translations[lang];

  // Room type options for Taki Taki (language-specific)
  const takiTakiRoomOptions = [
    { value: 'cave', label: t.cave_room },
    { value: 'secret', label: t.secret_room },
  ];

  // State for the selected room type in the Taki Taki card
  const [roomType, setRoomType] = useState(takiTakiRoomOptions[0]);

  // Direct contact links
  const emailLink = `mailto:takitakikatowice@gmail.com?subject=Rental%20Inquiry&body=${encodeURIComponent(
    "Hello, I'm interested in renting one of your spaces."
  )}`;
  const whatsappLink = `https://wa.me/48451110303?text=${encodeURIComponent(
    "Hello, I'm interested in renting one of your spaces."
  )}`;

  return (
    <>
      <Helmet>
        <title>{t.page_title}</title>
        <meta name="description" content={t.meta_description} />
        <meta name="keywords" content={t.meta_keywords} />
        <link rel="canonical" href={t.canonical_url} />
      </Helmet>
      <div className="bg-gray-900 text-white">
        {/* Hero Section */}
        <section className="py-20 text-center px-4 pt-40">
          <h1 className="text-4xl font-bold mb-4">{t.hero_title}</h1>
          <p className="max-w-2xl mx-auto text-lg">{t.hero_description}</p>
          <button
            onClick={() => {}}
            className="mt-6 px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
          >
            {t.contact_title}
          </button>
        </section>

        {/* Rental Pricing Section */}
        <section className="py-12 px-4">
          <h2 className="text-3xl font-bold text-center mb-8">{t.rental_section_title}</h2>
          <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Taki Taki Pricing Card */}
            <div className="p-6 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg border border-indigo-600 rounded-xl transition transform hover:scale-105 shadow-lg">
              <h3 className="text-2xl font-bold mb-4 text-center">{t.takitaki_title}</h3>
              {/* Room Type Selector */}
              <div className="mb-4">
                <label className="block mb-2 font-medium">{t.select_room_type}:</label>
                <Select
                  value={roomType}
                  onChange={(option) => setRoomType(option as any)}
                  options={takiTakiRoomOptions}
                  className="text-black"
                />
              </div>
              <div className="space-y-4">
                <p className="text-sm">
                  {t.takitaki_friday_desc} (Monday–Friday)
                </p>
                <p className="text-sm">
                  {t.takitaki_saturday_desc} (Saturday–Sunday)
                </p>
                <PricingCalculator
                  fridayPricing={{ base: 1000, extraRate: 300, examples: t.takitaki_friday_examples }}
                  saturdayPricing={{ base: 2000, extraRate: 600, examples: t.takitaki_saturday_examples }}
                  t={t}
                />
              </div>
            </div>

            {/* Taki House Pricing Card */}
            <div className="p-6 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg border border-indigo-600 rounded-xl transition transform hover:scale-105 shadow-lg">
              <h3 className="text-2xl font-bold mb-4 text-center">{t.takihouse_title}</h3>
              <div className="space-y-4">
                <p className="text-sm">
                  {t.takihouse_friday_desc} (Monday–Friday)
                </p>
                <p className="text-sm">
                  {t.takihouse_saturday_desc} (Saturday–Sunday)
                </p>
                <PricingCalculator
                  fridayPricing={{ base: 500, extraRate: 150, examples: t.takihouse_friday_examples }}
                  saturdayPricing={{ base: 1000, extraRate: 300, examples: t.takihouse_saturday_examples }}
                  t={t}
                />
              </div>
            </div>
          </div>
        </section>

        {/* Final Call-To-Action */}
        <section className="py-12 px-4 bg-gray-900">
          <h2 className="text-4xl font-bold text-center mb-8">{t.final_cta_title}</h2>
          <div className="flex flex-col md:flex-row justify-center items-center gap-6">
            <button
              onClick={() => {}}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
            >
              {t.contact_title}
            </button>
            <a
              href={emailLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-green-500 via-green-600 to-green-700 text-white font-bold hover:opacity-90 transition"
            >
              {t.direct_email}
            </a>
            <a
              href={whatsappLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white font-bold hover:opacity-90 transition"
            >
              {t.direct_whatsapp}
            </a>
          </div>
        </section>

        {/* Contact Form Modal */}
        {/* (Modal implementation can be added here as needed) */}
      </div>
    </>
  );
};

export default TakiRentalPricing;

// src/PartyTicket/SongRequests/index.tsx
import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import LowerBar from 'PartyTicket/LowerBar';
import React, { useEffect, useState } from 'react';
import { FaCoins, FaEdit, FaHistory, FaShieldAlt, FaTicketAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import Bio from './Bio';
import Gallery from './Gallery';

interface DetailCardProps {
  username?: string;
}

const translations = {
  english: {
    error: 'Error',
    accessDenied: 'Access Denied',
    noPermission: 'You do not have permission to access the admin panel.',
    pleaseTryAgainLater: 'Please try again later 😞',
    name: 'Name',
    bio: 'Bio',
    saveProfile: 'Save Profile',
    success: 'Success',
    profileUpdated: 'Profile updated successfully',
    updateError: 'Please try again later 😞',
    coins: 'Total Coins',
    coinsHistory: 'Coins History',
    coinsFetchError: 'Failed to fetch coins. Please try again later.',
    tickets: 'My Tickets',
  },
  spanish: {
    error: 'Error',
    accessDenied: 'Acceso Denegado',
    noPermission: 'No tienes permiso para acceder al panel de administración.',
    pleaseTryAgainLater: 'Por favor, inténtelo de nuevo más tarde 😞',
    name: 'Nombre',
    bio: 'Biografía',
    saveProfile: 'Guardar perfil',
    success: 'Éxito',
    profileUpdated: 'Perfil actualizado con éxito',
    updateError: 'Por favor, inténtelo de nuevo más tarde 😞',
    coins: 'Puntos Totales',
    coinsHistory: 'Historial de Puntos',
    coinsFetchError: 'No se pudieron obtener los puntos. Por favor, inténtelo más tarde.',
    tickets: 'Mis Entradas',
  },
  polish: {
    error: 'Błąd',
    accessDenied: 'Brak dostępu',
    noPermission: 'Nie masz uprawnień do dostępu do panelu administratora.',
    pleaseTryAgainLater: 'Proszę spróbować ponownie później 😞',
    name: 'Imię',
    bio: 'Biografia',
    saveProfile: 'Zapisz profil',
    success: 'Sukces',
    profileUpdated: 'Profil zaktualizowany pomyślnie',
    updateError: 'Spróbuj ponownie później 😞',
    coins: 'Suma Punktów',
    coinsHistory: 'Historia Punktów',
    coinsFetchError: 'Nie udało się pobrać punktów. Spróbuj ponownie później.',
    tickets: 'Moje Bilety',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const DetailCard: React.FC<DetailCardProps> = ({ username }) => {
  const [userData, setUserData] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [coins, setCoins] = useState(0); // State to store total coins
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const t = translations[language as Language];

  useEffect(() => {
    getUser(username);
    getUserCoins(); // Fetch user coins when component loads
  }, [username]);

  // Fetch user details
  const getUser = async (username?: string) => {
    setLoading(true);
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    const endpoint = username ? `public/user/${username}` : 'client/user';
    await fetch(`${APIBase}/${endpoint}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        setUserData(responseJson);
        setName(responseJson.name || '');
        setBio(responseJson.bio || '');
        if (!responseJson.name) {
          setIsModalOpen(true);
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.pleaseTryAgainLater,
          showConfirmButton: false,
          timer: 3000,
        });
      });
    setLoading(false);
  };

  // Fetch user coins
  const getUserCoins = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/coin`, data) // Assuming /coin is the route
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      })
      .then((responseJson) => {
        setCoins(responseJson.totalCoins); // Update the coins state with totalCoins
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.coinsFetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  // Navigate to coins history page
  const goToCoinsHistory = () => {
    navigate('/app/coins');
  };

  // Save profile function
  const saveProfile = async () => {
    const data: RequestInit = {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, bio }),
    };
    await fetch(`${APIBase}/client/user`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.success,
            text: t.profileUpdated,
            icon: 'success',
            timer: 2000,
          }).then(() => {
            setIsModalOpen(false);
            getUser(username);
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.updateError,
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  return (
    <div className="text-white min-h-screen mt-24 px-4">
      {loading ? (
        <div className="w-full flex items-center justify-center">
          <StageSpinner color="#ffffff" loading={loading} size={50} />
        </div>
      ) : (
        <>
          <div className="flex items-center mb-4">
            <img
              src={userData?.profilePicture || userData?.media[0]}
              alt={`${userData?.name}'s profile`}
              className="w-20 h-20 rounded-full mr-4 shrink-0"
            />
            <div>
              <div className="flex flex-row items-center justify-start mb-3">
                <h2 className="text-lg font-semibold">{userData?.name}</h2>
                <button
                  className="bg-gradient-to-r mx-5 from-purple-500 to-indigo-500 text-white p-1.5 rounded-full flex items-center justify-center shadow-lg hover:bg-gradient-to-r hover:from-purple-600 hover:to-indigo-600 transition-all duration-300 ease-in-out"
                  onClick={() => navigate('/app/profile/edit')}
                >
                  <FaEdit className="w-5 h-5" />
                </button>
                {userData?.role === 'admin' && (
                  <button
                    className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white p-1.5 rounded-full flex items-center justify-center shadow-lg hover:bg-gradient-to-r hover:from-cyan-600 hover:to-blue-600 transition-all duration-300 ease-in-out"
                    onClick={() => navigate('/app/admin')}
                  >
                    <FaShieldAlt className="w-5 h-5" />
                  </button>
                )}
              </div>
              <Bio content={userData?.bio} getUser={getUser} />
            </div>
          </div>

          {/* Display Total Coins and My Tickets button */}
          <div className="flex flex-row items-center mb-4">
            <FaCoins className="w-6 h-6 text-yellow-500 mr-2" />
            <h3 className="text-lg font-semibold">
              {t.coins}: {coins}
            </h3>
            <button
              className="bg-gradient-to-r mx-5 from-orange-500 to-red-500 text-white p-1.5 rounded-full flex items-center justify-center shadow-lg hover:bg-gradient-to-r hover:from-orange-600 hover:to-red-600 transition-all duration-300 ease-in-out"
              onClick={goToCoinsHistory}
            >
              <FaHistory className="m-1" />
            </button>
            {/* My Tickets Button */}
            <button
              onClick={() => navigate('/app/partyticket/my-tickets')}
              className="flex flex-col items-center justify-center bg-gradient-to-r from-blue-500 to-teal-500 hover:from-blue-600 hover:to-teal-600 transition-all p-3 sm:p-4 rounded-lg shadow-xl hover:scale-105 transform"
            >
              <FaTicketAlt className="text-2xl text-white mb-1" />
              <span className="text-white text-sm font-semibold">{t.tickets || 'My Tickets'}</span>
            </button>
          </div>

          <Gallery user={userData} getUser={getUser} />
          <LowerBar />

          {/* Modal for entering name and bio */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            contentLabel="Enter Name and Bio"
            className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center"
            overlayClassName="fixed inset-0 bg-black bg-opacity-75"
          >
            <div className="w-full max-w-md bg-black p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl mb-4 text-white">Enter your details</h2>
              <div className="mb-4">
                <label className="block text-gray-400 mb-2">{t.name}</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-3 py-2 mb-4 text-gray-900 rounded focus:outline-none focus:ring-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-400 mb-2">{t.bio}</label>
                <textarea
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  className="w-full px-3 py-2 mb-4 text-gray-900 rounded focus:outline-none focus:ring-2 resize-none"
                  style={{ overflow: 'hidden' }}
                />
              </div>
              <button
                onClick={saveProfile}
                className="w-full py-2 bg-blue-600 text-white rounded-lg border border-solid border-white shadow-md focus:outline-none hover:bg-blue-500"
              >
                {t.saveProfile}
              </button>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default DetailCard;

// src/PartyTicket/SongRequests/components/DJRequestModal.tsx
import { faCoins, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
interface DJRequestModalProps {
  youtubeLink: string;
  message: string;
  onYoutubeLinkChange: (value: string) => void;
  onMessageChange: (value: string) => void;
  videoId: string | null;
  onClose: () => void;
  onSubmit: (e: React.FormEvent) => void;
  t: any;
  currentCoins: number;
}

const DJRequestModal: React.FC<DJRequestModalProps> = ({
  youtubeLink,
  message,
  onYoutubeLinkChange,
  onMessageChange,
  videoId,
  onClose,
  onSubmit,
  t,
  currentCoins,
}) => {
    const navigate = useNavigate();
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4">
      <div className="bg-gray-900 p-6 rounded-lg w-full max-w-md">
        <h3 className="text-2xl font-bold mb-4">{t.requestASong || 'Request a Song'}</h3>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label className="block mb-1">{t.videoUrl || 'YouTube Link'}</label>
            <input
              type="text"
              value={youtubeLink}
              onChange={(e) => onYoutubeLinkChange(e.target.value)}
              placeholder={t.videoUrl || 'Enter YouTube link'}
              className="w-full p-2 rounded bg-gray-700"
            />
            {videoId && (
              <div className="mt-2">
                <img
                  src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                  alt="YouTube Preview"
                  className="w-20 h-20 object-cover rounded-lg border-2 border-gray-700 shadow-lg"
                />
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-1">{t.messageLabel || 'Message'}</label>
            <textarea
              value={message}
              onChange={(e) => onMessageChange(e.target.value)}
              placeholder={t.messageLabel || 'Enter your message'}
              className="w-full p-2 rounded bg-gray-700"
            />
          </div>
          {currentCoins < 5000 && (
            <div className="mb-4">
              <p className="text-sm text-yellow-400">
                You currently have {currentCoins} coins. 5000 coins are required.
              </p>
            </div>
          )}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="flex items-center bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 px-4 py-2 rounded shadow-lg"
            >
              <FontAwesomeIcon icon={faTimes} className="mr-2" />
              {t.cancel}
            </button>
            {currentCoins < 5000 ? (
              <button
                type="button"
                onClick={() => navigate('/app/partyticket/buycoins')}
                className="flex items-center bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-500 hover:to-yellow-700 px-4 py-2 rounded shadow-lg"
              >
                <FontAwesomeIcon icon={faCoins} className="mr-2" />
                {t.buyCoins || 'Buy Coins'}
              </button>
            ) : (
              <button
                type="submit"
                className="flex items-center bg-gradient-to-r from-indigo-500 to-indigo-700 hover:from-indigo-600 hover:to-indigo-800 px-4 py-2 rounded shadow-lg"
              >
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                {t.submit || 'Submit'}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DJRequestModal;

import { useForm, ValidationError } from '@formspree/react';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// Translations for the intelligence Platform page
const translations = {
  english: {
    page_title: 'intelligence Platform – Empowering Venues with Advanced Solutions',
    meta_description:
      'Our intelligence Platform combines cutting-edge features: Robot Host, Taki Ticketing, and DJ Requests to revolutionize your venue. More than a software, we are an intelligence and knowledge platform.',
    hero_title: 'Welcome to Our Intelligence Platform',
    hero_subtitle: 'More than a software, we are an intelligence and knowledge platform.',
    // Robot Host Section
    robotHost_title: 'Robot Host – Automated Karaoke System',
    robotHost_summary: 'Get Robot Host starting from 200 PLN',
    robotHost_videoURL: 'https://www.youtube.com/embed/R-KQvqBeMuU',
    benefit_section_title: 'Benefits',
    benefit1_title: 'Fully Automated Karaoke',
    benefit1_desc: 'Eliminate manual song selection and empower your venue with a self-operating system.',
    benefit2_title: 'Enhanced Guest Experience',
    benefit2_desc: 'Deliver a seamless and engaging experience with interactive, automated karaoke sessions.',
    benefit3_title: 'Maximize Revenue',
    benefit3_desc: 'Boost your profits with features like coin-based song skipping and dynamic queue management.',
    // Taki Ticketing Section
    ticketing_title: 'Taki Ticketing – Seamless Payment Integration',
    ticketing_summary:
      'We directly connect your business to Stripe so you have full control over payments. We only charge 1 % per transaction on top of that.',
    // DJ Requests Section
    djRequests_title: 'DJ Requests & Engagement',
    djRequests_summary: 'We implement DJ requests, TV messages, DJ battles, and contests in your club for free.',
    // FAQ Section
    faq_title: 'Frequently Asked Questions',
    faq_1_question: 'How does Robot Host help increase revenue?',
    faq_1_answer:
      'By automating karaoke operations and enabling coin-based song skipping, Robot Host boosts your venue’s revenue.',
    faq_2_question: 'How does Taki Ticketing work?',
    faq_2_answer:
      'It connects your business directly to Stripe with full payment control, charging only 1 % per transaction on top of that.',
    faq_3_question: 'What benefits do DJ Requests bring?',
    faq_3_answer:
      'They enhance guest engagement with interactive song requests, TV messages, and contests—at no additional cost.',
    // Final Summary Section
    final_summary: 'More than a software, we are an intelligence and knowledge platform.',
    // Contact and CTA
    contact_button: 'Contact Us',
    contact_title: 'Request Information',
    contact_form_title: 'Get In Touch',
    contact_form_submit: 'Submit',
    contact_form_success: "Thank you for contacting us! We'll be in touch shortly.",
    call_now: 'Call Now',
    direct_email: 'Email Us',
    direct_whatsapp: 'Chat on WhatsApp',
    learn_more: 'Learn More',
  },
  spanish: {
    page_title: 'Plataforma Inteligente – Potenciando Locales con Soluciones Avanzadas',
    meta_description:
      'Nuestra Plataforma Inteligente combina características de vanguardia: Robot Host, Taki Ticketing y Solicitudes DJ para revolucionar tu local. Más que un software, somos una plataforma de inteligencia y conocimiento.',
    hero_title: 'Bienvenido a Nuestra Plataforma Inteligente',
    hero_subtitle: 'Más que un software, somos una plataforma de inteligencia y conocimiento.',
    // Robot Host Section
    robotHost_title: 'Robot Host – Sistema Automatizado de Karaoke',
    robotHost_summary: 'Obtén Robot Host a partir de 200 PLN',
    robotHost_videoURL: 'https://www.youtube.com/embed/R-KQvqBeMuU',
    benefit_section_title: 'Beneficios',
    benefit1_title: 'Karaoke Completamente Automatizado',
    benefit1_desc: 'Elimina la selección manual de canciones y potencia tu local con un sistema que se autogestiona.',
    benefit2_title: 'Experiencia Mejorada para los Invitados',
    benefit2_desc: 'Ofrece una experiencia fluida y atractiva con sesiones de karaoke interactivas y automatizadas.',
    benefit3_title: 'Maximiza tus Ingresos',
    benefit3_desc:
      'Incrementa tus ganancias con funciones como saltar canciones mediante monedas y una gestión dinámica de la cola.',
    // Taki Ticketing Section
    ticketing_title: 'Taki Ticketing – Integración de Pagos Sin Esfuerzo',
    ticketing_summary:
      'Conectamos tu negocio directamente a Stripe para que tengas control total sobre los pagos. Solo cobramos un 1% por transacción adicional.',
    // DJ Requests Section
    djRequests_title: 'Solicitudes DJ y Compromiso',
    djRequests_summary:
      'Implementamos solicitudes DJ, mensajes en TV, batallas de DJ y concursos en tu club de forma gratuita.',
    // FAQ Section
    faq_title: 'Preguntas Frecuentes',
    faq_1_question: '¿Cómo ayuda Robot Host a incrementar los ingresos?',
    faq_1_answer:
      'Automatizando las operaciones de karaoke y permitiendo saltos de canciones mediante monedas, Robot Host incrementa los ingresos de tu local.',
    faq_2_question: '¿Cómo funciona Taki Ticketing?',
    faq_2_answer:
      'Conecta tu negocio directamente a Stripe con control total de los pagos, cobrando solo un 1% por transacción adicional.',
    faq_3_question: '¿Qué beneficios aportan las Solicitudes DJ?',
    faq_3_answer:
      'Mejoran la interacción de los invitados con solicitudes de canciones, mensajes en TV y concursos, sin costo adicional.',
    // Final Summary Section
    final_summary: 'Más que un software, somos una plataforma de inteligencia y conocimiento.',
    // Contact and CTA
    contact_button: 'Contáctanos',
    contact_title: 'Solicita Información',
    contact_form_title: 'Ponte en Contacto',
    contact_form_submit: 'Enviar',
    contact_form_success: '¡Gracias por contactarnos! Nos comunicaremos contigo pronto.',
    call_now: 'Llama Ahora',
    direct_email: 'Envíanos un Email',
    direct_whatsapp: 'Chatea por WhatsApp',
    learn_more: 'Más Información',
  },
  polish: {
    page_title: 'Inteligentna Platforma – Wzmacnianie Lokalów dzięki Zaawansowanym Rozwiązaniom',
    meta_description:
      'Nasza Inteligentna Platforma łączy najnowocześniejsze funkcje: Robot Host, Taki Ticketing oraz Żądania DJ, aby zrewolucjonizować Twój lokal. To więcej niż oprogramowanie – to platforma inteligencji i wiedzy.',
    hero_title: 'Witamy w Naszej Inteligentnej Platformie',
    hero_subtitle: 'To więcej niż oprogramowanie – to platforma inteligencji i wiedzy.',
    // Robot Host Section
    robotHost_title: 'Robot Host – Zautomatyzowany System Karaoke',
    robotHost_summary: 'Uzyskaj Robot Host już od 200 PLN',
    robotHost_videoURL: 'https://www.youtube.com/embed/R-KQvqBeMuU',
    benefit_section_title: 'Korzyści',
    benefit1_title: 'Całkowicie Zautomatyzowane Karaoke',
    benefit1_desc: 'Usuń ręczny wybór utworów i wzmocnij swój lokal dzięki systemowi, który działa samodzielnie.',
    benefit2_title: 'Lepsze Doświadczenie Gości',
    benefit2_desc: 'Zapewnij płynne i angażujące doświadczenie dzięki interaktywnym, zautomatyzowanym sesjom karaoke.',
    benefit3_title: 'Maksymalizuj Zyski',
    benefit3_desc:
      'Zwiększ swoje dochody dzięki funkcjom, takim jak pomijanie utworów za pomocą monet oraz dynamiczne zarządzanie kolejką.',
    // Taki Ticketing Section
    ticketing_title: 'Taki Ticketing – Bezproblemowa Integracja Płatności',
    ticketing_summary:
      'Łączymy Twój biznes bezpośrednio ze Stripe, abyś miał pełną kontrolę nad płatnościami. Pobieramy tylko 1% za każdą dodatkową transakcję.',
    // DJ Requests Section
    djRequests_title: 'Żądania DJ i Zaangażowanie',
    djRequests_summary:
      'Wdrażamy żądania DJ, wiadomości na TV, bitwy DJ oraz konkursy w Twoim klubie bez dodatkowych kosztów.',
    // FAQ Section
    faq_title: 'Najczęściej Zadawane Pytania',
    faq_1_question: 'Jak Robot Host pomaga zwiększyć przychody?',
    faq_1_answer:
      'Dzięki automatyzacji operacji karaoke i możliwości pomijania utworów za pomocą monet, Robot Host zwiększa przychody Twojego lokalu.',
    faq_2_question: 'Jak działa Taki Ticketing?',
    faq_2_answer:
      'Łączy Twój biznes bezpośrednio ze Stripe, zapewniając pełną kontrolę nad płatnościami, pobierając tylko 1% dodatkowo za każdą transakcję.',
    faq_3_question: 'Jakie korzyści przynoszą Żądania DJ?',
    faq_3_answer:
      'Poprawiają interakcję gości, umożliwiając wysyłanie żądań utworów, wiadomości na TV i organizację konkursów – bez dodatkowych kosztów.',
    // Final Summary Section
    final_summary: 'To więcej niż oprogramowanie – to platforma inteligencji i wiedzy.',
    // Contact and CTA
    contact_button: 'Skontaktuj się',
    contact_title: 'Zapytaj o Informacje',
    contact_form_title: 'Skontaktuj się z nami',
    contact_form_submit: 'Wyślij',
    contact_form_success: 'Dziękujemy za kontakt! Wkrótce się odezwiemy.',
    call_now: 'Zadzwoń teraz',
    direct_email: 'Napisz do nas',
    direct_whatsapp: 'Czat przez WhatsApp',
    learn_more: 'Dowiedz się więcej',
  },
};

//
// Custom hook to get window width
//
function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return width;
}

//
// Custom slider arrow components with bigger, elegant, futuristic styling
//
const CustomPrevArrow: React.FC<any> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '100%',
        left: '-6px',
        zIndex: 2,
        background: '#111111',
        opacity: '0.5',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-left text-sm text-white" />
    </div>
  );
};

const CustomNextArrow: React.FC<any> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-next-arrow`}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '100%',
        right: '-6px',
        zIndex: 2,
        background: '#111111',
        opacity: '0.5',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-right text-sm text-white" />
    </div>
  );
};

//
// Settings for the slider (used on mobile)
//
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};

//
// Reusable FadeInSection component using Intersection Observer
//
const FadeInSection: React.FC<{ className?: string; children: React.ReactNode }> = ({ children, className = '' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 },
    );
    if (ref.current) observer.observe(ref.current);
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []);
  return (
    <div ref={ref} className={`${className} ${isVisible ? 'fade-in-up' : 'opacity-0'}`}>
      {children}
    </div>
  );
};

//
// CSS Animations
//
const styles = `
  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  .fade-in-up {
    animation: fadeInUp 1s ease-out forwards;
  }
  .custom-prev-arrow, .custom-next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .slick-prev:before,
.slick-next:before {
  display: none;
}
`;

//
// ContactForm component using Formspree (new Formspree ID)
//
interface ContactFormProps {
  t: typeof translations.english;
}
const ContactForm: React.FC<ContactFormProps> = ({ t }) => {
  const [state, handleSubmit] = useForm('xjkyevbd');
  if (state.succeeded) {
    return <p className="text-green-400 mb-4">{t.contact_form_success}</p>;
  }
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        id="name"
        type="text"
        name="name"
        placeholder="Your Name"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <input
        id="email"
        type="email"
        name="email"
        placeholder="Your Email"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <textarea
        id="message"
        name="message"
        placeholder="Your Message"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        rows={3}
        required
      ></textarea>
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button
        type="submit"
        disabled={state.submitting}
        className="w-full px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
      >
        {t.contact_form_submit}
      </button>
    </form>
  );
};

//
// FAQItem component with plus/minus microinteraction
//
type FAQItemProps = { question: string; answer: string };
const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className="p-4 bg-gray-800 border border-gray-600 rounded-lg cursor-pointer transition-all hover:bg-gray-700"
      onClick={() => setOpen(!open)}
    >
      <div className="flex justify-between items-center">
        <p className="font-semibold text-xl">{question}</p>
        <i className={`fas ${open ? 'fa-minus' : 'fa-plus'} text-2xl`}></i>
      </div>
      {open && <p className="mt-2 text-gray-300">{answer}</p>}
    </div>
  );
};

//
// Main intelligence Platform Page component
//
const IntelligencePlatformPage: React.FC = () => {
  const { language } = useLanguage();
  const windowWidth = useWindowWidth();
  const lang = (['english', 'spanish', 'polish'].includes(language) ? language : 'english') as
    | 'english'
    | 'spanish'
    | 'polish';
  const t = translations[lang] as typeof translations.english;

  // Array of Robot Host video URLs
  const robotHostVideos = [
    'https://www.youtube.com/embed/zFaT-yxKT00?autoplay=1&mute=1&loop=1&playlist=zFaT-yxKT00',
    'https://www.youtube.com/embed/yDgdRdb_0uE?autoplay=1&mute=1&loop=1&playlist=yDgdRdb_0uE',
    'https://www.youtube.com/embed/rAtFqW72oYo?autoplay=1&mute=1&loop=1&playlist=rAtFqW72oYo',
  ];

  // Array of Benefits
  const benefits = [
    { title: t.benefit1_title, desc: t.benefit1_desc },
    { title: t.benefit2_title, desc: t.benefit2_desc },
    { title: t.benefit3_title, desc: t.benefit3_desc },
  ];

  return (
    <>
      <Helmet>
        <title>{t.page_title}</title>
        <meta name="description" content={t.meta_description} />
        <link rel="canonical" href="https://yourwebsite.com/intelligence-platform" />
      </Helmet>
      <style>{styles}</style>
      <div className="bg-black text-white font-sans">
        {/* Hero Section with 3 parallel background videos, min-height = 100vh */}
        <section className="relative h-screen">
          <div className="absolute inset-0 flex">
            <video
              className="w-1/3 object-cover"
              autoPlay
              muted
              loop
              playsInline
              src="https://imgur.com/heADSmI.mp4"
            ></video>
            <video
              className="w-1/3 object-cover"
              autoPlay
              muted
              loop
              playsInline
              src="https://imgur.com/nApwlPg.mp4"
            ></video>
            <video
              className="w-1/3 object-cover"
              autoPlay
              muted
              loop
              playsInline
              src="https://imgur.com/o1cotFy.mp4"
            ></video>
          </div>
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="relative z-10 flex flex-col items-center justify-center h-full px-4 text-center">
            <FadeInSection>
              <h1 className="text-5xl font-bold mb-4">{t.hero_title}</h1>
              <p className="text-2xl mb-8">{t.hero_subtitle}</p>
            </FadeInSection>
          </div>
        </section>

        {/* Robot Host Section (min-h-screen with vertical padding) */}
        <section className="min-h-screen py-12 bg-black text-white px-4">
          <FadeInSection>
            <h2 className="text-4xl font-bold mb-8 text-center">{t.robotHost_title}</h2>
            {windowWidth < 768 ? (
              <Slider {...sliderSettings}>
                {robotHostVideos.map((url, idx) => (
                  <div key={idx} className="px-2">
                    <div className="w-full aspect-[9/16]">
                      <iframe
                        src={url}
                        title={`Robot Host Video ${idx + 1}`}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        className="w-full h-full rounded-xl shadow-xl"
                      ></iframe>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="flex flex-col md:flex-row justify-center items-center gap-8">
                {robotHostVideos.map((url, idx) => (
                  <div key={idx} className="w-60 md:w-72 aspect-[9/16]">
                    <iframe
                      src={url}
                      title={`Robot Host Video ${idx + 1}`}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                      className="w-full h-full rounded-xl shadow-xl"
                    ></iframe>
                  </div>
                ))}
              </div>
            )}
            <div className="mt-8 text-center">
              <Link
                to="/robot-host"
                className="inline-block px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold uppercase tracking-wider hover:opacity-90 transition transform hover:scale-105"
              >
                {t.learn_more}
              </Link>
            </div>
            {/* Benefits Grid */}
            <div className="mt-8">
              <h3 className="text-3xl font-bold text-center mb-4">{t.benefit_section_title}</h3>
              {windowWidth < 768 ? (
                <Slider {...sliderSettings}>
                  {benefits.map((benefit, idx) => (
                    <div key={idx} className="px-2">
                      <div className="p-4 bg-gray-800 rounded-lg shadow-md">
                        <h4 className="text-xl font-bold mb-2">{benefit.title}</h4>
                        <p className="text-lg">{benefit.desc}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
                  {benefits.map((benefit, idx) => (
                    <div key={idx} className="p-4 bg-gray-800 rounded-lg shadow-md">
                      <h4 className="text-xl font-bold mb-2">{benefit.title}</h4>
                      <p className="text-lg">{benefit.desc}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </FadeInSection>
        </section>

        {/* Taki Ticketing Section (Full Screen, Vertical Reel; content centered) */}
        <section className="flex flex-col items-center justify-center text-center px-4 bg-gray-800">
          <FadeInSection>
            <h2 className="text-4xl font-bold my-8 pt-10">{t.ticketing_title}</h2>
            <div className="w-full flex justify-center">
              <div className="w-full max-w-xs md:max-w-sm aspect-[9/16]">
                <video
                  className="w-full h-full object-cover rounded-xl shadow-xl"
                  autoPlay
                  muted
                  loop
                  playsInline
                  src="https://imgur.com/heADSmI.mp4"
                ></video>
              </div>
            </div>
            <p className="text-2xl mt-4 max-w-2xl">{t.ticketing_summary}</p>
            <div className="mt-6 text-center pb-8">
              <Link
                to="/taki-ticketing"
                className="inline-block px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold uppercase tracking-wider hover:opacity-90 transition transform hover:scale-105"
              >
                {t.learn_more}
              </Link>
            </div>
          </FadeInSection>
        </section>

        {/* DJ Requests Section (Full Screen, Vertical Reel; content centered) */}
        <section className="flex flex-col items-center justify-center text-center px-4 py-4 bg-gray-700">
          <FadeInSection>
            <h2 className="text-4xl font-bold mb-4">{t.djRequests_title || 'DJ Requests & Engagement'}</h2>
            <div className="w-full flex justify-center">
              <div className="w-full max-w-xs md:max-w-sm aspect-[9/16]">
                <video
                  className="w-full h-full object-cover rounded-xl shadow-xl"
                  autoPlay
                  muted
                  loop
                  playsInline
                  src="https://imgur.com/tz66b98.mp4"
                ></video>
              </div>
            </div>
            <p className="text-2xl mt-4 max-w-2xl">{t.djRequests_summary}</p>
            <div className="mt-6 text-center">
              <Link
                to="/djrequest"
                className="inline-block px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold uppercase tracking-wider hover:opacity-90 transition transform hover:scale-105"
              >
                {t.learn_more}
              </Link>
            </div>
          </FadeInSection>
        </section>

        {/* FAQ Section – height fits content */}
        <section className="py-12 px-4 bg-gray-800">
          <FadeInSection>
            <h2 className="text-3xl font-bold text-center mb-8">{t.faq_title}</h2>
            <div className="max-w-4xl mx-auto space-y-6">
              <FAQItem question={t.faq_1_question} answer={t.faq_1_answer} />
              <FAQItem question={t.faq_2_question} answer={t.faq_2_answer} />
              <FAQItem question={t.faq_3_question} answer={t.faq_3_answer} />
            </div>
          </FadeInSection>
        </section>

        {/* Final Summary Section with Parallel Background Videos (Full Screen) */}
        <section className="relative h-screen">
          <div className="absolute inset-0 flex">
            <video
              className="w-1/3 object-cover"
              autoPlay
              muted
              loop
              playsInline
              src="https://imgur.com/LCotEr4.mp4"
            ></video>
            <video
              className="w-1/3 object-cover"
              autoPlay
              muted
              loop
              playsInline
              src="https://imgur.com/SkQSLtO.mp4"
            ></video>
            <video
              className="w-1/3 object-cover"
              autoPlay
              muted
              loop
              playsInline
              src="https://imgur.com/y22DC6G.mp4"
            ></video>
          </div>
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="relative z-10 flex flex-col items-center justify-center h-full px-4 text-center">
            <FadeInSection>
              <h2 className="text-4xl font-bold mb-4">{t.final_summary}</h2>
              <button
                onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}
                className="mt-4 px-8 py-4 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold uppercase tracking-wider hover:opacity-90 transition transform hover:scale-105"
              >
                {t.contact_button}
              </button>
            </FadeInSection>
          </div>
        </section>

        {/* Contact Form Section at the bottom – height fits content */}
        <section className="py-12 px-4 bg-gray-900">
          <FadeInSection>
            <h2 className="text-4xl font-bold text-center mb-4">{t.contact_form_title}</h2>
            <div className="max-w-md mx-auto">
              <ContactForm t={t} />
            </div>
          </FadeInSection>
        </section>
      </div>
    </>
  );
};

export default IntelligencePlatformPage;

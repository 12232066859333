// src/PartyTicket/SongRequests/index.tsx
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DJAccessRequestItem from './components/DJAccessRequestItem';
import DJRequestItem from './components/DJRequestItem';
import DJRequestModal from './components/DJRequestModal';
import {
  useAcceptDJAccessRequest,
  useAcceptDJRequest,
  useDJAccessRequests,
  useDJRequests,
  useDJs,
  useRejectDJAccessRequest,
  useRejectDJRequest,
  useSubmitDJAccessRequest,
  useSubmitDJRequest,
} from './hooks/useDJRequests';
import { useUserProfile } from './hooks/useUserProfile';
import translations from './translations';
import { extractVideoId } from './utils/youtubeHelpers';

const DJSongRequestsScreen: React.FC = () => {
  const { currentToken } = useAuth();
  const { data: userData } = useUserProfile(currentToken || '');
  const { data: requests, isLoading } = useDJRequests(currentToken || '');
  const { data: accessRequests, isLoading: accessIsLoading } = useDJAccessRequests(currentToken || '');
  const { data: djs, isLoading: djsLoading } = useDJs(currentToken || '');

  const acceptMutation = useAcceptDJRequest(currentToken || '');
  const rejectMutation = useRejectDJRequest(currentToken || '');
  const submitMutation = useSubmitDJRequest(currentToken || '');
  const submitDJAccessMutation = useSubmitDJAccessRequest(currentToken || '');
  const acceptDJAccessMutation = useAcceptDJAccessRequest(currentToken || '');
  const rejectDJAccessMutation = useRejectDJAccessRequest(currentToken || '');

  // State for non-DJ users
  const [selectedDJ, setSelectedDJ] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Common states
  const [djAccessModalOpen, setDjAccessModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [message, setMessage] = useState('');
  // For DJs, default to "requested"; for non-DJs, default to "djs" tab.
  const [activeTab, setActiveTab] = useState<string>(userData && userData.roles?.includes('dj') ? 'requested' : 'djs');

  const navigate = useNavigate();
  const { language } = useLanguage();
  const t = translations[language as keyof typeof translations];
  const videoId = extractVideoId(youtubeLink);

  // Determine if the current user is a DJ (assuming userData.roles is an array)
  const isDJ = userData?.roles && userData.roles.includes('dj');

  console.log(requests);
  // For non-DJ users: filter my requests (where requester equals current user ID)
  const myRequests = requests?.filter((req: any) => req.requester._id === userData?._id) || [];
  const mySortedRequests =
    myRequests?.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) || [];

  // For non-DJ users: filter DJs based on search term
  const filteredDJs = djs?.filter((dj: any) => dj.username.toLowerCase().includes(searchTerm.toLowerCase())) || [];

  // Filter song requests by status for DJ users
  const requested = isDJ
    ? requests?.filter((req: any) => req.status === 'pending' && req.recipient === userData?._id) || []
    : [];
  const accepted = isDJ
    ? requests?.filter((req: any) => req.status === 'accepted' && req.recipient === userData?._id) || []
    : [];
  const rejected = isDJ
    ? requests?.filter((req: any) => req.status === 'rejected' && req.recipient === userData?._id) || []
    : [];

  // Handlers for DJ song requests (for DJ users)
  const handleAccept = async (requestId: string) => {
    try {
      await acceptMutation.mutateAsync({ requestId });
      Swal.fire(t.success, t.djSongRequestAccepted || 'DJ song request accepted.', 'success');
    } catch (error: any) {
      Swal.fire(t.error, error.message || t.failedToAcceptRequest || 'Failed to accept request.', 'error');
    }
  };

  const handleReject = async (requestId: string) => {
    try {
      await rejectMutation.mutateAsync({ requestId });
      Swal.fire(t.success, t.djSongRequestRejected || 'DJ song request rejected.', 'success');
    } catch (error: any) {
      Swal.fire(t.error, error.message || t.failedToRejectRequest || 'Failed to reject request.', 'error');
    }
  };

  useEffect(() => {
    if (userData) {
      setActiveTab(userData.roles?.includes('dj') ? 'requested' : 'djs');
    }
  }, [userData]);

  // Handler for submitting a new DJ song request
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!youtubeLink || !message) {
      Swal.fire(t.error, t.fieldsRequired, 'error');
      return;
    }
    if (!videoId) {
      Swal.fire(t.error, 'Please enter a valid YouTube link.', 'error');
      return;
    }
    try {
      if (!isDJ) {
        console.log('selectedDJ> ', selectedDJ);
        if (!selectedDJ) {
          Swal.fire(t.error, 'Please select a DJ.', 'error');
          return;
        }
        // Include recipient for non-DJ users
        await submitMutation.mutateAsync({ youtubeLink, message, recipient: selectedDJ });
      } else {
        await submitMutation.mutateAsync({ youtubeLink, message });
      }
      Swal.fire(t.success, t.djSongRequestSubmitted || 'DJ song request submitted.', 'success');
      setModalOpen(false);
      setYoutubeLink('');
      setMessage('');
      setSelectedDJ(null);
    } catch (error: any) {
      Swal.fire(t.error, error.message || t.failedToSubmitRequest || 'Failed to submit request.', 'error');
    }
  };

  // Handler for submitting a DJ access request (for non-DJ users)
  const handleDJAccessSubmit = async () => {
    try {
      await submitDJAccessMutation.mutateAsync();
      Swal.fire(t.success, t.djAccessRequestSubmitted || 'DJ access request submitted.', 'success');
      setDjAccessModalOpen(false);
    } catch (error: any) {
      Swal.fire(
        t.error,
        error.message || t.failedToSubmitDJAccessRequest || 'Failed to submit DJ access request.',
        'error',
      );
    }
  };

  // Handlers for approving/rejecting DJ access requests (admin only)
  const handleAccessApprove = async (requestId: string) => {
    try {
      await acceptDJAccessMutation.mutateAsync({ requestId });
      Swal.fire(t.success, t.djAccessRequestApproved || 'DJ access request approved.', 'success');
    } catch (error: any) {
      Swal.fire(t.error, error.message || t.failedToAcceptRequest || 'Failed to approve request.', 'error');
    }
  };

  const handleAccessReject = async (requestId: string) => {
    try {
      await rejectDJAccessMutation.mutateAsync({ requestId });
      Swal.fire(t.success, t.djAccessRequestRejected || 'DJ access request rejected.', 'success');
    } catch (error: any) {
      Swal.fire(t.error, error.message || t.failedToRejectRequest || 'Failed to reject request.', 'error');
    }
  };

  return (
    <div className="w-full p-4 text-white bg-gradient-to-b from-gray-900 to-black overflow-auto pt-28 pb-32">
      {/* Header: Return and Join as DJ */}
      <div className="mb-4 flex justify-between items-center">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-white hover:text-neonMagenta transition-colors duration-200"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          {t.return || 'Return'}
        </button>
        {!isDJ && (
          <button
            onClick={() => setDjAccessModalOpen(true)}
            className="flex items-center sm:w-auto bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800 px-4 py-2 rounded shadow-lg transition-all duration-200"
          >
            {t.joinAsDJ || 'Join as DJ'}
          </button>
        )}
      </div>
      {/* Tabs Navigation */}
      <div className="w-full mb-4">
        <div className="flex">
          {isDJ ? (
            <>
              <button
                onClick={() => setActiveTab('requested')}
                className={`flex-1 text-center sm:flex-none px-4 py-2 ${
                  activeTab === 'requested' ? 'border-b-2 border-blue-500' : ''
                }`}
              >
                {t.requested || 'Requested'}
              </button>
              <button
                onClick={() => setActiveTab('accepted')}
                className={`flex-1 text-center sm:flex-none px-4 py-2 ${
                  activeTab === 'accepted' ? 'border-b-2 border-blue-500' : ''
                }`}
              >
                {t.accepted || 'Accepted'}
              </button>
              <button
                onClick={() => setActiveTab('rejected')}
                className={`flex-1 text-center sm:flex-none px-4 py-2 ${
                  activeTab === 'rejected' ? 'border-b-2 border-blue-500' : ''
                }`}
              >
                {t.rejected || 'Rejected'}
              </button>
              {userData && userData.role === 'admin' && (
                <button
                  onClick={() => setActiveTab('access')}
                  className={`flex-1 text-center sm:flex-none px-4 py-2 ${
                    activeTab === 'access' ? 'border-b-2 border-blue-500' : ''
                  }`}
                >
                  {t.djAccessRequests || 'DJ Access Requests'}
                </button>
              )}
            </>
          ) : (
            <>
              <button
                onClick={() => setActiveTab('djs')}
                className={`flex-1 text-center sm:flex-none px-4 py-2 ${
                  activeTab === 'djs' ? 'border-b-2 border-blue-500' : ''
                }`}
              >
                {t.djs || 'DJs'}
              </button>
              <button
                onClick={() => setActiveTab('myRequests')}
                className={`flex-1 text-center sm:flex-none px-4 py-2 ${
                  activeTab === 'myRequests' ? 'border-b-2 border-blue-500' : ''
                }`}
              >
                {t.myRequests || 'My Requests'}
              </button>
            </>
          )}
        </div>
      </div>

      {/* Tab Content */}
      <div>
        {isDJ ? (
          <>
            {activeTab === 'requested' &&
              (isLoading ? (
                <p>{t.loading}</p>
              ) : requested.length === 0 ? (
                <p>{t.noPendingRequests || 'No pending requests.'}</p>
              ) : (
                <ul>
                  {requested.map((req: any) => (
                    <DJRequestItem
                      key={req._id}
                      request={req}
                      onAccept={handleAccept}
                      onReject={handleReject}
                      isDJ={isDJ}
                      t={t}
                    />
                  ))}
                </ul>
              ))}
            {activeTab === 'accepted' &&
              (isLoading ? (
                <p>{t.loading}</p>
              ) : accepted.length === 0 ? (
                <p>{t.noAcceptedRequests || 'No accepted requests.'}</p>
              ) : (
                <ul>
                  {accepted.map((req: any) => (
                    <DJRequestItem key={req._id} request={req} t={t} />
                  ))}
                </ul>
              ))}
            {activeTab === 'rejected' &&
              (isLoading ? (
                <p>{t.loading}</p>
              ) : rejected.length === 0 ? (
                <p>{t.noRejectedRequests || 'No rejected requests.'}</p>
              ) : (
                <ul>
                  {rejected.map((req: any) => (
                    <DJRequestItem key={req._id} request={req} t={t} />
                  ))}
                </ul>
              ))}
            {activeTab === 'access' &&
              userData &&
              userData.role === 'admin' &&
              (accessIsLoading ? (
                <p>{t.loading}</p>
              ) : accessRequests?.length === 0 ? (
                <p>{t.noAccessRequests || 'No DJ access requests.'}</p>
              ) : (
                <ul>
                  {accessRequests.map((req: any) => (
                    <DJAccessRequestItem
                      key={req._id}
                      request={req}
                      t={t}
                      onApprove={handleAccessApprove}
                      onReject={handleAccessReject}
                    />
                  ))}
                </ul>
              ))}
          </>
        ) : (
          <>
            {activeTab === 'djs' && (
              <>
                {/* Full-width search bar on mobile */}
                <div className="mb-4 w-full">
                  <input
                    type="text"
                    placeholder={t.search || 'Search DJs...'}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full px-4 py-2 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                {djsLoading ? (
                  <p>{t.loading}</p>
                ) : filteredDJs.length > 0 ? (
                  <ul className="space-y-4">
                    {filteredDJs.map((dj: any) => (
                      <li
                        key={dj._id}
                        className="flex flex-col sm:flex-row items-center px-4 py-4 bg-gradient-to-r from-purple-700 to-pink-700 rounded-lg shadow-lg transition-transform duration-200 ease-in-out hover:scale-105"
                      >
                        <div className="flex items-center space-x-4 w-full">
                          {dj.media && dj.media.length > 0 && (
                            <img
                              src={dj.media[0]}
                              alt={`${dj.username} profile`}
                              className="w-16 h-16 sm:w-20 sm:h-20 rounded-full object-cover border-2 border-white transition-transform duration-200 ease-in-out hover:scale-105"
                            />
                          )}
                          <div className="flex-1">
                            <p className="font-bold text-neonMagenta text-lg">{dj.name || dj.username}</p>
                            {dj.bio && <p className="text-sm text-gray-300">{dj.bio}</p>}
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            setSelectedDJ(dj._id);
                            setModalOpen(true);
                          }}
                          className="mt-3 sm:mt-0 ml-auto px-4 py-2 bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800 rounded-full text-sm text-white shadow-lg transition-transform duration-200 ease-in-out hover:scale-105 active:scale-95"
                        >
                          {t.requestASong || 'Request Song'}
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>{t.noDJs || 'No DJs found.'}</p>
                )}
              </>
            )}
            {activeTab === 'myRequests' &&
              (isLoading ? (
                <p>{t.loading}</p>
              ) : mySortedRequests.length === 0 ? (
                <p>{t.noRequests || 'No requests found.'}</p>
              ) : (
                <ul>
                  {mySortedRequests.map((req: any) => (
                    <DJRequestItem key={req._id} request={req} t={t} />
                  ))}
                </ul>
              ))}
          </>
        )}
      </div>

      {/* Modal for submitting a new DJ song request */}
      {modalOpen && (
        <DJRequestModal
          youtubeLink={youtubeLink}
          message={message}
          onYoutubeLinkChange={setYoutubeLink}
          onMessageChange={setMessage}
          videoId={videoId}
          onClose={() => setModalOpen(false)}
          onSubmit={handleSubmit}
          t={t}
          currentCoins={userData?.totalCoins || 0}
        />
      )}

      {/* Modal for requesting DJ access */}
      {djAccessModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg text-black max-w-sm mx-auto">
            <h2 className="text-xl font-bold mb-4">{t.djAccessRequestTitle || 'DJ Access Request'}</h2>
            <p>{t.djAccessRequestDescription || 'Please complete your profile and add at least a picture.'}</p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setDjAccessModalOpen(false)}
                className="bg-blue-500 text-white px-4 py-2 rounded transition-colors duration-200"
              >
                {t.cancel || 'Cancel'}
              </button>
              <button
                onClick={handleDJAccessSubmit}
                className="bg-green-500 text-white px-4 py-2 rounded transition-colors duration-200"
              >
                {t.confirmDJAccess || 'Confirm Request'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DJSongRequestsScreen;

// src/PartyTicket/SongRequests/components/DJRequestItem.tsx
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { extractVideoId } from '../utils/youtubeHelpers';

interface DJRequestItemProps {
  request: any;
  t: any;
  isDJ?: boolean;
  onAccept?: (id: string) => void;
  onReject?: (id: string) => void;
}

const DJRequestItem: React.FC<DJRequestItemProps> = ({ request, t, isDJ = false, onAccept, onReject }) => {
  const reqVideoId = extractVideoId(request.youtubeLink);

  return (
    <li className="bg-black bg-opacity-70 border border-gray-600 p-3 sm:p-4 mb-4 sm:mb-6 rounded-lg shadow-xl flex items-center transform transition-transform duration-200 hover:scale-105">
      {reqVideoId && (
        <img
          src={`https://img.youtube.com/vi/${reqVideoId}/hqdefault.jpg`}
          alt="YouTube Thumbnail"
          className="w-16 h-16 sm:w-20 sm:h-20 object-cover rounded-lg border-2 border-gray-500 mr-3 sm:mr-4 shadow-xl transition-transform duration-200 hover:scale-105"
        />
      )}
      <div className="flex-grow">
        <p className="font-bold text-pink-400 text-base sm:text-lg">{request.requester.username}</p>
        <p className="text-sm sm:text-base">
          <a
            href={request.youtubeLink}
            target="_blank"
            rel="noopener noreferrer"
            className="underline hover:text-blue-300 transition-colors duration-200"
          >
            {request.youtubeLink}
          </a>
        </p>
        <p className="mt-1 text-gray-200 text-sm sm:text-base">{request.message}</p>
        {/* Status tag */}
        <p className="mt-1">
          <span
            className={`inline-block px-2 py-1 text-xs sm:text-sm font-semibold rounded transition-transform duration-200 ${
              request.status === 'pending'
                ? 'bg-yellow-400 text-black drop-shadow-[0_0_8px_rgba(255,215,0,0.8)]'
                : request.status === 'accepted'
                ? 'bg-green-500 text-white drop-shadow-[0_0_8px_rgba(0,255,0,0.8)]'
                : request.status === 'rejected'
                ? 'bg-red-500 text-white drop-shadow-[0_0_8px_rgba(255,0,0,0.8)]'
                : 'bg-gray-500 text-white drop-shadow'
            } transition-colors duration-200`}
          >
            {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
          </span>
        </p>
      </div>
      {isDJ && onAccept && onReject && (
        <div className="flex flex-col space-y-2">
          <button
            onClick={() => onAccept(request._id)}
            className="flex items-center bg-gradient-to-r from-green-600 to-green-800 hover:from-green-700 hover:to-green-900 px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm text-white shadow-xl transition-transform duration-200 ease-in-out transform hover:scale-105 active:scale-95"
          >
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            {t.accept || 'Accept'}
          </button>
          <button
            onClick={() => onReject(request._id)}
            className="flex items-center bg-gradient-to-r from-red-600 to-red-800 hover:from-red-700 hover:to-red-900 px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm text-white shadow-xl transition-transform duration-200 ease-in-out transform hover:scale-105 active:scale-95"
          >
            <FontAwesomeIcon icon={faBan} className="mr-2" />
            {t.reject || 'Reject'}
          </button>
        </div>
      )}
    </li>
  );
};

export default DJRequestItem;

// src/translations.ts
const translations = {
  english: {
    success: 'Success',
    error: 'Error',
    djSongRequestAccepted: 'DJ song request accepted.',
    failedToAcceptRequest: 'Failed to accept request.',
    djSongRequestRejected: 'DJ song request rejected.',
    failedToRejectRequest: 'Failed to reject request.',
    fieldsRequired: 'Both YouTube link and message are required.',
    djSongRequestSubmitted: 'DJ song request submitted.',
    failedToSubmitRequest: 'Failed to submit request.',
    djSongRequests: 'DJ Song Requests',
    requested: 'Requested',
    accepted: 'Accepted',
    rejected: 'Rejected',
    loading: 'Loading...',
    noPendingRequests: 'No pending requests.',
    noAcceptedRequests: 'No accepted requests.',
    noRejectedRequests: 'No rejected requests.',
    accept: 'Accept',
    reject: 'Reject',
    requestASong: 'Request a Song',
    videoUrl: 'YouTube Link',
    messageLabel: 'Message',
    cancel: 'Cancel',
    submit: 'Submit',
    songRequestCost: 'Each song request costs 5000 coins.',
    buyCoins: 'Buy Coins',
    requestDJAccess: 'Request DJ Access',
    djAccessRequestTitle: 'DJ Access Request',
    djAccessRequestDescription: 'Please complete your profile and add at least a picture.',
    confirmDJAccess: 'Confirm Request',
    djAccessRequestSubmitted: 'DJ access request submitted.',
    failedToSubmitDJAccessRequest: 'Failed to submit DJ access request.',
    djAccessRequests: 'DJ Access Requests',
    noAccessRequests: 'No DJ access requests.',
    djAccessRequestApproved: 'DJ access request approved.',
    djAccessRequestRejected: 'DJ access request rejected.',
    search: 'Search DJs...',
    myRequests: 'My Requests',
    djs: 'DJs',
    noDJs: 'No DJs found.',
    noRequests: 'No requests found.',
    return: 'Return',
    joinAsDJ: 'Join as DJ',
  },
  spanish: {
    success: 'Éxito',
    error: 'Error',
    djSongRequestAccepted: 'Solicitud de canción DJ aceptada.',
    failedToAcceptRequest: 'Error al aceptar la solicitud.',
    djSongRequestRejected: 'Solicitud de canción DJ rechazada.',
    failedToRejectRequest: 'Error al rechazar la solicitud.',
    fieldsRequired: 'Se requieren tanto el enlace de YouTube como el mensaje.',
    djSongRequestSubmitted: 'Solicitud de canción DJ enviada.',
    failedToSubmitRequest: 'Error al enviar la solicitud.',
    djSongRequests: 'Solicitudes de Canción DJ',
    requested: 'Solicitadas',
    accepted: 'Aceptadas',
    rejected: 'Rechazadas',
    loading: 'Cargando...',
    noPendingRequests: 'No hay solicitudes pendientes.',
    noAcceptedRequests: 'No hay solicitudes aceptadas.',
    noRejectedRequests: 'No hay solicitudes rechazadas.',
    accept: 'Aceptar',
    reject: 'Rechazar',
    requestASong: 'Solicitar una Canción',
    videoUrl: 'Enlace de YouTube',
    messageLabel: 'Mensaje',
    cancel: 'Cancelar',
    submit: 'Enviar',
    songRequestCost: 'Cada solicitud de canción cuesta 5000 monedas.',
    buyCoins: 'Comprar Monedas',
    requestDJAccess: 'Solicitar Acceso DJ',
    djAccessRequestTitle: 'Solicitud de Acceso DJ',
    djAccessRequestDescription: 'Por favor, completa tu perfil y añade al menos una foto.',
    confirmDJAccess: 'Confirmar Solicitud',
    djAccessRequestSubmitted: 'Solicitud de acceso DJ enviada.',
    failedToSubmitDJAccessRequest: 'Error al enviar la solicitud de acceso DJ.',
    djAccessRequests: 'Solicitudes de Acceso DJ',
    noAccessRequests: 'No hay solicitudes de acceso DJ.',
    djAccessRequestApproved: 'Solicitud de acceso DJ aprobada.',
    djAccessRequestRejected: 'Solicitud de acceso DJ rechazada.',
    search: 'Buscar DJs...',
    myRequests: 'Mis Solicitudes',
    djs: 'DJs',
    noDJs: 'No se encontraron DJs.',
    noRequests: 'No se encontraron solicitudes.',
    return: 'Volver',
    joinAsDJ: 'Únete como DJ',
  },
  polish: {
    success: 'Sukces',
    error: 'Błąd',
    djSongRequestAccepted: 'Żądanie piosenki DJ zaakceptowane.',
    failedToAcceptRequest: 'Nie udało się zaakceptować żądania.',
    djSongRequestRejected: 'Żądanie piosenki DJ odrzucone.',
    failedToRejectRequest: 'Nie udało się odrzucić żądania.',
    fieldsRequired: 'Wymagany jest zarówno link do YouTube, jak i wiadomość.',
    djSongRequestSubmitted: 'Żądanie piosenki DJ wysłane.',
    failedToSubmitRequest: 'Nie udało się wysłać żądania.',
    djSongRequests: 'Żądania Piosenek DJ',
    requested: 'Żądane',
    accepted: 'Zaakceptowane',
    rejected: 'Odrzucone',
    loading: 'Ładowanie...',
    noPendingRequests: 'Brak żądań oczekujących.',
    noAcceptedRequests: 'Brak zaakceptowanych żądań.',
    noRejectedRequests: 'Brak odrzuconych żądań.',
    accept: 'Zaakceptuj',
    reject: 'Odrzuć',
    requestASong: 'Złóż Żądanie Piosenki',
    videoUrl: 'Link do YouTube',
    messageLabel: 'Wiadomość',
    cancel: 'Anuluj',
    submit: 'Wyślij',
    songRequestCost: 'Każde żądanie piosenki kosztuje 5000 monet.',
    buyCoins: 'Kup Monety',
    requestDJAccess: 'Złóż Wniosek o Dostęp DJ',
    djAccessRequestTitle: 'Wniosek o Dostęp DJ',
    djAccessRequestDescription: 'Proszę uzupełnij swój profil i dodaj przynajmniej jedno zdjęcie.',
    confirmDJAccess: 'Potwierdź Żądanie',
    djAccessRequestSubmitted: 'Wniosek o dostęp DJ został wysłany.',
    failedToSubmitDJAccessRequest: 'Nie udało się wysłać wniosku o dostęp DJ.',
    djAccessRequests: 'Wnioski o Dostęp DJ',
    noAccessRequests: 'Brak wniosków o dostęp DJ.',
    djAccessRequestApproved: 'Wniosek o dostęp DJ został zaakceptowany.',
    djAccessRequestRejected: 'Wniosek o dostęp DJ został odrzucony.',
    search: 'Szukaj DJ-ów...',
    myRequests: 'Moje Żądania',
    djs: 'DJ-e',
    noDJs: 'Nie znaleziono DJ-ów.',
    noRequests: 'Nie znaleziono żądań.',
    return: 'Powrót',
    joinAsDJ: 'Dołącz jako DJ',
  },
};

export default translations;

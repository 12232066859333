import React from 'react';
import { Helmet } from 'react-helmet';
import { useForm, ValidationError } from '@formspree/react';
import { useLanguage } from 'Contexts/LanguageContext';

// Translations for the Marketing Management page
const translations = {
  english: {
    page_title: 'Bar & Club Marketing Management | Full-Service Marketing Solutions',
    meta_description:
      'We provide comprehensive marketing management for bars and clubs. From social media content generation on Instagram, Facebook, and TikTok to photography, video production, and banner design, we take control of your marketing strategy.',
    meta_keywords:
      'Bar Marketing, Club Marketing, Marketing Management, Social Media, Content Generation, Photography, Video Production, Banner Design, Full-Service Marketing',
    canonical_url: 'https://yourwebsite.com/marketing-management',
    hero_title: 'Elevate Your Bar or Club with Expert Marketing',
    hero_description:
      'Our full-service marketing management solutions cover everything from creative content generation and social media posting to photography, video production, and banner design. We empower your brand to stand out in a competitive market.',
    services_section_title: 'Our Marketing Services',
    service_social_media_title: 'Social Media Management',
    service_social_media_desc:
      'We structure your posting and content generation on Instagram, Facebook, and TikTok—keeping your brand active, engaging, and on-trend.',
    service_photography_video_title: 'Photography & Video Production',
    service_photography_video_desc:
      'From professional photoshoots to dynamic video content, we capture the unique vibe of your bar or club to attract more patrons.',
    service_banner_design_title: 'Banner & Visual Design',
    service_banner_design_desc:
      'Our creative team designs eye-catching banners and promotional visuals tailored to your brand’s style and target audience.',
    service_full_management_title: 'Complete Marketing Management',
    service_full_management_desc:
      'We don’t just offer small services—we take full control of your marketing efforts, developing and executing a cohesive strategy that delivers results.',
    contact_section_title: 'Get in Touch',
    contact_form_title: 'Contact Us',
    contact_form_submit: 'Send Message',
    contact_form_success: 'Thank you for reaching out! We will get back to you soon.',
    final_cta_title: 'Ready to Transform Your Marketing?',
    direct_email: 'Email Us',
    direct_whatsapp: 'Chat on WhatsApp',
  },
  spanish: {
    page_title: 'Gestión de Marketing para Bares y Clubs | Soluciones Integrales de Marketing',
    meta_description:
      'Ofrecemos gestión de marketing integral para bares y clubs. Desde la generación de contenido en Instagram, Facebook y TikTok hasta fotografía, producción de video y diseño de banners, controlamos tu estrategia de marketing.',
    meta_keywords:
      'Marketing para Bares, Marketing para Clubs, Gestión de Marketing, Redes Sociales, Generación de Contenido, Fotografía, Producción de Video, Diseño de Banners, Marketing Integral',
    canonical_url: 'https://yourwebsite.com/marketing-management',
    hero_title: 'Eleva Tu Bar o Club con Marketing Experto',
    hero_description:
      'Nuestras soluciones integrales de gestión de marketing abarcan desde la generación creativa de contenido y publicaciones en redes sociales hasta fotografía, producción de video y diseño de banners. Potenciamos tu marca para destacar en un mercado competitivo.',
    services_section_title: 'Nuestros Servicios de Marketing',
    service_social_media_title: 'Gestión de Redes Sociales',
    service_social_media_desc:
      'Planificamos tus publicaciones y generamos contenido en Instagram, Facebook y TikTok, asegurando que tu marca se mantenga activa y atractiva.',
    service_photography_video_title: 'Fotografía y Producción de Video',
    service_photography_video_desc:
      'Desde sesiones fotográficas profesionales hasta contenido de video dinámico, capturamos la esencia de tu bar o club para atraer a más clientes.',
    service_banner_design_title: 'Diseño de Banners y Visuales',
    service_banner_design_desc:
      'Nuestro equipo creativo diseña banners y visuales promocionales impactantes, adaptados al estilo de tu marca y audiencia.',
    service_full_management_title: 'Gestión Integral de Marketing',
    service_full_management_desc:
      'No solo ofrecemos pequeños servicios; nos encargamos de todas tus necesidades de marketing, desarrollando y ejecutando una estrategia coherente que genera resultados.',
    contact_section_title: 'Contáctanos',
    contact_form_title: 'Envíanos un Mensaje',
    contact_form_submit: 'Enviar Mensaje',
    contact_form_success: '¡Gracias por contactarnos! Nos pondremos en contacto contigo pronto.',
    final_cta_title: '¿Listo para Transformar Tu Marketing?',
    direct_email: 'Envíanos un Email',
    direct_whatsapp: 'Chatea por WhatsApp',
  },
  polish: {
    page_title: 'Zarządzanie Marketingiem dla Barów i Klubów | Kompleksowe Rozwiązania Marketingowe',
    meta_description:
      'Oferujemy kompleksowe zarządzanie marketingiem dla barów i klubów. Od generowania treści na Instagramie, Facebooku i TikTok, po fotografię, produkcję wideo i projektowanie banerów – przejmujemy kontrolę nad Twoją strategią marketingową.',
    meta_keywords:
      'Marketing Barowy, Marketing Klubowy, Zarządzanie Marketingiem, Social Media, Generowanie Treści, Fotografia, Produkcja Wideo, Projektowanie Banerów, Marketing Kompleksowy',
    canonical_url: 'https://yourwebsite.com/marketing-management',
    hero_title: 'Podnieś Poziom Swojego Baru lub Klubu dzięki Profesjonalnemu Marketingowi',
    hero_description:
      'Nasze kompleksowe rozwiązania zarządzania marketingiem obejmują wszystko, od kreatywnego generowania treści i publikacji w mediach społecznościowych, po fotografię, produkcję wideo i projektowanie banerów. Wzmacniamy Twoją markę, aby wyróżniała się na konkurencyjnym rynku.',
    services_section_title: 'Nasze Usługi Marketingowe',
    service_social_media_title: 'Zarządzanie Social Media',
    service_social_media_desc:
      'Planujemy publikacje i generujemy treści na Instagramie, Facebooku i TikTok, zapewniając aktywność i zaangażowanie Twojej marki.',
    service_photography_video_title: 'Fotografia i Produkcja Wideo',
    service_photography_video_desc:
      'Od profesjonalnych sesji fotograficznych po dynamiczne materiały wideo – uchwycimy esencję Twojego baru lub klubu, aby przyciągnąć więcej klientów.',
    service_banner_design_title: 'Projektowanie Banerów i Materiałów Wizualnych',
    service_banner_design_desc:
      'Nasz kreatywny zespół tworzy przyciągające uwagę banery i materiały wizualne, dostosowane do stylu Twojej marki i odbiorców.',
    service_full_management_title: 'Kompleksowe Zarządzanie Marketingiem',
    service_full_management_desc:
      'Nie oferujemy tylko drobnych usług – przejmujemy pełną kontrolę nad Twoimi działaniami marketingowymi, tworząc spójną strategię, która przynosi rezultaty.',
    contact_section_title: 'Skontaktuj się z Nami',
    contact_form_title: 'Wyślij Wiadomość',
    contact_form_submit: 'Wyślij',
    contact_form_success: 'Dziękujemy za wiadomość! Wkrótce się odezwiemy.',
    final_cta_title: 'Gotowy na Transformację Marketingu?',
    direct_email: 'Napisz do nas',
    direct_whatsapp: 'Czat przez WhatsApp',
  },
};

const ContactForm = ({ t }: {t: any}) => {
  const [state, handleSubmit] = useForm('xbldppyg');
  if (state.succeeded) {
    return <p className="text-green-400 mb-4">{t.contact_form_success}</p>;
  }
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        id="name"
        type="text"
        name="name"
        placeholder="Your Name"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <input
        id="email"
        type="email"
        name="email"
        placeholder="Your Email"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <textarea
        id="message"
        name="message"
        placeholder="Your Message"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        rows={3}
      ></textarea>
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button
        type="submit"
        disabled={state.submitting}
        className="w-full px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
      >
        {t.contact_form_submit}
      </button>
    </form>
  );
};

const MarketingManagement = () => {
  const { language } = useLanguage();
  const lang = (['english', 'spanish', 'polish'].includes(language)
    ? language
    : 'english') as 'english' | 'spanish' | 'polish';
  const t = translations[lang];
  // Direct contact links can be customized
  const emailLink = `mailto:marketing@example.com?subject=Marketing%20Inquiry&body=${encodeURIComponent(
    "Hello, I'm interested in your marketing services for bars and clubs."
  )}`;
  const whatsappLink = `https://wa.me/1234567890?text=${encodeURIComponent(
    "Hello, I'm interested in your marketing services for bars and clubs."
  )}`;

  return (
    <>
      <Helmet>
        <title>{t.page_title}</title>
        <meta name="description" content={t.meta_description} />
        <meta name="keywords" content={t.meta_keywords} />
        <link rel="canonical" href={t.canonical_url} />
      </Helmet>
      <div className="bg-gray-900 text-white">
        {/* Hero Section */}
        <section className="py-20 text-center px-4">
          <h1 className="text-4xl font-bold mb-4">{t.hero_title}</h1>
          <p className="max-w-2xl mx-auto text-lg">{t.hero_description}</p>
        </section>

        {/* Services Section */}
        <section className="py-12 px-4">
          <h2 className="text-3xl font-bold text-center mb-8">{t.services_section_title}</h2>
          <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Social Media Management */}
            <div className="p-6 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg border border-indigo-600 rounded-xl transition transform hover:scale-105 shadow-lg">
              <h3 className="text-2xl font-bold mb-4 text-center">{t.service_social_media_title}</h3>
              <p className="text-sm">{t.service_social_media_desc}</p>
            </div>
            {/* Photography & Video Production */}
            <div className="p-6 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg border border-indigo-600 rounded-xl transition transform hover:scale-105 shadow-lg">
              <h3 className="text-2xl font-bold mb-4 text-center">{t.service_photography_video_title}</h3>
              <p className="text-sm">{t.service_photography_video_desc}</p>
            </div>
            {/* Banner & Visual Design */}
            <div className="p-6 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg border border-indigo-600 rounded-xl transition transform hover:scale-105 shadow-lg">
              <h3 className="text-2xl font-bold mb-4 text-center">{t.service_banner_design_title}</h3>
              <p className="text-sm">{t.service_banner_design_desc}</p>
            </div>
            {/* Complete Marketing Management */}
            <div className="p-6 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg border border-indigo-600 rounded-xl transition transform hover:scale-105 shadow-lg">
              <h3 className="text-2xl font-bold mb-4 text-center">{t.service_full_management_title}</h3>
              <p className="text-sm">{t.service_full_management_desc}</p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section className="py-12 px-4 bg-gray-800">
          <h2 className="text-3xl font-bold text-center mb-8">{t.contact_section_title}</h2>
          <div className="max-w-xl mx-auto">
            <ContactForm t={t} />
          </div>
        </section>

        {/* Final Call-To-Action */}
        <section className="py-12 px-4">
          <h2 className="text-4xl font-bold text-center mb-8">{t.final_cta_title}</h2>
          <div className="flex flex-col md:flex-row justify-center items-center gap-6">
            <a
              href={emailLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-green-500 via-green-600 to-green-700 text-white font-bold hover:opacity-90 transition"
            >
              {t.direct_email}
            </a>
            <a
              href={whatsappLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white font-bold hover:opacity-90 transition"
            >
              {t.direct_whatsapp}
            </a>
          </div>
        </section>
      </div>
    </>
  );
};

export default MarketingManagement;

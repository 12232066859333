// src/PartyTicket/SongRequests/components/DJAccessRequestItem.tsx
import React from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Requester {
  username: string;
  email: string;
}

export interface DJAccessRequest {
  _id: string;
  requester: Requester;
  message: string;
  status: 'pending' | 'accepted' | 'rejected';
  createdAt: string;
}

interface DJAccessRequestItemProps {
  request: DJAccessRequest;
  t: { [key: string]: string };
  onApprove?: (requestId: string) => void;
  onReject?: (requestId: string) => void;
}

const DJAccessRequestItem: React.FC<DJAccessRequestItemProps> = ({
  request,
  t,
  onApprove,
  onReject,
}) => {
  const formattedDate = new Date(request.createdAt).toLocaleString();

  // Define basic neon/cyberpunk color styling for status
  const statusStyles =
    request.status === 'pending'
      ? 'bg-yellow-500'
      : request.status === 'accepted'
      ? 'bg-green-500'
      : 'bg-red-500';

  return (
    <li className="border-b border-gray-800 py-2">
      <div className="flex justify-between items-center">
        <div>
          <p className="font-bold text-[1.1rem] text-neonMagenta">
            {request.requester.username}{' '}
            <span className="text-sm text-gray-400">({request.requester.email})</span>
          </p>
          <p className="text-sm text-gray-400">{formattedDate}</p>
          <p className="mt-1 text-gray-200">{request.message}</p>
        </div>
        <div className="flex items-center">
          <span className={`px-2 py-1 rounded text-xs text-white ${statusStyles} drop-shadow-[0_0_8px_rgba(255,20,147,0.7)]`}>
            {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
          </span>
          {request.status === 'pending' && (
            <>
              {onApprove && (
                <button
                  onClick={() => onApprove(request._id)}
                  className="ml-2 flex items-center px-4 py-1 bg-gradient-to-r from-pink-500 to-purple-600 hover:from-pink-600 hover:to-purple-700 rounded-full text-xs text-white shadow-[0_0_10px_rgba(255,20,147,0.7)] transition-transform duration-200 ease-in-out transform hover:scale-105 active:scale-95"
                >
                  <FontAwesomeIcon icon={faCheck} className="mr-1" />
                  {t.accept || 'Accept'}
                </button>
              )}
              {onReject && (
                <button
                  onClick={() => onReject(request._id)}
                  className="ml-2 flex items-center px-4 py-1 bg-gradient-to-r from-red-500 to-orange-600 hover:from-red-600 hover:to-orange-700 rounded-full text-xs text-white shadow-[0_0_10px_rgba(255,69,0,0.7)] transition-transform duration-200 ease-in-out transform hover:scale-105 active:scale-95"
                >
                  <FontAwesomeIcon icon={faTimes} className="mr-1" />
                  {t.reject || 'Reject'}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </li>
  );
};

export default DJAccessRequestItem;

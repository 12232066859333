import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import { FaClipboard, FaPlus, FaSpinner } from 'react-icons/fa';

interface AddVideoModalProps {
  newVideo: { videoId: string };
  setNewVideo: (value: { videoId: string }) => void;
  handleAddVideo: () => void;
  addVideoLoading: boolean;
  handlePasteClipboard: () => void;
  closeModal: () => void;
  songCount: number; // Number of songs the user already has in queue
  isAdmin: boolean;
}

const AddVideoModal: React.FC<AddVideoModalProps> = ({
  newVideo,
  setNewVideo,
  handleAddVideo,
  addVideoLoading,
  handlePasteClipboard,
  closeModal,
  songCount,
  isAdmin,
}) => {
  // Get current language and define translations
  console.log("songCount> ", songCount);
  const { language } = useLanguage();
  const translations = {
    english: {
      addVideo: 'Add Video',
      extraSongCost:
        'You already have {count} song(s) in the queue. Adding an additional song will cost you {cost} Taki Coins.',
      adding: 'Adding...',
      addWithCost: 'Add ({cost} Taki Coins)',
      add: 'Add',
      cancel: 'Cancel',
    },
    spanish: {
      addVideo: 'Agregar Video',
      extraSongCost:
        'Ya tienes {count} canción(es) en la cola. Agregar una canción adicional te costará {cost} Taki Coins.',
      adding: 'Agregando...',
      addWithCost: 'Agregar ({cost} Taki Coins)',
      add: 'Agregar',
      cancel: 'Cancelar',
    },
    polish: {
      addVideo: 'Dodaj Wideo',
      extraSongCost:
        'Masz już {count} piosenkę/e w kolejce. Dodanie kolejnej piosenki będzie kosztować Cię {cost} Taki Coins.',
      adding: 'Dodawanie...',
      addWithCost: 'Dodaj ({cost} Taki Coins)',
      add: 'Dodaj',
      cancel: 'Anuluj',
    },
  };

  const t = translations[language as keyof typeof translations];

  // Calculate additional cost if user already has songs queued.
  // Cost doubles for each song beyond the first: cost = 100 * 2^(songCount - 1)
  const additionalCost = songCount > 0 ? 100 * Math.pow(2, songCount - 1) : 0;

  // Generate the extra cost message by replacing placeholders.
  const extraCostMessage =
    songCount > 0 && !isAdmin
      ? t.extraSongCost.replace('{count}', String(songCount)).replace('{cost}', String(additionalCost))
      : '';

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center">
      <div className="bg-gradient-to-br from-gray-900 via-gray-800 to-gray-700 p-8 rounded-xl shadow-2xl w-96">
        <h3 className="text-2xl font-bold text-white mb-6 text-center">{t.addVideo}</h3>
        {songCount > 0 && !isAdmin && <p className="text-sm text-yellow-400 mb-4 text-center">{extraCostMessage}</p>}
        <div className="space-y-4">
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Video URL"
              value={newVideo.videoId}
              onChange={(e) => setNewVideo({ videoId: e.target.value })}
              className="w-full p-3 rounded-md bg-gray-800 text-white placeholder-gray-400 pr-12 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <button
              onClick={handlePasteClipboard}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white focus:outline-none"
              title="Paste YouTube Link"
            >
              <FaClipboard size={20} />
            </button>
          </div>
          <button
            onClick={handleAddVideo}
            className={`w-full p-3 rounded-md flex items-center justify-center ${
              addVideoLoading
                ? 'bg-gray-600'
                : 'bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600'
            } text-white transition-all duration-300`}
            disabled={addVideoLoading}
          >
            {addVideoLoading ? <FaSpinner className="animate-spin mr-2" /> : <FaPlus className="mr-2" />}
            {addVideoLoading
              ? t.adding
              : songCount > 0 && !isAdmin
              ? t.addWithCost.replace('{cost}', String(additionalCost))
              : t.add}
          </button>
        </div>
        <button
          onClick={closeModal}
          className="w-full mt-6 p-3 bg-gradient-to-r from-red-500 to-red-600 text-white rounded-md hover:from-red-600 hover:to-red-700 transition-all duration-300"
        >
          {t.cancel}
        </button>
      </div>
    </div>
  );
};

export default AddVideoModal;

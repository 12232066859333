import { faTimes } from '@fortawesome/free-solid-svg-icons'; // Added the close icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'animate.css'; // Importing Animate.css for animations
import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useEffect, useState } from 'react';
import { FaCog } from 'react-icons/fa';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Swal from 'sweetalert2';
import SettingsModal from './components/SettingsModal';
import SliderSections from './components/SliderSections';
import useFetchAllUsers from './hooks/useFetchAllUsers';
import useFetchBattleDetails from './hooks/useFetchBattleDetails';
import useFetchBattles from './hooks/useFetchBattles';
import useFetchPlaylistDetails from './hooks/useFetchPlaylistDetails';
import useFetchPlaylists from './hooks/useFetchPlaylists';
import useFetchPromotions from './hooks/useFetchPromotions';
import useFetchRooms from './hooks/useFetchRooms';
import useFetchVenues from './hooks/useFetchVenues';
import { translations } from './translations'; // Import translations
import { SectionKey, SectionType } from './types';

const TVScreenDisplay: React.FC = () => {
  const { currentToken } = useAuth();
  const { language } = useLanguage();

  const [selectedVenue, setSelectedVenue] = useState<string>(() => localStorage.getItem('selectedVenue') || '');
  const [selectedRoom, setSelectedRoom] = useState<string>(() => localStorage.getItem('selectedRoom') || '');
  const [selectedPlaylist, setSelectedPlaylist] = useState<string>(
    () => localStorage.getItem('selectedPlaylist') || '',
  );
  const [selectedBattle, setSelectedBattle] = useState<string>(() => localStorage.getItem('selectedBattle') || '');
  const [videoUrls, setVideoUrls] = useState<string[]>(() => {
    const saved = localStorage.getItem('videoUrls');
    return saved ? JSON.parse(saved) : [];
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);

  const [activeTab, setActiveTab] = useState<SectionType>('videos' as SectionType);

  // State for toggling sections visibility
  const [displaySections, setDisplaySections] = useState<Record<string, boolean>>(() => {
    const saved = localStorage.getItem('displaySections');
    return saved
      ? JSON.parse(saved)
      : {
          totalCoins: true,
          battleData: false,
          promotions: false,
          videos: true,
          karaokeLeaderboard: false,
          venueQR: false,
          djRequestsQR: false,
        };
  });

  const { venues } = useFetchVenues(currentToken || '');
  const { rooms } = useFetchRooms(currentToken || '');
  const { playlists } = useFetchPlaylists(currentToken || '', selectedRoom);
  const { battles } = useFetchBattles(currentToken || '');
  const { promotions } = useFetchPromotions(currentToken || '', selectedVenue);
  const { users: totalCoinsLeaderboard } = useFetchAllUsers(currentToken || '');
  const { playlistDetails: playlist } = useFetchPlaylistDetails(currentToken || '', selectedPlaylist);
  const { battleData } = useFetchBattleDetails(currentToken || '', selectedBattle);
  const [messages, setMessages] = useState<any[]>([]);
  const [activeMessageOverlay, setActiveMessageOverlay] = useState<boolean>(true); // State to control overlay visibility

  const t = translations[language as keyof typeof translations];

  // Whenever these states change, update localStorage
  useEffect(() => {
    localStorage.setItem('selectedVenue', selectedVenue);
  }, [selectedVenue]);

  useEffect(() => {
    localStorage.setItem('selectedRoom', selectedRoom);
  }, [selectedRoom]);

  useEffect(() => {
    localStorage.setItem('selectedPlaylist', selectedPlaylist);
  }, [selectedPlaylist]);

  useEffect(() => {
    localStorage.setItem('selectedBattle', selectedBattle);
  }, [selectedBattle]);

  useEffect(() => {
    localStorage.setItem('videoUrls', JSON.stringify(videoUrls));
  }, [videoUrls]);

  useEffect(() => {
    localStorage.setItem('displaySections', JSON.stringify(displaySections));
  }, [displaySections]);

  useEffect(() => {
    if (currentToken && selectedRoom && selectedVenue && selectedPlaylist && selectedBattle) {
      // Optional: Combine all API requests in one place.
      setLoading(false); // Once all data is available, set loading false.
    }
  }, [currentToken, selectedRoom, selectedVenue, selectedPlaylist, selectedBattle]);

  useEffect(() => {
    if (currentToken) {
      fetchAllMessages();
    }
  }, [currentToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAllMessages(); // Fetch new messages periodically
      removeExpiredMessages(); // Check for expired messages
    }, 5000); // Check every 5 seconds

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  const removeExpiredMessages = () => {
    // Remove messages whose end time has passed
    setMessages((prevMessages) => prevMessages.filter((message) => new Date(message.endTime) > new Date()));
  };

  const addVideo = (newVideoUrl: string) => {
    if (!newVideoUrl) {
      Swal.fire('Error', 'Please enter a valid YouTube video URL', 'error');
      return;
    }
    setVideoUrls([...videoUrls, newVideoUrl]);
  };

  const removeVideo = (index: number) => {
    const updated = [...videoUrls];
    updated.splice(index, 1);
    setVideoUrls(updated);
  };

  const moveVideo = (index: number, direction: 'up' | 'down') => {
    const updated = [...videoUrls];
    const targetIndex = direction === 'up' ? index - 1 : index + 1;
    if (targetIndex >= 0 && targetIndex < updated.length) {
      [updated[index], updated[targetIndex]] = [updated[targetIndex], updated[index]];
    }
    setVideoUrls(updated);
  };

  const leaderboard = playlist?.participants.map((participant: any) => {
    const likesForParticipant = playlist?.userInteractions.filter(
      (interaction: any) => interaction.type === 'like' && interaction.recipient === participant._id,
    );

    return {
      ...participant,
      likes: likesForParticipant || [],
    };
  });

  const handleToggle = (section: SectionKey) => {
    setDisplaySections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const fetchAllMessages = async () => {
    try {
      const response = await fetch(`${APIBase}/client/message/all`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setMessages(data.data);
      } else {
        throw new Error('Failed to fetch messages');
      }
    } catch (error) {
      console.error('Error fetching all messages:', error);
    }
  };

  // Categorize messages (Now, Past, Future)
  const categorizedMessages = {
    past: messages
      .filter((message) => new Date(message.endTime) < new Date())
      .sort((a, b) => new Date(b.endTime).getTime() - new Date(a.endTime).getTime()), // Sort past by newest first
    now: messages
      .filter((message) => new Date(message.startTime) <= new Date() && new Date(message.endTime) >= new Date())
      .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()), // Sort now by closest start time
    future: messages
      .filter((message) => new Date(message.startTime) > new Date())
      .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()), // Sort future by closest start time
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
  };

  console.log('activeMessageOverlay> ', activeMessageOverlay);

  const approvedMessagesNow = categorizedMessages.now.filter((message) => message.approved === true);

  console.log('promotions> ', promotions);
  return (
    <div className="relative w-full h-screen text-white">
      {/* Background Video */}
      {videoUrls.length > 0 && (
        <iframe
          src={`https://www.youtube.com/embed/${new URL(videoUrls[currentVideoIndex]).searchParams.get(
            'v',
          )}?autoplay=1&loop=1&playlist=${new URL(videoUrls[currentVideoIndex]).searchParams.get('v')}`}
          className="absolute inset-0 w-full h-full object-cover"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      )}

      {/* Message Overlay with animation */}
      {categorizedMessages.now.length > 0 &&
        activeMessageOverlay &&
        // Filter messages to only include approved ones

        // Only show the overlay if there are approved messages
        approvedMessagesNow.length > 0 && (
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70 z-50 flex justify-center items-center">
            <div className="bg-gray-800 p-28 rounded-lg shadow-lg w-full h-full animate__animated animate__zoomIn animate__fast flex-wrap flex flex-col items-center justify-center bg-opacity-50 message-display-container">
              {approvedMessagesNow.length > 0 ? (
                approvedMessagesNow.map((message) => (
                  <div
                    key={message._id}
                    className="text-white font-extrabold flex-wrap text-wrap text-center neon-text"
                    style={{ fontSize: '200px', lineHeight: '200px' }}
                  >
                    {message.message}
                  </div>
                ))
              ) : (
                <p className="text-gray-300">{t.noMessages}</p>
              )}

              {/* Close Button with Neon Gradient */}
              <button
                onClick={() => setActiveMessageOverlay(false)}
                className="absolute bottom-5 right-5 bg-gradient-to-r from-blue-500 to-teal-500 hover:from-blue-600 hover:to-teal-600 text-white p-3 rounded-full shadow-md w-12 h-12 flex items-center justify-center transition-all duration-300 ease-in-out"
              >
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </button>
            </div>
          </div>
        )}

      {/* Overlay Content */}
      <SliderSections
        leaderboard={leaderboard}
        battleData={battleData}
        promotions={promotions}
        totalCoinsLeaderboard={totalCoinsLeaderboard}
        settings={settings}
        handleToggle={handleToggle}
        displaySections={displaySections}
        t={t}
        loading={loading}
        selectedVenue={selectedVenue} //
      />

      {/* Settings Icon */}
      <div
        className="absolute top-20 right-5 px-3 bg-gray-800 text-white rounded-full shadow-lg cursor-pointer w-10 h-10 flex items-center justify-center"
        onClick={() => setIsModalOpen(true)}
      >
        <FaCog />
      </div>

      <SettingsModal
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        handleToggle={handleToggle}
        activeTab={activeTab}
        setActiveTab={setActiveTab} // Pass setActiveTab as a prop
        sectionsVisibility={displaySections}
        selectVenue={setSelectedVenue}
        venues={venues}
        selectedVenue={selectedVenue} // Pass selectedVenue to the modal
        rooms={rooms} // Pass rooms to the modal
        playlists={playlists} // Pass playlists to the modal
        battles={battles} // Pass battles to the modal
        setSelectedRoom={setSelectedRoom} // Pass setSelectedRoom
        setSelectedPlaylist={setSelectedPlaylist} // Pass setSelectedPlaylist
        setCurrentVideoIndex={setCurrentVideoIndex} // Pass setCurrentVideoIndex
        setSelectedBattle={setSelectedBattle} // Pass setSelectedBattle
        selectedRoom={selectedRoom} // Pass selectedRoom to the modal
        selectedPlaylist={selectedPlaylist} // Pass selectedPlaylist to the modal
        selectedBattle={selectedBattle} // Pass selectedBattle to the modal
        videoUrls={videoUrls} // Pass videoUrls to the modal
        addVideo={addVideo} // Pass addVideo method to the modal
        removeVideo={removeVideo} // Pass removeVideo method to the modal
        moveVideo={moveVideo} // Pass moveVideo method to the modal
      />
    </div>
  );
};

export default TVScreenDisplay;

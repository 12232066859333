import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useForm, ValidationError } from '@formspree/react';
import { useLanguage } from 'Contexts/LanguageContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const translations = {
  english: {
    page_title: 'TAKITON Festival | Latin & International DJ Showcase',
    meta_description:
      'Experience TAKITON, the ultimate DJ-centered festival celebrating deep Latino and non-commercial music. Discover amazing sets, remixes, and live productions from top talent.',
    hero_title: 'A Festival for Music Lovers',
    hero_description:
      'Showcasing deep Latino and non-commercial sounds, TAKITON is a celebration of independent DJ culture. Witness electrifying performances and innovative productions from global talent.',
    about_section_title: 'What is TAKITON?',
    about_section_desc:
      'TAKITON is not your typical music festival. It’s a platform where DJs take center stage, displaying their mixing skills, exclusive edits, and unique productions. Expect high-energy Latin beats fused with global rhythms in an unforgettable atmosphere.',
    gallery_section_title: 'Moments from TAKITON',
    final_cta_title: 'Join the Rhythm at TAKITON!',
    final_cta_desc:
      'Our next tickets and events are coming this summer. Check them out here:',
    contact_button: 'Follow us on Instagram',
    host_section_title: 'Want TAKITON at Your Venue?',
    host_section_desc:
      'We partner with bars, clubs, and event spaces to bring the TAKITON experience to new cities and communities. If you’d like to host TAKITON at your venue, let’s talk!',
    reel_section_title: 'Experience TAKITON in Action',
    reel_section_desc:
      "Instead of telling you what TAKITON is all about, it's better to show you. Watch a quick reel showcasing the electrifying atmosphere and performances at TAKITON.",
    venues_section_title: 'Bring TAKITON to Your Venue',
    venues_section_desc:
      'We can bring our concept to your venue. Contact us for partnerships and booking details.',
    faq_title: 'Frequently Asked Questions',
    faq_q1: 'How much does it cost to host TAKITON at your venue?',
    faq_a1:
      'We work on a revenue-sharing model by taking a percentage of entrance ticket sales.',
    faq_q2: 'How do we get started?',
    faq_a2:
      'Simply contact us via WhatsApp or Instagram, and we\'ll guide you through the process.',
    faq_q3: 'What support do you provide?',
    faq_a3:
      'We provide full event support, from DJ lineup to technical setup and promotional materials.',
    venue_contact_button: 'Contact Us',
  },
  spanish: {
    page_title: 'TAKITON Festival | Exhibición de DJs Latinos e Internacionales',
    meta_description:
      'Vive TAKITON, el festival que celebra la música latina profunda y no comercial. Descubre sets, remixes y producciones en vivo de talentos independientes.',
    hero_title: 'Un Festival para Amantes de la Música',
    hero_description:
      'TAKITON celebra la música latina profunda y no comercial, resaltando la cultura DJ independiente. Disfruta de actuaciones electrizantes y producciones innovadoras de talentos globales.',
    about_section_title: '¿Qué es TAKITON?',
    about_section_desc:
      'TAKITON no es un festival cualquiera. Es una plataforma donde los DJs son protagonistas, mostrando sus mezclas, ediciones exclusivas y producciones únicas. Prepárate para vibrar con ritmos latinos fusionados con sonidos globales.',
    gallery_section_title: 'Momentos de TAKITON',
    final_cta_title: '¡Únete al Ritmo en TAKITON!',
    final_cta_desc:
      'Nuestros próximos tickets y eventos llegarán este verano. Échales un vistazo aquí:',
    contact_button: 'Síguenos en Instagram',
    host_section_title: '¿Quieres TAKITON en tu local?',
    host_section_desc:
      'Colaboramos con bares, clubes y espacios de eventos para llevar la experiencia TAKITON a nuevas ciudades y comunidades. ¡Contáctanos si te interesa!',
    reel_section_title: 'Resumen Destacado del Festival',
    reel_section_desc:
      'En lugar de contarte de qué se trata TAKITON, es mejor que lo veas. Mira un breve resumen que muestra la atmósfera electrizante y las actuaciones en TAKITON.',
    venues_section_title: 'Lleva TAKITON a Tu Local',
    venues_section_desc:
      'Podemos llevar nuestro concepto a tu local. Contáctanos para detalles de colaboración y reserva.',
    faq_title: 'Preguntas Frecuentes',
    faq_q1: '¿Cuánto cuesta organizar TAKITON en tu local?',
    faq_a1:
      'Trabajamos con un modelo de reparto de ingresos, tomando un porcentaje de la venta de entradas.',
    faq_q2: '¿Cómo podemos empezar?',
    faq_a2:
      'Contáctanos a través de WhatsApp o Instagram y te guiaremos en el proceso.',
    faq_q3: '¿Qué tipo de soporte ofrecen?',
    faq_a3:
      'Brindamos soporte integral para el evento, desde el lineup de DJs hasta la instalación técnica y material promocional.',
    venue_contact_button: 'Contáctanos',
  },
  polish: {
    page_title: 'TAKITON Festival | Latynoska i Międzynarodowa Scena DJ-ska',
    meta_description:
      'Doświadcz TAKITON – festiwalu celebrującego głęboką muzykę latynoską i niezależne brzmienia. Zobacz sety, remiksy i produkcje na żywo najlepszych talentów.',
    hero_title: 'Festiwal dla Miłośników Muzyki',
    hero_description:
      'TAKITON to święto głębokich latynoskich brzmień oraz niezależnej kultury DJ-skiej. Przeżyj niesamowite występy i innowacyjne produkcje artystów z całego świata.',
    about_section_title: 'Czym jest TAKITON?',
    about_section_desc:
      'TAKITON to wyjątkowy festiwal, w którym DJ-e są w centrum uwagi, prezentując swoje umiejętności miksowania, unikalne edity i produkcje. Spodziewaj się energetycznych rytmów połączonych z globalnymi brzmieniami.',
    gallery_section_title: 'Chwile z TAKITON',
    final_cta_title: 'Dołącz do Rytmu na TAKITON!',
    final_cta_desc:
      'Nasze bilety i wydarzenia nadchodzą tego lata. Obserwuj nas na Instagramie, aby dowiedzieć się więcej',
    contact_button: 'Obserwuj nas na Instagramie',
    host_section_title: 'Chcesz zorganizować TAKITON w swoim lokalu?',
    host_section_desc:
      'Współpracujemy z barami, klubami i przestrzeniami eventowymi, aby przenieść doświadczenie TAKITON do nowych miast i społeczności. Skontaktuj się z nami!',
    reel_section_title: 'Migawki z Festiwalu',
    reel_section_desc:
      'Zamiast opowiadać, czym jest TAKITON, lepiej zobacz to na własne oczy. Obejrzyj krótki filmik pokazujący elektryzującą atmosferę i występy.',
    venues_section_title: 'Przenieś TAKITON do Swojego Miejsca',
    venues_section_desc:
      'Możemy przenieść nasz koncept do Twojego lokalu. Skontaktuj się z nami, aby uzyskać szczegóły współpracy.',
    faq_title: 'Najczęściej Zadawane Pytania',
    faq_q1: 'Ile kosztuje organizacja TAKITON w Twoim lokalu?',
    faq_a1:
      'Pracujemy na modelu podziału przychodów, pobierając procent od sprzedaży biletów wstępu.',
    faq_q2: 'Jak zacząć współpracę?',
    faq_a2:
      'Skontaktuj się z nami przez WhatsApp lub Instagram, a przeprowadzimy Cię przez proces.',
    faq_q3: 'Jakiego wsparcia udzielacie?',
    faq_a3:
      'Zapewniamy pełne wsparcie wydarzenia, od listy DJ-ów po wsparcie techniczne i materiały promocyjne.',
    venue_contact_button: 'Skontaktuj się z nami',
  },
};

// Original images array
const originalImages = [
  'https://imgur.com/GjxMNdE.jpg',
  'https://imgur.com/3vMAEhN.jpg',
  'https://imgur.com/dwac0Jk.jpg',
  'https://i.imgur.com/gr91GnJ.jpeg',
  'https://imgur.com/V0a2zLA.jpg',
  'https://imgur.com/Upz97Vd.jpg',
];

// About section slideshow images
const aboutSlideshowImages = [
  'https://i.imgur.com/AkQ983X.jpg',
  'https://imgur.com/3vMAEhN.jpg',
  'https://imgur.com/dwac0Jk.jpg'
];

// Gallery images: merge original images with additional ones
const galleryImages = [
  ...originalImages,
  'https://i.imgur.com/1Qjk4JM.jpeg',
  'https://i.imgur.com/Xmtv5bZ.jpg',
  'https://i.imgur.com/zqaRS0s.jpeg',
  'https://i.imgur.com/xKR1AZ7.jpeg'
];

const FadeInSection: React.FC<{ className?: string; children: React.ReactNode }> = ({
  children,
  className = '',
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 }
    );
    if (ref.current) observer.observe(ref.current);
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []);
  return <div ref={ref} className={`${className} ${isVisible ? 'fade-in-up' : 'opacity-0'}`}>{children}</div>;
};

type FAQItemProps = { question: string; answer: string; };
const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className="p-4 bg-gray-800 border border-gray-600 rounded-lg cursor-pointer transition-all hover:bg-gray-700"
      onClick={() => setOpen(!open)}
    >
      <div className="flex justify-between items-center">
        <p className="font-semibold">{question}</p>
        <i className={`fas ${open ? 'fa-minus' : 'fa-plus'} text-2xl`}></i>
      </div>
      {open && <p className="mt-2 text-gray-300">{answer}</p>}
    </div>
  );
};

const styles = `
  @keyframes glitch {
    0% { clip: rect(0, 9999px, 0, 0); transform: translate(0); }
    10% { clip: rect(10px, 9999px, 30px, 0); transform: translate(-5px, -5px); }
    20% { clip: rect(20px, 9999px, 40px, 0); transform: translate(5px, 5px); }
    30% { clip: rect(0, 9999px, 10px, 0); transform: translate(-5px, 5px); }
    40% { clip: rect(30px, 9999px, 50px, 0); transform: translate(5px, -5px); }
    50% { clip: rect(0, 9999px, 0, 0); transform: translate(0); }
    100% { clip: rect(0, 9999px, 0, 0); transform: translate(0); }
  }
  .glitch {
    animation: glitch 0.5s;
  }
  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  .fade-in-up {
    animation: fadeInUp 1s ease-out forwards;
  }
`;

const VenueForm = () => {
  const [state, handleSubmit] = useForm('mnnpbaba'); // Replace with your Formspree ID
  if (state.succeeded) {
    return <p className="text-green-400 mb-6">Thank you for your interest in hosting TAKITON! We'll get back to you soon.</p>;
  }
  return (
    <form onSubmit={handleSubmit} className="space-y-4 mt-4">
      <div>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          className="w-full p-2 rounded-md bg-gray-700 text-white"
          required
        />
        <ValidationError prefix="Name" field="name" errors={state.errors} />
      </div>
      <div>
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          className="w-full p-2 rounded-md bg-gray-700 text-white"
          required
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>
      <div>
        <input
          type="text"
          name="venue"
          placeholder="Venue Name"
          className="w-full p-2 rounded-md bg-gray-700 text-white"
        />
        <ValidationError prefix="Venue" field="venue" errors={state.errors} />
      </div>
      <div>
        <textarea
          name="message"
          placeholder="Tell us about your venue or any questions you have..."
          className="w-full p-2 rounded-md bg-gray-700 text-white"
          rows={4}
        ></textarea>
        <ValidationError prefix="Message" field="message" errors={state.errors} />
      </div>
      <button
        type="submit"
        disabled={state.submitting}
        className="w-full px-6 py-3 rounded-full bg-gradient-to-r from-purple-700 to-blue-600 text-white font-bold uppercase tracking-wider hover:opacity-90 transition transform hover:scale-105"
      >
        Send Request
      </button>
    </form>
  );
};

const TakitonFestival = () => {
  const { language } = useLanguage();
  const lang = (['english', 'spanish', 'polish'].includes(language)
    ? language
    : 'english') as 'english' | 'spanish' | 'polish';
  const t = translations[lang];

  // State for the About section slideshow
  const [currentSlide, setCurrentSlide] = useState(0);
  const [glitch, setGlitch] = useState(false);
  const [heroGlitch, setHeroGlitch] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setHeroGlitch(false), 500);
    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    setGlitch(true);
    const timeout = setTimeout(() => setGlitch(false), 500);
    return () => clearTimeout(timeout);
  }, [currentSlide]);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % aboutSlideshowImages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // State for the Lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  return (
    <>
      <Helmet>
        <title>{t.page_title}</title>
        <meta name="description" content={t.meta_description} />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
          integrity="sha512-Fo3rlrZj/k7ujTTXRRW7Yd/FSOajf/k1Y8s2JxKr5r6blj8S0Kk1L0S7kO9Py9sw0IbE7E+8xIEw2jA5JrZpRQ=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
      </Helmet>
      <style>{styles}</style>
      <div className="bg-black text-white font-sans">
        {/* Hero Section */}
        <section
          className="relative flex items-center justify-center text-center h-screen bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url('https://imgur.com/GjxMNdE.jpg')` }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <FadeInSection>
            <div className="relative z-10 flex flex-col items-center px-4">
              <LazyLoadImage
                src="https://i.imgur.com/sCPOeCf.png"
                alt="TAKITON Logo"
                effect="blur"
                className="max-w-xs mb-6"
              />
              <h1 className={`text-3xl md:text-5xl font-bold uppercase tracking-wider mb-4 ${heroGlitch ? 'glitch' : ''}`}>
                {t.hero_title}
              </h1>
              <p className="max-w-2xl text-lg md:text-xl">{t.hero_description}</p>
            </div>
          </FadeInSection>
        </section>

        {/* Reel Section */}
        <section className="py-12 px-4 bg-black text-white w-full">
          <FadeInSection className="mx-auto w-full flex items-center justify-center">
            <div style={{ width: '900px', maxWidth: '100%' }}>
              <div className="flex flex-col lg:flex-row items-center justify-center">
                <div className="lg:w-1/2 p-4 text-center">
                  <h2 className="text-3xl font-bold mb-4">{t.reel_section_title}</h2>
                  <p className="max-w-xl mx-auto">{t.reel_section_desc}</p>
                </div>
                <div className="lg:w-1/2 p-4 flex justify-center">
                  <div
                    className="relative overflow-hidden rounded-md shadow-lg hover:shadow-xl transition-shadow duration-300 w-full max-w-[360px]"
                    style={{ paddingBottom: '177.78%' }}
                  >
                    <iframe
                      className="absolute inset-0 w-full h-full object-cover"
                      src="https://www.youtube.com/embed/R-KQvqBeMuU"
                      title="TAKITON Reel"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </FadeInSection>
        </section>

        {/* About Section */}
        <section
          className={`relative flex flex-col items-center justify-center text-center h-screen bg-cover bg-center bg-no-repeat ${glitch ? 'glitch' : ''}`}
          style={{
            backgroundImage: `url('${aboutSlideshowImages[currentSlide]}')`,
            transition: 'background-image 1s ease-in-out',
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <FadeInSection>
            <div className="relative z-10 px-4">
              <h2 className="text-3xl md:text-4xl font-bold text-center mb-6">
                {t.about_section_title}
              </h2>
              <p className="max-w-3xl mx-auto text-center text-lg md:text-xl text-gray-300">
                {t.about_section_desc}
              </p>
            </div>
          </FadeInSection>
        </section>

        {/* Gallery Section */}
        <section className="py-12 px-4 bg-gray-900">
          <FadeInSection>
            <h2 className="text-3xl font-bold text-center mb-8">{t.gallery_section_title}</h2>
            <div className="columns-1 sm:columns-2 md:columns-3 gap-4 max-w-6xl mx-auto">
              {galleryImages.map((src, idx) => (
                <div key={idx} className="relative group">
                  <LazyLoadImage
                    src={src}
                    alt={`TAKITON gallery ${idx + 1}`}
                    effect="blur"
                    className="mb-4 w-full rounded-lg shadow-lg hover:scale-105 transition-transform"
                  />
                  <button
                    onClick={() => {
                      setPhotoIndex(idx);
                      setIsLightboxOpen(true);
                    }}
                    className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <i className="fas fa-search text-white text-4xl"></i>
                  </button>
                </div>
              ))}
            </div>
          </FadeInSection>
        </section>

        {/* Full Screen Lightbox */}
        {isLightboxOpen && (
          <Lightbox
            mainSrc={galleryImages[photoIndex]}
            nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
            prevSrc={galleryImages[(photoIndex + galleryImages.length - 1) % galleryImages.length]}
            onCloseRequest={() => setIsLightboxOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + galleryImages.length - 1) % galleryImages.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % galleryImages.length)
            }
          />
        )}

        {/* Final CTA Section */}
        <section className="py-12 px-4 text-center">
          <FadeInSection>
            <h2 className="text-4xl font-bold mb-4">{t.final_cta_title}</h2>
            <p className="max-w-2xl mx-auto mb-6 text-lg md:text-xl">
              {t.final_cta_desc}
            </p>
            <a
              href="https://www.instagram.com/takitonmusicbash/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-6 py-3 rounded-full bg-gradient-to-r from-purple-700 to-blue-600 text-black font-bold uppercase tracking-wider hover:opacity-90 transition transform hover:scale-105"
            >
              {t.contact_button}
            </a>
            <p className="mt-4 text-lg md:text-xl italic">
              Our mission: to be the biggest Latin and international party mixing music, technology and dance.
            </p>
          </FadeInSection>
        </section>

        {/* Venues Section */}
        <section className="py-12 px-4 bg-gray-800 text-white">
          <FadeInSection>
            <h2 className="text-3xl font-bold text-center mb-4">{t.venues_section_title}</h2>
            <p className="max-w-2xl mx-auto text-center mb-8">{t.venues_section_desc}</p>
            <div className="max-w-4xl mx-auto">
              <h3 className="text-2xl font-semibold mb-4">{t.faq_title}</h3>
              <div className="space-y-4">
                <FAQItem question={t.faq_q1} answer={t.faq_a1} />
                <FAQItem question={t.faq_q2} answer={t.faq_a2} />
                <FAQItem question={t.faq_q3} answer={t.faq_a3} />
              </div>
              <div className="mt-12">
                <h3 className="text-2xl font-semibold text-center mb-4">
                  Or fill out this form to host TAKITON
                </h3>
                <VenueForm />
              </div>
            </div>
            <div className="mt-8 text-center">
              <a
                href="https://wa.me/48451110303"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-6 py-3 rounded-full bg-gradient-to-r from-purple-700 to-blue-600 text-white font-bold uppercase tracking-wider hover:opacity-90 transition transform hover:scale-105"
              >
                {t.venue_contact_button}
              </a>
            </div>
          </FadeInSection>
        </section>

        {/* Host Section */}
        <section className="py-12 px-4 bg-gray-800 text-center">
          <FadeInSection>
            <h2 className="text-3xl font-bold mb-4">{t.host_section_title}</h2>
            <p className="max-w-2xl mx-auto text-lg text-gray-300 mb-6">{t.host_section_desc}</p>
            <div className="flex flex-col md:flex-row justify-center gap-4">
              <a
                href="https://wa.me/48451110303"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-6 py-3 rounded-full bg-gradient-to-r from-purple-700 to-blue-600 text-white font-bold uppercase tracking-wider hover:opacity-90 transition transform hover:scale-105"
              >
                <i className="fab fa-whatsapp mr-2"></i>WhatsApp
              </a>
              <a
                href="https://www.instagram.com/takitonmusicbash"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-6 py-3 rounded-full bg-gradient-to-r from-purple-700 to-blue-600 text-white font-bold uppercase tracking-wider hover:opacity-90 transition transform hover:scale-105"
              >
                <i className="fab fa-instagram mr-2"></i>Instagram
              </a>
            </div>
          </FadeInSection>
        </section>
      </div>
    </>
  );
};

export default TakitonFestival;

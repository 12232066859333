// src/hooks/useDJRequests.ts
import { APIBase } from 'api/hosts';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const fetchDJRequests = async (token: string) => {
  const response = await fetch(`${APIBase}/client/dj-request`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch DJ requests');
  }
  const data = await response.json();
  return data.requests;
};

export const useDJRequests = (token: string) => {
  return useQuery(['djRequests'], () => fetchDJRequests(token), {
    enabled: !!token,
    refetchInterval: 10000,
  });
};

export const acceptDJRequest = async ({ requestId, token }: { requestId: string; token: string }) => {
  const response = await fetch(`${APIBase}/client/dj-request/${requestId}/accept`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to accept request.');
  }
  const data = await response.json();
  return data;
};

export const useAcceptDJRequest = (token: string) => {
  const queryClient = useQueryClient();
  return useMutation(({ requestId }: { requestId: string }) => acceptDJRequest({ requestId, token }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['djRequests']);
    },
  });
};

export const rejectDJRequest = async ({ requestId, token }: { requestId: string; token: string }) => {
  const response = await fetch(`${APIBase}/client/dj-request/${requestId}/reject`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to reject request.');
  }
  const data = await response.json();
  return data;
};

export const useRejectDJRequest = (token: string) => {
  const queryClient = useQueryClient();
  return useMutation(({ requestId }: { requestId: string }) => rejectDJRequest({ requestId, token }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['djRequests']);
    },
  });
};

export const submitDJRequest = async ({
  youtubeLink,
  message,
  recipient,
  token,
}: {
  youtubeLink: string;
  message: string;
  recipient?: string;
  token: string;
}) => {
  const body: { youtubeLink: string; message: string; recipient?: string } = { youtubeLink, message };
  if (recipient) {
    body.recipient = recipient;
  }

  const response = await fetch(`${APIBase}/client/dj-request`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to submit request.');
  }
  const data = await response.json();
  return data;
};

export const useSubmitDJRequest = (token: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      youtubeLink,
      message,
      recipient,
    }: {
      youtubeLink: string;
      message: string;
      recipient?: string;
    }) => submitDJRequest({ youtubeLink, message, recipient, token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['djRequests']);
      },
    },
  );
};


// New hook for submitting DJ access request
export const submitDJAccessRequest = async ({ token }: { token: string }) => {
  const response = await fetch(`${APIBase}/client/user/request-dj`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to submit DJ access request.');
  }
  const data = await response.json();
  return data;
};

export const useSubmitDJAccessRequest = (token: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => submitDJAccessRequest({ token }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['djRequests']);
    },
  });
};


export const fetchDJAccessRequests = async (token: string) => {
  const response = await fetch(`${APIBase}/client/user/dj-request`, {
    headers: { Authorization: `Bearer ${token}`, 
    'Content-Type': 'application/json'},
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to fetch DJ access requests');
  }
  const data = await response.json();
  return data.requests;
};

export const useDJAccessRequests = (token: string) => {
  return useQuery(['djAccessRequests'], () => fetchDJAccessRequests(token), {
    enabled: !!token,
    refetchInterval: 10000,
  });
};

export const acceptDJAccessRequest = async ({
  requestId,
  token,
}: {
  requestId: string;
  token: string;
}) => {
  const response = await fetch(`${APIBase}/client/user/accept-dj/${requestId}`, {
    method: 'PUT', // or PUT if that's what your endpoint expects
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to accept DJ access request.');
  }
  const data = await response.json();
  return data;
};

export const useAcceptDJAccessRequest = (token: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ requestId }: { requestId: string }) =>
      acceptDJAccessRequest({ requestId, token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['djAccessRequests']);
      },
    }
  );
};

export const rejectDJAccessRequest = async ({
  requestId,
  token,
}: {
  requestId: string;
  token: string;
}) => {
  const response = await fetch(`${APIBase}/client/user/reject-dj/${requestId}`, {
    method: 'PUT', // or PUT if your backend expects that method
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to reject DJ access request.');
  }
  const data = await response.json();
  return data;
};

export const useRejectDJAccessRequest = (token: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ requestId }: { requestId: string }) =>
      rejectDJAccessRequest({ requestId, token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['djAccessRequests']);
      },
    }
  );
};



export const fetchDJs = async (token: string) => {
  const response = await fetch(`${APIBase}/client/user/djs`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to fetch DJs');
  }
  const data = await response.json();
  return data.djs;
};

export const useDJs = (token: string) => {
  return useQuery(['djs'], () => fetchDJs(token), {
    enabled: !!token,
    refetchInterval: 10000,
  });
};
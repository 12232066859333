// src/pages/DJRequestsFeaturePage.tsx
import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Helmet } from 'react-helmet';
import { useLanguage } from 'Contexts/LanguageContext';
import Modal from 'react-modal';
import { FaClipboard, FaChartBar, FaUsers, FaRegLightbulb  } from 'react-icons/fa';

// Define translations for this page, including contact button labels
const translations = {
  english: {
    djFeaturePageTitle: 'DJ Requests Feature',
    djFeaturePageDescription:
      'Our DJ Requests feature empowers venues to generate additional revenue, gain insights into customer preferences, and ensure every song selection matters.',
    djFeatureHeader: 'DJ Requests Feature',
    djFeatureDescription:
      'Our DJ Requests feature allows your guests to send song requests directly to your DJ, creating a dynamic and interactive music experience. This not only enhances the guest experience but also opens up an additional revenue stream for your venue.',
    djFeatureCustomerInsight:
      'Gain valuable insights into your customers’ preferences—knowing which songs are requested most frequently helps you tailor your playlist to keep the energy high and your guests engaged.',
    djFeatureRevenue:
      'Every song request is an opportunity to earn more. With our system, you can monetize song requests and boost your revenue, ensuring that every selection counts.',
    contactUs: 'Request More Information',
    contactFormSuccess: "Thank you for contacting us! We'll be in touch shortly.",
    contactNamePlaceholder: 'Your Name',
    contactEmailPlaceholder: 'Your Email',
    contactMessagePlaceholder: 'Your Message',
    contactFormSubmit: 'Submit',
    whatsappContactLabel: 'Contact via WhatsApp',
    emailContactLabel: 'Email Manager',
    whatsappContactURL: 'https://wa.me/48451110303',
    emailContactURL: 'mailto:manager@takientertainment.com?subject=DJ%20Requests%20Inquiry',
    faq_title: 'Frequently Asked Questions',
    faq_1_question: 'How does the DJ Requests feature benefit my venue?',
    faq_1_answer:
      'It increases revenue by monetizing song requests and provides valuable insights into customer preferences.',
    faq_2_question: 'Can guests request songs directly?',
    faq_2_answer: 'Yes, guests can send song requests directly to your DJ in real time.',
    faq_3_question: 'How do I manage the song queue?',
    faq_3_answer: 'Our system automates queue management for a seamless music experience.',
    comparison_title: 'Comparison: Direct Request vs. DJ Requests Feature',
    comparison_direct: 'Direct Request',
    comparison_system: 'DJ Requests Feature',
    comparison_feature_1: 'Revenue',
    comparison_feature_1_system: 'Monetized & Optimized',
    comparison_feature_1_direct: 'Limited',
    comparison_feature_2: 'Customer Insight',
    comparison_feature_2_system: 'Valuable Data',
    comparison_feature_2_direct: 'None',
    comparison_feature_3: 'Ease of Use',
    comparison_feature_3_system: 'Automated',
    comparison_feature_3_direct: 'Manual',
    features_title: 'Key Features',
    feature1_title: 'Increased Revenue',
    feature1_desc: 'Monetize every song request and boost your earnings effortlessly.',
    feature2_title: 'Customer Insights',
    feature2_desc: 'Gain real-time data on customer preferences to tailor your music.',
    feature3_title: 'Enhanced Engagement',
    feature3_desc: 'Create a dynamic and interactive music experience for your guests.',
    final_cta_title: 'Are you ready?',
    contact_title: 'Contact Us',
    call_now: 'Call Now',
    chat_whatsapp: 'Chat via WhatsApp',
  },
  spanish: {
    djFeaturePageTitle: 'Función de Solicitudes de DJ',
    djFeaturePageDescription:
      'Nuestra función de solicitudes de DJ permite a los locales generar ingresos adicionales, conocer las preferencias de sus clientes y hacer que cada selección musical cuente.',
    djFeatureHeader: 'Función de Solicitudes de DJ',
    djFeatureDescription:
      'Nuestra función de solicitudes de DJ permite a tus invitados enviar peticiones de canciones directamente al DJ, creando una experiencia musical dinámica e interactiva. Esto no solo mejora la experiencia del cliente, sino que también abre una fuente adicional de ingresos para tu local.',
    djFeatureCustomerInsight:
      'Obtén valiosos conocimientos sobre las preferencias de tus clientes—saber qué canciones se solicitan con más frecuencia te ayuda a adaptar tu lista de reproducción para mantener la energía y el compromiso de tus invitados.',
    djFeatureRevenue:
      'Cada solicitud de canción es una oportunidad para ganar más. Con nuestro sistema, puedes monetizar las solicitudes y aumentar tus ingresos, asegurando que cada selección cuente.',
    contactUs: 'Solicitar Más Información',
    contactFormSuccess: '¡Gracias por contactarnos! Nos pondremos en contacto contigo pronto.',
    contactNamePlaceholder: 'Tu Nombre',
    contactEmailPlaceholder: 'Tu Correo',
    contactMessagePlaceholder: 'Tu Mensaje',
    contactFormSubmit: 'Enviar',
    whatsappContactLabel: 'Contactar por WhatsApp',
    emailContactLabel: 'Enviar Email al Manager',
    whatsappContactURL: 'https://wa.me/48451110303',
    emailContactURL: 'mailto:manager@takientertainment.com?subject=Consulta%20de%20Solicitudes%20DJ',
    faq_title: 'Preguntas Frecuentes',
    faq_1_question: '¿Cómo beneficia mi local la función de solicitudes de DJ?',
    faq_1_answer:
      'Aumenta los ingresos al monetizar las solicitudes de canciones y ofrece información sobre las preferencias de los clientes.',
    faq_2_question: '¿Pueden los invitados solicitar canciones directamente?',
    faq_2_answer: 'Sí, los invitados pueden enviar solicitudes de canciones al DJ en tiempo real.',
    faq_3_question: '¿Cómo se gestiona la cola de canciones?',
    faq_3_answer:
      'Nuestro sistema automatiza la gestión de la cola para ofrecer una experiencia musical sin interrupciones.',
    comparison_title: 'Comparación: Solicitud Directa vs. Función de Solicitudes de DJ',
    comparison_direct: 'Solicitud Directa',
    comparison_system: 'Función de Solicitudes de DJ',
    comparison_feature_1: 'Ingresos',
    comparison_feature_1_system: 'Monetizado y Optimizado',
    comparison_feature_1_direct: 'Limitado',
    comparison_feature_2: 'Conocimiento del Cliente',
    comparison_feature_2_system: 'Datos Valiosos',
    comparison_feature_2_direct: 'Ninguno',
    comparison_feature_3: 'Facilidad de Uso',
    comparison_feature_3_system: 'Automatizado',
    comparison_feature_3_direct: 'Manual',
    features_title: 'Características Clave',
    feature1_title: 'Mayor Ingreso',
    feature1_desc: 'Monetiza cada solicitud de canción y aumenta tus ganancias sin esfuerzo.',
    feature2_title: 'Conocimiento del Cliente',
    feature2_desc: 'Obtén datos en tiempo real sobre las preferencias de tus clientes para adaptar tu música.',
    feature3_title: 'Mayor Compromiso',
    feature3_desc: 'Crea una experiencia musical dinámica e interactiva para tus invitados.',
    final_cta_title: '¿Estás listo?',
    contact_title: 'Contáctanos',
    call_now: 'Llama Ahora',
    chat_whatsapp: 'Chatea por WhatsApp',
  },
  polish: {
    djFeaturePageTitle: 'Funkcja Żądań DJ',
    djFeaturePageDescription:
      'Nasza funkcja żądań DJ pozwala lokalom generować dodatkowe przychody, poznać preferencje klientów i sprawić, by każdy wybór utworu miał znaczenie.',
    djFeatureHeader: 'Funkcja Żądań DJ',
    djFeatureDescription:
      'Nasza funkcja żądań DJ umożliwia gościom wysyłanie żądań utworów bezpośrednio do DJ-a, tworząc dynamiczne i interaktywne doświadczenie muzyczne. Dzięki temu nie tylko zwiększasz satysfakcję gości, ale także otwierasz nowy strumień przychodów dla Twojego lokalu.',
    djFeatureCustomerInsight:
      'Poznaj cenne informacje o preferencjach klientów – wiedza o najczęściej żądanych utworach pozwala dostosować listę odtwarzania, aby utrzymać wysoki poziom energii i zaangażowanie gości.',
    djFeatureRevenue:
      'Każde żądanie utworu to szansa na dodatkowe przychody. Dzięki naszemu systemowi możesz monetyzować żądania i zwiększać swoje przychody, dbając o to, by każdy wybór miał znaczenie.',
    contactUs: 'Zapytaj o Informacje',
    contactFormSuccess: 'Dziękujemy za kontakt! Wkrótce się z Tobą skontaktujemy.',
    contactNamePlaceholder: 'Twoje Imię',
    contactEmailPlaceholder: 'Twój Email',
    contactMessagePlaceholder: 'Twoja Wiadomość',
    contactFormSubmit: 'Wyślij',
    whatsappContactLabel: 'Skontaktuj się przez WhatsApp',
    emailContactLabel: 'Wyślij Email do Managera',
    whatsappContactURL: 'https://wa.me/48451110303',
    emailContactURL: 'mailto:manager@takientertainment.com?subject=Zapytanie%20dotyczące%20Żądań%20DJ',
    faq_title: 'Najczęściej Zadawane Pytania',
    faq_1_question: 'Jak funkcja żądań DJ wpływa na lokal?',
    faq_1_answer:
      'Funkcja ta zwiększa przychody poprzez monetyzację żądań utworów oraz dostarcza cennych informacji o preferencjach klientów.',
    faq_2_question: 'Czy goście mogą bezpośrednio żądać utworów?',
    faq_2_answer: 'Tak, goście mogą wysyłać żądania utworów bezpośrednio do DJ-a w czasie rzeczywistym.',
    faq_3_question: 'Jak zarządzać kolejką utworów?',
    faq_3_answer:
      'Nasz system automatycznie zarządza kolejką, zapewniając płynne i bezproblemowe odtwarzanie utworów.',
    comparison_title: 'Porównanie: Bezpośrednia Prośba vs. Funkcja Żądań DJ',
    comparison_direct: 'Bezpośrednia Prośba',
    comparison_system: 'Funkcja Żądań DJ',
    comparison_feature_1: 'Przychody',
    comparison_feature_1_system: 'Monetyzowane i zoptymalizowane',
    comparison_feature_1_direct: 'Ograniczone',
    comparison_feature_2: 'Poznanie Klienta',
    comparison_feature_2_system: 'Cenne dane',
    comparison_feature_2_direct: 'Brak',
    comparison_feature_3: 'Łatwość Użytkowania',
    comparison_feature_3_system: 'Automatyczny',
    comparison_feature_3_direct: 'Ręczny',
    features_title: 'Kluczowe Funkcje',
    feature1_title: 'Zwiększone Przychody',
    feature1_desc: 'Monetyzuj każde żądanie utworu i zwiększaj swoje przychody bez wysiłku.',
    feature2_title: 'Poznanie Klienta',
    feature2_desc: 'Zdobądź dane w czasie rzeczywistym o preferencjach klientów, aby dostosować muzykę do ich gustów.',
    feature3_title: 'Lepsze Zaangażowanie',
    feature3_desc: 'Stwórz dynamiczne i interaktywne doświadczenie muzyczne dla swoich gości.',
    final_cta_title: 'Czy jesteś gotowy?',
    contact_title: 'Skontaktuj się',
    call_now: 'Zadzwoń teraz',
    chat_whatsapp: 'Czat przez WhatsApp',
  },
};

const DJRequestsFeaturePage: React.FC = () => {
  const { language } = useLanguage();
  const t = translations[language as keyof typeof translations];
  const [state, handleSubmit] = useForm('xkgjwnev');
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const handleOpenContactModal = () => setContactModalOpen(true);
  const handleCloseContactModal = () => setContactModalOpen(false);

  return (
    <>
      <Helmet>
        <title>{t.djFeaturePageTitle}</title>
        <meta name="description" content={t.djFeaturePageDescription} />
      </Helmet>
      <div className="min-h-screen bg-gray-900 text-white p-8 pt-24">
        <h1 className="text-4xl font-bold mb-6 text-center">{t.djFeatureHeader}</h1>
        <div className="max-w-3xl mx-auto space-y-4">
          <p className="text-lg">{t.djFeatureDescription}</p>
          <p className="text-lg">{t.djFeatureCustomerInsight}</p>
          <p className="text-lg">{t.djFeatureRevenue}</p>
        </div>
        <div className="max-w-xl mx-auto mt-10">
          <h2 className="text-2xl font-bold mb-4 text-center">{t.contactUs}</h2>
          {state.succeeded ? (
            <p className="text-green-400 mb-4 text-center">{t.contactFormSuccess}</p>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                id="name"
                type="text"
                name="name"
                placeholder={t.contactNamePlaceholder}
                className="w-full p-2 rounded-md bg-gray-700 text-white"
                required
              />
              <input
                id="email"
                type="email"
                name="email"
                placeholder={t.contactEmailPlaceholder}
                className="w-full p-2 rounded-md bg-gray-700 text-white"
                required
              />
              <textarea
                id="message"
                name="message"
                placeholder={t.contactMessagePlaceholder}
                className="w-full p-2 rounded-md bg-gray-700 text-white"
                rows={4}
                required
              ></textarea>
              <ValidationError prefix="Email" field="email" errors={state.errors} />
              <ValidationError prefix="Message" field="message" errors={state.errors} />
              <button
                type="submit"
                disabled={state.submitting}
                className="w-full px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
              >
                {t.contactFormSubmit}
              </button>
            </form>
          )}
          <div className="mt-6 flex flex-col md:flex-row gap-4">
            <a
              href={t.whatsappContactURL}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-auto px-4 py-2 rounded-full bg-gradient-to-r from-green-400 via-green-500 to-green-600 text-white font-bold text-center hover:opacity-90 transition"
            >
              {t.whatsappContactLabel}
            </a>
            <a
              href={t.emailContactURL}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-auto px-4 py-2 rounded-full bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 text-white font-bold text-center hover:opacity-90 transition"
            >
              {t.emailContactLabel}
            </a>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="max-w-3xl mx-auto mt-16">
          <h2 className="text-3xl font-bold mb-6 text-center">{t.faq_title}</h2>
          <div className="space-y-4">
            <FAQItem question={t.faq_1_question} answer={t.faq_1_answer} />
            <FAQItem question={t.faq_2_question} answer={t.faq_2_answer} />
            <FAQItem question={t.faq_3_question} answer={t.faq_3_answer} />
          </div>
        </div>

        {/* Comparison Section */}
        <div className="max-w-4xl mx-auto mt-16">
          <h2 className="text-3xl font-bold mb-6 text-center">{t.comparison_title}</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full text-left">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b">Feature</th>
                  <th className="px-4 py-2 border-b">{t.comparison_system}</th>
                  <th className="px-4 py-2 border-b">{t.comparison_direct}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="hover:bg-indigo-900 transition-colors cursor-pointer">
                  <td className="px-4 py-2 border-b">{t.comparison_feature_1}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_1_system}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_1_direct}</td>
                </tr>
                <tr className="hover:bg-indigo-900 transition-colors cursor-pointer">
                  <td className="px-4 py-2 border-b">{t.comparison_feature_2}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_2_system}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_2_direct}</td>
                </tr>
                <tr className="hover:bg-indigo-900 transition-colors cursor-pointer">
                  <td className="px-4 py-2 border-b">{t.comparison_feature_3}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_3_system}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_3_direct}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Features Section */}
        <div className="max-w-4xl mx-auto mt-16">
          <h2 className="text-3xl font-bold mb-6 text-center">{t.features_title}</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard title={t.feature1_title} description={t.feature1_desc} icon={<FaChartBar />} />
            <FeatureCard title={t.feature2_title} description={t.feature2_desc} icon={<FaUsers />} /> 
            <FeatureCard title={t.feature3_title} description={t.feature3_desc} icon={<FaRegLightbulb />} />
          </div>
        </div>

        {/* Final Call-To-Action Section */}
        <div className="max-w-3xl mx-auto mt-16 text-center">
          <h2 className="text-4xl font-bold mb-6">{t.final_cta_title}</h2>
          <div className="flex flex-col md:flex-row justify-center items-center gap-6">
            <button
              onClick={handleOpenContactModal}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
            >
              {t.contact_title}
            </button>
            <a
              href="tel:+48451110303"
              className="px-6 py-3 rounded-full bg-gradient-to-r from-green-500 via-green-600 to-green-700 text-white font-bold hover:opacity-90 transition"
            >
              {t.call_now}
            </a>
            <a
              href="https://wa.me/48451110303"
              className="px-6 py-3 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white font-bold hover:opacity-90 transition"
            >
              {t.chat_whatsapp}
            </a>
          </div>
        </div>
      </div>

      {/* Contact Form Modal */}
      {contactModalOpen && (
        <Modal
          isOpen={contactModalOpen}
          onRequestClose={handleCloseContactModal}
          contentLabel="Contact Form"
          className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-75"
        >
          <div className="w-full max-w-md bg-gray-800 p-6 rounded-lg shadow-lg relative">
            <button
              onClick={handleCloseContactModal}
              className="absolute top-2 right-2 text-xl text-gray-400 hover:text-white"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4 text-white">{t.contactUs}</h2>
            {state.succeeded ? (
              <p className="text-green-400 mb-4 text-center">{t.contactFormSuccess}</p>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4">
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder={t.contactNamePlaceholder}
                  className="w-full p-2 rounded-md bg-gray-700 text-white"
                  required
                />
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder={t.contactEmailPlaceholder}
                  className="w-full p-2 rounded-md bg-gray-700 text-white"
                  required
                />
                <textarea
                  id="message"
                  name="message"
                  placeholder={t.contactMessagePlaceholder}
                  className="w-full p-2 rounded-md bg-gray-700 text-white"
                  rows={4}
                  required
                ></textarea>
                <ValidationError prefix="Email" field="email" errors={state.errors} />
                <ValidationError prefix="Message" field="message" errors={state.errors} />
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="w-full px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
                >
                  {t.contactFormSubmit}
                </button>
              </form>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default DJRequestsFeaturePage;

const FAQItem: React.FC<{ question: string; answer: string }> = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="p-4 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl transition-all duration-300 hover:shadow-xl">
      <button
        onClick={() => setOpen(!open)}
        className="w-full text-left flex justify-between items-center focus:outline-none"
      >
        <span className="text-xl font-bold">{question}</span>
        <span className="text-2xl">{open ? '−' : '+'}</span>
      </button>
      <div className={`overflow-hidden transition-all duration-300 ${open ? 'max-h-96 mt-2' : 'max-h-0'}`}>
        <p className="text-lg">{answer}</p>
      </div>
    </div>
  );
};

interface FeatureCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, icon }) => (
  <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-lg transform transition-all hover:scale-105">
    <div className="flex items-center mb-4">
      <div className="p-3 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full shadow-lg mr-4">{icon}</div>
      <h4 className="text-2xl font-bold">{title}</h4>
    </div>
    <p className="text-lg">{description}</p>
  </div>
);

export { FAQItem, FeatureCard };

// src/PartyTicket/SongRequests/components/SettingsModal.tsx
import React, { useState } from 'react';
import { FaArrowDown, FaArrowUp, FaTrash } from 'react-icons/fa';
import ReactSwitch from 'react-switch';
import { SectionKey, SectionType } from '../types/index';

type SettingsModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  handleToggle: (section: SectionKey) => void;
  activeTab: string;
  setActiveTab: (tab: SectionType) => void;
  sectionsVisibility: Record<string, boolean>;
  selectVenue: (venueId: string) => void;
  venues: { _id: string; name: string }[];
  rooms: { _id: string; name: string }[];
  playlists: { _id: string; name: string }[];
  battles: { _id: string; name: string }[];
  setSelectedRoom: (roomId: string) => void;
  setSelectedPlaylist: (playlistId: string) => void;
  setCurrentVideoIndex: (index: number) => void;
  setSelectedBattle: (battleId: string) => void;
  selectedRoom: string;
  selectedPlaylist: string;
  selectedBattle: string;
  selectedVenue: string;
  videoUrls: string[];
  addVideo: (videoUrl: string) => void;
  removeVideo: (index: number) => void;
  moveVideo: (index: number, direction: 'up' | 'down') => void;
};

const SettingsModal: React.FC<SettingsModalProps> = ({
  isModalOpen,
  closeModal,
  handleToggle,
  activeTab,
  setActiveTab,
  sectionsVisibility,
  selectVenue,
  venues,
  setSelectedRoom,
  setSelectedPlaylist,
  setCurrentVideoIndex,
  setSelectedBattle,
  rooms,
  playlists,
  battles,
  selectedRoom,
  selectedPlaylist,
  selectedBattle,
  selectedVenue,
  videoUrls,
  addVideo,
  removeVideo,
  moveVideo,
}) => {
  const [newVideoUrl, setNewVideoUrl] = useState('');

  if (!isModalOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center">
      <div className="bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 p-8 rounded-2xl shadow-2xl w-[50rem] text-white relative">
        <div className="flex flex-row">
          {/* Settings Buttons */}
          <div className="flex justify-start items-start mb-6 pb-3 flex-col">
            <button
              className={`text-md font-bold text-left border-b border-gray-700 mb-3 ${
                activeTab === 'general-settings' ? 'text-teal-400' : 'text-gray-400'
              }`}
              onClick={() => setActiveTab('general-settings')}
            >
              General Settings
            </button>
            <button
              className={`text-md font-bold text-left border-b border-gray-700 mb-3 ${
                activeTab === 'videos' ? 'text-teal-400' : 'text-gray-400'
              }`}
              onClick={() => setActiveTab('videos')}
            >
              Videos Settings
            </button>
            <button
              className={`text-md font-bold text-left border-b border-gray-700 mb-3 ${
                activeTab === 'karaoke' ? 'text-teal-400' : 'text-gray-400'
              }`}
              onClick={() => setActiveTab('karaoke')}
            >
              Karaoke Leaderboard
            </button>
            <button
              className={`text-md font-bold text-left border-b border-gray-700 mb-3 ${
                activeTab === 'dj-battle' ? 'text-teal-400' : 'text-gray-400'
              }`}
              onClick={() => setActiveTab('dj-battle')}
            >
              DJ Battle
            </button>
            <button
              className={`text-md font-bold text-left border-b border-gray-700 mb-3 ${
                activeTab === 'venue-qr' ? 'text-teal-400' : 'text-gray-400'
              }`}
              onClick={() => setActiveTab('venue-qr')}
            >
              Venue QR
            </button>
            <button
              className={`text-md font-bold text-left border-b border-gray-700 mb-3 ${
                activeTab === 'promotions' ? 'text-teal-400' : 'text-gray-400'
              }`}
              onClick={() => setActiveTab('promotions')}
            >
              Venue Promotions
            </button>
            <button
              className={`text-md font-bold text-left border-b border-gray-700 mb-3 ${
                activeTab === 'total-leaderboard' ? 'text-teal-400' : 'text-gray-400'
              }`}
              onClick={() => setActiveTab('total-leaderboard')}
            >
              Total Coins Leaderboard
            </button>
            {/* New: DJ Requests QR Toggle */}
            <button
              className={`text-md font-bold text-left border-b border-gray-700 mb-3 ${
                activeTab === 'dj-requests-qr' ? 'text-teal-400' : 'text-gray-400'
              }`}
              onClick={() => setActiveTab('dj-requests-qr')}
            >
              DJ Requests QR
            </button>
          </div>

          {/* Tab Content */}
          <div className="space-y-4 ml-5 w-full">
            {activeTab === 'general-settings' && (
              <div>
                <h4 className="text-xl font-semibold mb-4">Venue Settings</h4>
                <select
                  onChange={(e) => selectVenue(e.target.value)}
                  value={selectedVenue}
                  className="w-full mb-4 p-2 bg-gray-700 rounded-md"
                >
                  <option value="">Select Venue</option>
                  {venues.map((venue) => (
                    <option key={venue._id} value={venue._id}>
                      {venue.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {activeTab === 'videos' && (
              <div>
                <h4 className="text-xl font-semibold mb-4">Video Settings</h4>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm">Show Videos Section</span>
                  <ReactSwitch
                    checked={sectionsVisibility.videos}
                    onChange={() => handleToggle('videos')}
                    offColor="#333333"
                    onColor="#6e5906"
                    offHandleColor="#1F1F1F"
                    onHandleColor="#E6B800"
                    handleDiameter={22}
                    height={22}
                    width={50}
                    borderRadius={50}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
                    className="transition-all duration-300 ease-in-out"
                  />
                </div>

                <input
                  type="text"
                  placeholder="Enter YouTube Video URL"
                  value={newVideoUrl}
                  onChange={(e) => setNewVideoUrl(e.target.value)}
                  className="w-full mb-4 p-2 bg-gray-700 rounded-md"
                />
                <button
                  onClick={() => {
                    addVideo(newVideoUrl);
                    setNewVideoUrl('');
                  }}
                  className="bg-teal-500 p-2 rounded-md text-white"
                >
                  Add Video
                </button>

                {/* Display Video URLs */}
                <div className="mt-4">
                  <h4 className="text-md font-semibold">Added Videos:</h4>
                  <ul className="h-72 overflow-scroll">
                    {videoUrls.map((url, index) => {
                      const videoId = new URL(url).searchParams.get('v') || '';
                      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

                      return (
                        <div
                          key={index}
                          className="flex items-center space-x-4 p-4 bg-gray-800 rounded-lg shadow-md transition hover:scale-105"
                        >
                          {/* Video Thumbnail */}
                          <div className="w-16 h-16 shrink-0 rounded-lg overflow-hidden shadow-md mr-auto">
                            <img src={thumbnailUrl} alt="Video Thumbnail" className="w-full h-full object-cover" />
                          </div>

                          {/* Video Controls */}
                          <div className="flex items-center space-x-2">
                            <button
                              onClick={() => moveVideo(index, 'up')}
                              className="p-2 rounded-full bg-gradient-to-r from-blue-500 to-teal-500 hover:from-blue-600 hover:to-teal-600 shadow-md text-white transition"
                              title="Move Up"
                            >
                              <FaArrowUp />
                            </button>
                            <button
                              onClick={() => moveVideo(index, 'down')}
                              className="p-2 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 shadow-md text-white transition"
                              title="Move Down"
                            >
                              <FaArrowDown />
                            </button>
                            <button
                              onClick={() => removeVideo(index)}
                              className="p-2 rounded-full bg-gradient-to-r from-red-500 to-orange-500 hover:from-red-600 hover:to-orange-600 shadow-md text-white transition"
                              title="Remove"
                            >
                              <FaTrash />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}

            {activeTab === 'karaoke' && (
              <div>
                <h4 className="text-xl font-semibold mb-4">Karaoke Leaderboard Settings</h4>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm">Show Karaoke Leaderboard</span>
                  <ReactSwitch
                    checked={sectionsVisibility.karaokeLeaderboard}
                    onChange={() => handleToggle('karaokeLeaderboard')}
                    offColor="#333333"
                    onColor="#6e5906"
                    offHandleColor="#1F1F1F"
                    onHandleColor="#E6B800"
                    handleDiameter={22}
                    height={22}
                    width={50}
                    borderRadius={50}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
                    className="transition-all duration-300 ease-in-out"
                  />
                </div>
                {/* Select Room */}
                <select
                  onChange={(e) => setSelectedRoom(e.target.value)}
                  value={selectedRoom}
                  className="w-full mb-4 p-2 bg-gray-700 rounded-md"
                >
                  <option value="">Select Room</option>
                  {rooms.map((room) => (
                    <option key={room._id} value={room._id}>
                      {room.name}
                    </option>
                  ))}
                </select>

                {/* Select Playlist */}
                <select
                  onChange={(e) => setSelectedPlaylist(e.target.value)}
                  value={selectedPlaylist}
                  className="w-full mb-4 p-2 bg-gray-700 rounded-md"
                >
                  <option value="">Select Playlist</option>
                  {playlists.map((playlist) => (
                    <option key={playlist._id} value={playlist._id}>
                      {playlist.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {activeTab === 'dj-battle' && (
              <div>
                <h4 className="text-xl font-semibold mb-4">DJ Battle Settings</h4>
                <select
                  onChange={(e) => setSelectedBattle(e.target.value)}
                  value={selectedBattle}
                  className="w-full mb-4 p-2 bg-gray-700 rounded-md"
                >
                  <option value="">Select Battle</option>
                  {battles.map((battle) => (
                    <option key={battle._id} value={battle._id}>
                      {battle.name}
                    </option>
                  ))}
                </select>

                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm">Show Battle Data</span>
                  <ReactSwitch
                    checked={sectionsVisibility.battleData}
                    onChange={() => handleToggle('battleData')}
                    offColor="#333333"
                    onColor="#6e5906"
                    offHandleColor="#1F1F1F"
                    onHandleColor="#E6B800"
                    handleDiameter={22}
                    height={22}
                    width={50}
                    borderRadius={50}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
                    className="transition-all duration-300 ease-in-out"
                  />
                </div>
              </div>
            )}

            {activeTab === 'venue-qr' && (
              <div>
                <h4 className="text-xl font-semibold mb-4">Venue QR Code Settings</h4>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm">Show Venue QR</span>
                  <ReactSwitch
                    checked={sectionsVisibility.venueQR}
                    onChange={() => handleToggle('venueQR')}
                    offColor="#333333"
                    onColor="#6e5906"
                    offHandleColor="#1F1F1F"
                    onHandleColor="#E6B800"
                    handleDiameter={22}
                    height={22}
                    width={50}
                    borderRadius={50}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
                    className="transition-all duration-300 ease-in-out"
                  />
                </div>
              </div>
            )}

            {activeTab === 'promotions' && (
              <div>
                <h4 className="text-xl font-semibold mb-4">Venue Promotions Settings</h4>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm">Show Venue Promotions</span>
                  <ReactSwitch
                    checked={sectionsVisibility.promotions}
                    onChange={() => handleToggle('promotions')}
                    offColor="#333333"
                    onColor="#6e5906"
                    offHandleColor="#1F1F1F"
                    onHandleColor="#E6B800"
                    handleDiameter={22}
                    height={22}
                    width={50}
                    borderRadius={50}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
                    className="transition-all duration-300 ease-in-out"
                  />
                </div>
              </div>
            )}

            {activeTab === 'total-leaderboard' && (
              <div>
                <h4 className="text-xl font-semibold mb-4">Total Coins Leaderboard Settings</h4>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm">Show Total Coins Leaderboard</span>
                  <ReactSwitch
                    checked={sectionsVisibility.totalCoins}
                    onChange={() => handleToggle('totalCoins')}
                    offColor="#333333"
                    onColor="#6e5906"
                    offHandleColor="#1F1F1F"
                    onHandleColor="#E6B800"
                    handleDiameter={22}
                    height={22}
                    width={50}
                    borderRadius={50}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
                    className="transition-all duration-300 ease-in-out"
                  />
                </div>
              </div>
            )}

            {activeTab === 'dj-requests-qr' && (
              <div>
                <h4 className="text-xl font-semibold mb-4">DJ Requests QR Settings</h4>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm">Show DJ Requests QR</span>
                  <ReactSwitch
                    checked={sectionsVisibility.djRequestsQR}
                    onChange={() => handleToggle('djRequestsQR')}
                    offColor="#333333"
                    onColor="#6e5906"
                    offHandleColor="#1F1F1F"
                    onHandleColor="#E6B800"
                    handleDiameter={22}
                    height={22}
                    width={50}
                    borderRadius={50}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
                    className="transition-all duration-300 ease-in-out"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Modal Buttons */}
        <div className="flex justify-end space-x-4 mt-6">
          <button onClick={closeModal} className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500">
            Close
          </button>
          <button onClick={closeModal} className="px-4 py-2 bg-teal-500 text-white rounded-lg hover:bg-teal-400">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;

import React from 'react';
import { useLanguage } from 'Contexts/LanguageContext';
import Button from 'react-bootstrap/Button';
import { FaMusic, FaPeopleCarry, FaChartLine, FaCamera, FaRobot, FaTicketAlt, FaHeadphones } from 'react-icons/fa';
import Typed from 'react-typed';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Overview: React.FC = () => {
  const { language } = useLanguage();

  // Navigate to the WebApp (profile) section.
  const goToWebApp = () => {
    window.location.href = '/app/profile';
  };

  // Typed strings for the hero section.
  const getTypedStrings = () => {
    switch (language) {
      case 'spanish':
        return [
          'Bienvenido a Taki Entertainment',
          'Tecnología Nocturna Innovadora',
          'Taki Taki Katowice',
          'Foreigners: Colectivo Musical',
          'Robot Host & Taki Ticketing'
        ];
      case 'polish':
        return [
          'Witamy w Taki Entertainment',
          'Innowacyjna Technologia Nocna',
          'Taki Taki Katowice',
          'Foreigners: Kolektyw Muzyczny',
          'Robot Host i Taki Ticketing'
        ];
      default:
        return [
          'Welcome to Taki Entertainment',
          'Innovative Nightlife Technology',
          'Taki Taki Katowice',
          'Foreigners: Music Collective',
          'Robot Host & Taki Ticketing'
        ];
    }
  };

  // Meta description for SEO.
  const getMetaDescription = () => {
    switch (language) {
      case 'spanish':
        return 'Taki Entertainment ofrece tecnología nocturna innovadora, experiencias en Taki Taki Katowice, el colectivo musical Foreigners y soluciones como Robot Host y Taki Ticketing. Descubre el futuro de los eventos.';
      case 'polish':
        return 'Taki Entertainment oferuje innowacyjną technologię nocną, doświadczenia w Taki Taki Katowice, kolektyw muzyczny Foreigners oraz rozwiązania takie jak Robot Host i Taki Ticketing. Odkryj przyszłość wydarzeń.';
      default:
        return 'Taki Entertainment delivers innovative nightlife technology, experiences at Taki Taki Katowice, the Foreigners music collective, and solutions like Robot Host & Taki Ticketing. Discover the future of events.';
    }
  };

  // Section texts for various parts.
  const getSectionText = (section: string) => {
    switch (section) {
      case 'technology':
        switch (language) {
          case 'spanish':
            return 'Nuestra tecnología vanguardista integra soluciones digitales para transformar cada evento en una experiencia inolvidable.';
          case 'polish':
            return 'Nasza nowatorska technologia integruje rozwiązania cyfrowe, aby każda impreza stała się niezapomnianym doświadczeniem.';
          default:
            return 'Our cutting-edge technology integrates digital solutions to transform every event into an unforgettable experience.';
        }
      case 'venue':
        switch (language) {
          case 'spanish':
            return 'Taki Taki Katowice es nuestro emblemático espacio, diseñado para ofrecer noches vibrantes y sofisticadas.';
          case 'polish':
            return 'Taki Taki Katowice to nasze flagowe miejsce, zaprojektowane, aby oferować pełne wrażeń i wyrafinowane noce.';
          default:
            return 'Taki Taki Katowice is our flagship venue, designed to deliver vibrant and sophisticated nights.';
        }
      case 'collective':
        switch (language) {
          case 'spanish':
            return 'Foreigners es nuestro colectivo musical futurista, fusionando diversos géneros para crear experiencias sonoras únicas.';
          case 'polish':
            return 'Foreigners to nasz futurystyczny kolektyw muzyczny, łączący różne gatunki, aby tworzyć unikalne doświadczenia dźwiękowe.';
          default:
            return 'Foreigners is our futuristic music collective, blending various genres to create unique sonic experiences.';
        }
      default:
        return '';
    }
  };

  // Text for the WebApp button.
  const getButtonText = () => {
    switch (language) {
      case 'spanish':
        return 'Accede a Nuestra WebApp';
      case 'polish':
        return 'Wejdź do naszej WebApp';
      default:
        return 'Enter Our WebApp';
    }
  };

  // New Innovations Section: Robot Host, Taki Ticketing & Foreigners.
  const renderInnovationsSection = () => {
    return (
      <section className="w-full bg-black bg-opacity-80 rounded-lg p-8 my-6">
        <h2 className="text-3xl text-white mb-4">
          {language === 'spanish'
            ? 'Nuestras Innovaciones'
            : language === 'polish'
            ? 'Nasze Innowacje'
            : 'Our Innovations'}
        </h2>
        <div className="flex flex-wrap justify-center gap-6">
          {/* Robot Host */}
          <div className="flex flex-col items-center text-white p-4">
            <FaRobot className="text-6xl mb-2" />
            <h3 className="text-xl font-semibold">
              {language === 'spanish'
                ? 'Robot Host'
                : language === 'polish'
                ? 'Robot Host'
                : 'Robot Host'}
            </h3>
            <p className="text-center">
              {language === 'spanish'
                ? 'Experimenta la hostelería de eventos futurista.'
                : language === 'polish'
                ? 'Doświadcz futurystycznej obsługi wydarzeń.'
                : 'Experience futuristic event hosting.'}
            </p>
          </div>
          {/* Taki Ticketing */}
          <div className="flex flex-col items-center text-white p-4">
            <FaTicketAlt className="text-6xl mb-2" />
            <h3 className="text-xl font-semibold">
              {language === 'spanish'
                ? 'Taki Ticketing'
                : language === 'polish'
                ? 'Taki Ticketing'
                : 'Taki Ticketing'}
            </h3>
            <p className="text-center">
              {language === 'spanish'
                ? 'Solución de venta de entradas sin complicaciones para una gestión eficiente.'
                : language === 'polish'
                ? 'Bezproblemowa sprzedaż biletów dla efektywnego zarządzania.'
                : 'Seamless ticketing for efficient event management.'}
            </p>
          </div>
          {/* Foreigners */}
          <div className="flex flex-col items-center text-white p-4">
            <FaHeadphones className="text-6xl mb-2" />
            <h3 className="text-xl font-semibold">
              {language === 'spanish'
                ? 'Foreigners'
                : language === 'polish'
                ? 'Foreigners'
                : 'Foreigners'}
            </h3>
            <p className="text-center">
              {language === 'spanish'
                ? 'Nuestro colectivo musical de vanguardia.'
                : language === 'polish'
                ? 'Nasz nowoczesny kolektyw muzyczny.'
                : 'Our cutting-edge music collective.'}
            </p>
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      <Helmet>
        <title>Taki Entertainment | Innovative Nightlife Technology</title>
        <meta name="description" content={getMetaDescription()} />
        <meta name="keywords" content="Taki Entertainment, Nightlife Technology, Taki Taki Katowice, Foreigners, Music Collective, Robot Host, Taki Ticketing, Futuristic Events" />
        <link rel="canonical" href="https://www.takientertainment.com" />
      </Helmet>
      <div
        className="w-full min-h-screen flex flex-col items-center bg-cover bg-center overflow-y-auto"
        style={{ backgroundImage: 'url("assets/back_facebook.jpg")' }}
      >
        {/* Hero Section */}
        <header className="w-full bg-black bg-opacity-80 flex flex-col items-center justify-center pt-20 pb-10">
          <div className="w-full flex flex-col items-center px-4 sm:px-8 md:px-16 max-w-4xl">
            <div className="mb-4">
              <img alt="Logo Taki" src="assets/sun3RemovebgPrev.png" className="mx-auto" />
            </div>
            <h1 className="text-white text-3xl md:text-5xl uppercase font-Khula mb-6 text-center">
              <Typed strings={getTypedStrings()} typeSpeed={40} backSpeed={30} loop />
            </h1>
          </div>
        </header>

        <main className="w-full flex flex-col items-center px-4 sm:px-8 md:px-16 max-w-4xl">
          {/* Overview Section */}
          <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto">
            {language === 'english' && (
              <>
                <p className="text-lg md:text-xl text-white mb-8 mt-4">
                  We are Taki Entertainment, your go-to solution for unforgettable nightlife experiences. Whether you are looking for a dynamic club night, an intimate lounge experience, or a themed party, we have you covered. Our mission is to make every event a memorable one.
                </p>
                <img src="https://i.imgur.com/AA4xfz3.jpg" alt="Company Overview" className="w-full rounded-lg mb-6" />
              </>
            )}
            {language === 'spanish' && (
              <>
                <p className="text-lg md:text-xl text-white mb-8 mt-4">
                  Somos Taki Entertainment, tu solución ideal para experiencias nocturnas inolvidables. Ya sea que busques una noche dinámica en un club, una experiencia íntima en un lounge o una fiesta temática, lo tenemos todo cubierto. Nuestra misión es hacer que cada evento sea memorable.
                </p>
                <img src="https://i.imgur.com/AA4xfz3.jpg" alt="Company Overview" className="w-full rounded-lg mb-6" />
              </>
            )}
            {language === 'polish' && (
              <>
                <p className="text-lg md:text-xl text-white mb-8 mt-4">
                  Jesteśmy Taki Entertainment, Twoim niezawodnym partnerem w organizacji niezapomnianych doświadczeń nocnych. Niezależnie od tego, czy szukasz dynamicznej nocy klubowej, intymnej atmosfery w lounge, czy tematycznej imprezy, mamy wszystko, czego potrzebujesz. Naszą misją jest uczynienie każdego wydarzenia niezapomnianym.
                </p>
                <img src="https://i.imgur.com/AA4xfz3.jpg" alt="Company Overview" className="w-full rounded-lg mb-6" />
              </>
            )}
          </div>

          {/* "What We Do" Section */}
          <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto mt-6">
            <h2 className="text-3xl text-white mb-4">
              {language === 'english' ? 'What We Do' : language === 'spanish' ? 'Qué Hacemos' : 'Co Robimy'}
            </h2>
            <div className="flex flex-wrap justify-center gap-6 mb-6">
              <div className="flex flex-col items-center text-white">
                <FaMusic className="text-6xl mb-2" />
                <p>{language === 'english' ? 'Top-notch Music & DJs' : language === 'spanish' ? 'Música y DJs de Primera' : 'Najlepsza Muzyka i DJ-e'}</p>
              </div>
              <div className="flex flex-col items-center text-white">
                <FaPeopleCarry className="text-6xl mb-2" />
                <p>{language === 'english' ? 'Corporate Events' : language === 'spanish' ? 'Eventos Corporativos' : 'Wydarzenia Firmowe'}</p>
              </div>
              <div className="flex flex-col items-center text-white">
                <FaChartLine className="text-6xl mb-2" />
                <p>{language === 'english' ? 'Marketing & Promotion' : language === 'spanish' ? 'Marketing y Promoción' : 'Marketing i Promocja'}</p>
              </div>
              <div className="flex flex-col items-center text-white">
                <FaCamera className="text-6xl mb-2" />
                <p>{language === 'english' ? 'Photography & Videography' : language === 'spanish' ? 'Fotografía y Videografía' : 'Fotografia i Wideografia'}</p>
              </div>
            </div>
          </div>

          {/* "What We Offer" Section */}
          <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto mt-6">
            <h2 className="text-3xl text-white mb-4">
              {language === 'english' ? 'What We Offer' : language === 'spanish' ? 'Qué Ofrecemos' : 'Co Oferujemy'}
            </h2>
            <div className="flex flex-col md:flex-row justify-between items-center mb-6">
              <div className="flex-1 p-4 text-white">
                <h3 className="text-2xl font-semibold mb-2">
                  {language === 'english' ? 'For Individuals' : language === 'spanish' ? 'Para Individuos' : 'Dla Osób Indywidualnych'}
                </h3>
                <p>
                  {language === 'english'
                    ? 'Birthday parties, private gatherings, and more. We bring the party to you with professional DJs, vibrant decorations, and interactive activities.'
                    : language === 'spanish'
                    ? 'Fiestas de cumpleaños, reuniones privadas y más. Llevamos la fiesta a ti con DJs profesionales, decoraciones vibrantes y actividades interactivas.'
                    : 'Przyjęcia urodzinowe, prywatne spotkania i inne. Przywozimy imprezę do Ciebie z profesjonalnymi DJ-ami, żywymi dekoracjami i interaktywnymi atrakcjami.'}
                </p>
              </div>
              <div className="flex-1 p-4 text-white">
                <h3 className="text-2xl font-semibold mb-2">
                  {language === 'english' ? 'For Business Owners' : language === 'spanish' ? 'Para Propietarios de Negocios' : 'Dla Właścicieli Firm'}
                </h3>
                <p>
                  {language === 'english'
                    ? 'We collaborate with bars, clubs, and event organizers to create spectacular events. We offer marketing services, event planning, and the necessary equipment and personnel.'
                    : language === 'spanish'
                    ? 'Colaboramos con bares, clubes y organizadores de eventos para crear eventos espectaculares. Ofrecemos servicios de marketing, planificación de eventos y el equipo y personal necesarios.'
                    : 'Współpracujemy z barami, klubami i organizatorami wydarzeń, aby tworzyć spektakularne wydarzenia. Oferujemy usługi marketingowe, planowanie wydarzeń oraz niezbędny sprzęt i personel.'}
                </p>
              </div>
            </div>
            <img src="https://i.imgur.com/KS7q7Y8.png" alt="Our Offerings" className="w-full rounded-lg mb-6" />
          </div>

          {/* New Innovations Section */}
          {renderInnovationsSection()}

          {/* WebApp Link / Contact Section */}
          <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto mt-6">
            <h2 className="text-3xl text-white mb-4">
              {language === 'english'
                ? 'Explore Our WebApp'
                : language === 'spanish'
                ? 'Explora Nuestra WebApp'
                : 'Odkryj Naszą WebApp'}
            </h2>
            <Button
              className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white p-3 rounded-lg w-full max-w-xs mx-auto hover:opacity-90 transition"
              onClick={goToWebApp}
              aria-label={getButtonText()}
            >
              {getButtonText()}
            </Button>
          </div>

          {/* Additional Navigation Section */}
          <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto mt-6">
            <h2 className="text-3xl text-white mb-4">
              {language === 'english'
                ? 'Explore More'
                : language === 'spanish'
                ? 'Explora Más'
                : 'Odkryj Więcej'}
            </h2>
            <nav aria-label="Additional Navigation">
              <ul className="flex flex-col md:flex-row justify-center items-center gap-4">
                <li>
                  <Link
                    to="/services"
                    title="Services"
                    className="text-white text-lg hover:underline"
                  >
                    {language === 'english'
                      ? 'For Individuals'
                      : language === 'spanish'
                      ? 'Para Individuos'
                      : 'Dla Osób Indywidualnych'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/forbars"
                    title="Services for Bars"
                    className="text-white text-lg hover:underline"
                  >
                    {language === 'english'
                      ? 'For Bars'
                      : language === 'spanish'
                      ? 'Para Bares'
                      : 'Dla Barów'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/forclubs"
                    title="Services for Clubs"
                    className="text-white text-lg hover:underline"
                  >
                    {language === 'english'
                      ? 'For Clubs'
                      : language === 'spanish'
                      ? 'Para Clubes'
                      : 'Dla Klubów'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/foreventorganizers"
                    title="Services for Organizers"
                    className="text-white text-lg hover:underline"
                  >
                    {language === 'english'
                      ? 'For Organizers'
                      : language === 'spanish'
                      ? 'Para Organizadores'
                      : 'Dla Organizatorów'}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/app/partyticket/events"
                    title="Events"
                    className="text-white text-lg hover:underline"
                  >
                    {language === 'english'
                      ? 'Events'
                      : language === 'spanish'
                      ? 'Eventos'
                      : 'Wydarzenia'}
                  </Link>
                </li>
                <li>
                  <a
                    href="https://wa.me/48451110303"
                    title="Contact via WhatsApp"
                    className="text-white text-lg hover:underline"
                  >
                    {language === 'english'
                      ? 'Contact'
                      : language === 'spanish'
                      ? 'Contacto'
                      : 'Kontakt'}
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          {/* Hashtag / Footer Message */}
          <div className="text-white text-center my-8">
            {language === 'english' &&
              '#PartyPlanner #EventServices #CorporateEvents #BirthdayParties #DJService #EventEquipment #TakiEntertainment'}
            {language === 'spanish' &&
              '#OrganizadorDeFiestas #ServiciosDeEventos #EventosCorporativos #FiestasDeCumpleaños #ServicioDeDJ #EquipoDeEventos #TakiEntertainment'}
            {language === 'polish' &&
              '#OrganizatorImprez #UsługiEventowe #WydarzeniaFirmowe #Urodziny #DJService #SprzętEventowy #TakiEntertainment'}
          </div>
        </main>
      </div>
    </>
  );
};

export default Overview;

import { FaCrown, FaHeart, FaTrophy } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { SectionKey } from '../types';
interface TotalCoinsLeaderboardProps {
  totalCoinsLeaderboard: any[];
  t: any;
}

interface SliderSectionsProps {
  leaderboard: any[];
  battleData: any;
  promotions: any[];
  totalCoinsLeaderboard: any[];
  settings: object;
  handleToggle: (section: SectionKey) => void;
  displaySections: Record<SectionKey, boolean>;
  t: any;
  loading: boolean;
  selectedVenue: string; // Add selectedVenue to the props
}

const SliderSections: React.FC<SliderSectionsProps> = ({
  leaderboard,
  battleData,
  promotions,
  totalCoinsLeaderboard,
  settings,
  handleToggle,
  displaySections,
  t,
  loading,
  selectedVenue,
}) => {
  console.log('displaySections> ', displaySections);
  return (
    <div className="absolute right-0 top-0 h-[calc(100%-5rem)] w-96 bg-black bg-opacity-75 p-4 flex flex-col space-y-4 mt-20 overflow-y-auto pt-10">
      <Slider {...settings}>
        {/* Leaderboard Slide */}
        {displaySections?.karaokeLeaderboard && <LeaderboardSection leaderboard={leaderboard} t={t} />}

        {/* Battle Data Slide */}
        {battleData && displaySections.battleData && <BattleDataSection battleData={battleData} t={t} />}

        {/* Venue QR Code Slide */}
        {displaySections.venueQR && <VenueQRCode selectedVenue={selectedVenue} t={t} />}

        {/* Venue Promotions Slide */}
        {displaySections.promotions && <VenuePromotions promotions={promotions} t={t} />}

        {/* Total Coins Leaderboard Slide */}
        {displaySections.totalCoins && <TopClients totalCoinsLeaderboard={totalCoinsLeaderboard} t={t} />}

        {/* New: DJ Requests QR Section */}
        {displaySections.djRequestsQR && <DJRequestsQRSection t={t} />}
      </Slider>
    </div>
  );
};
const LeaderboardSection: React.FC<{ leaderboard: any[]; t: any }> = ({ leaderboard, t }) => (
  <div className="flex flex-col h-full overflow-y-auto">
    <h3 className="text-lg font-bold mb-3 flex items-center">
      <FaCrown className="mr-2 text-yellow-400" /> {t.leaderboard}
    </h3>
    <div className="flex-1 bg-gray-800 bg-opacity-30 p-4 rounded-md">
      {leaderboard
        ?.sort((a, b) => (b.likes?.length || 0) - (a.likes?.length || 0))
        .map((participant, index) => (
          <LeaderboardItem key={participant._id} participant={participant} index={index} />
        ))}
    </div>
  </div>
);

const LeaderboardItem: React.FC<{ participant: any; index: number }> = ({ participant, index }) => {
  const isTopThree = index < 3;
  return (
    <div
      className={`flex items-center space-x-4 p-4 py-2 rounded-lg shadow-lg transition-all duration-300 mb-2 ${
        isTopThree
          ? index === 0
            ? 'bg-gradient-to-r from-purple-400 via-blue-400 to-teal-300 text-gray-900'
            : index === 1
            ? 'bg-gradient-to-r from-blue-300 via-teal-200 to-gray-300 text-gray-900'
            : 'bg-gradient-to-r from-teal-200 via-gray-300 to-gold-300 text-gray-900'
          : 'bg-gray-800 bg-opacity-30/60 text-gray-300'
      } hover:scale-105`}
    >
      <div
        className={`w-12 h-12 shrink-0 flex items-center justify-center rounded-full text-xl font-bold ${
          isTopThree ? 'bg-white text-gray-900 shadow-lg' : 'bg-gray-700 text-gray-300'
        }`}
      >
        {index + 1}
      </div>
      <div className="text-sm font-medium truncate w-full">{participant.username || 'Unknown'}</div>
      <div className="relative flex items-center justify-center ml-auto h-8 w-8">
        <FaHeart className="text-pink-400 text-xl h-8 w-8" />
        <span className="absolute text-xs text-white">{participant.likes?.length || 0}</span>
      </div>
    </div>
  );
};
const BattleDataSection: React.FC<{ battleData: any; t: any }> = ({ battleData, t }) => (
  <div className="flex flex-col h-full overflow-y-auto">
    <h3 className="text-lg font-bold text-center text-yellow-400 mb-4">{t.battleData}</h3>
    <ul className="space-y-4">
      {battleData.participants.map((participant: any) => (
        <BattleDataItem key={participant._id} participant={participant} battleData={battleData} />
      ))}
    </ul>
  </div>
);

const BattleDataItem: React.FC<{ participant: any; battleData: any }> = ({ participant, battleData }) => (
  <li
    className={`p-6 rounded-lg shadow-md flex justify-between items-center ${
      participant._id === battleData.winner && battleData.status === 'finished'
        ? 'bg-gradient-to-r from-yellow-300 via-yellow-400 to-yellow-500 text-black'
        : 'bg-gray-800'
    }`}
  >
    <div className="relative">
      <img
        src={participant.media?.[0] || 'https://via.placeholder.com/50'}
        alt={participant.name}
        className="w-12 h-12 rounded-full object-cover"
      />
      {participant._id === battleData.winner && battleData.status === 'finished' && (
        <div className="absolute top-0 right-0 -mt-2 -mr-2 bg-yellow-500 p-1 rounded-full">
          <FaTrophy className="text-white text-sm" />
        </div>
      )}
    </div>

    <div className="flex-grow pl-4">
      <p className="text-lg font-bold">{participant.name}</p>
    </div>

    <div
      className={`relative flex items-center justify-center w-12 h-12 ${
        participant._id === battleData.winner && battleData.status === 'finished'
          ? 'bg-gradient-to-r from-yellow-400 to-yellow-500'
          : 'bg-gradient-to-r from-pink-500 to-red-500'
      } hover:from-pink-600 hover:to-red-600 rounded-full shadow-md`}
    >
      <FaHeart className="text-white text-xl" />
      <span className="absolute inset-0 flex items-center justify-center text-xs font-bold text-black">
        {battleData.votes.filter((vote: any) => vote.participant === participant._id).length}
      </span>
    </div>
  </li>
);

const VenueQRCode: React.FC<{ selectedVenue: string; t: any }> = ({ selectedVenue, t }) => (
  <div className="text-center">
    <h3 className="text-lg font-bold text-yellow-400">{t.venueQR}</h3>
    <div className="bg-white p-5 inline-block rounded-lg shadow-md">
      <QRCode value={`venue-${selectedVenue}`} size={128} />
    </div>
  </div>
);
const VenuePromotions: React.FC<{ promotions: any[]; t: any }> = ({ promotions, t }) => (
  <div className="text-center">
    <h3 className="text-2xl font-extrabold mb-6 text-gradient bg-gradient-to-r from-purple-400 via-blue-500 to-teal-300 text-transparent bg-clip-text">
      {t.venuePromotions}
    </h3>
    {promotions.length > 0 ? (
      <div className="flex flex-col">
        {promotions.map((promo) => (
          <PromoItem key={promo._id} promo={promo} />
        ))}
      </div>
    ) : (
      <p className="text-lg text-gray-500">{t.noPromotions}</p>
    )}
  </div>
);

const PromoItem: React.FC<{ promo: any }> = ({ promo }) => (
  <div className="p-6 bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-md transition hover:scale-105 hover:shadow-lg">
    <div className="flex items-center space-x-4">
      {promo.imageURL && (
        <div className="relative">
          <img src={promo.imageURL} alt={promo.name} className="w-20 h-20 object-cover rounded-xl shadow-lg" />
          <div className="absolute inset-0 bg-gradient-to-t from-transparent via-teal-500/10 to-transparent rounded-xl"></div>
        </div>
      )}
      <div>
        <h4 className="text-lg font-semibold text-white truncate">{promo.name}</h4>
        <p className="text-sm text-gray-400 line-clamp-2">{promo.description}</p>
        <p className="mt-2 text-base font-bold text-teal-400 line-through">
          {promo.price} {promo.currency}
        </p>
      </div>
    </div>
    {promo.discounts?.[0] && (
      <div className="mt-4 bg-teal-800 bg-opacity-10 p-3 rounded-lg flex items-center justify-between">
        <span className="text-sm text-teal-300 font-semibold">
          {promo.discounts[0].priceWithDiscount} {promo.currency}
        </span>
        <span className="text-xs text-gray-400">-{promo.discounts[0].coinsRequired} coins</span>
      </div>
    )}
  </div>
);

const DJRequestsQRSection: React.FC<{ t: any }> = ({ t }) => (
  <div className="text-center">
    <h3 className="text-lg font-bold text-yellow-400">
      {t.djRequestsQR || 'DJ Requests'}
    </h3>
    <div className="bg-white p-5 inline-block rounded-lg shadow-md">
      <QRCode value="https://takientertainment.com/app/partyticket/dj-requests" size={128} />
    </div>
    <p className="mt-4 text-sm text-gray-300">
      {t.scanQR || 'Scan the QR code with your phone to request songs to DJs.'}
    </p>
  </div>
);

const TopClients: React.FC<TotalCoinsLeaderboardProps> = ({ totalCoinsLeaderboard, t }) => {
  // Filter out users with role 'admin' and only verified users
  const filteredLeaderboard = totalCoinsLeaderboard.filter((user) => user.role !== 'admin' && user.isVerified === true);

  return (
    <div className="flex flex-col h-full overflow-y-auto">
      <h3 className="text-lg font-bold mb-3 flex items-center">
        <FaHeart className="mr-2 text-yellow-400" /> {t.topClients || 'Top Clients'}
      </h3>
      <div className="flex-1 bg-gray-800 bg-opacity-30 p-4 rounded-md">
        {filteredLeaderboard.length > 0 ? (
          filteredLeaderboard.map((user: any, index: number) => (
            <div
              key={user._id}
              className={`flex items-center space-x-4 p-4 py-2 rounded-lg shadow-lg transition-all duration-300 mb-2 ${
                index === 0
                  ? 'bg-gradient-to-r from-purple-400 via-blue-400 to-teal-300 text-gray-900'
                  : index === 1
                  ? 'bg-gradient-to-r from-blue-300 via-teal-200 to-gray-300 text-gray-900'
                  : index === 2
                  ? 'bg-gradient-to-r from-teal-200 via-gray-300 to-yellow-300 text-gray-900'
                  : 'bg-gray-800 bg-opacity-60 text-gray-300'
              } hover:scale-105`}
              style={index < 3 ? { boxShadow: '0 0 15px rgba(255, 255, 255, 0.2)' } : {}}
            >
              <div className="relative w-12 h-12 shrink-0 rounded-full overflow-hidden">
                {user.media && user.media.length > 0 ? (
                  <img src={user.media[0]} alt={user.username} className="w-full h-full object-cover" />
                ) : (
                  <div className="w-full h-full bg-gray-700" />
                )}
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <span className="text-xl font-bold text-white">{index + 1}</span>
                </div>
              </div>
              <div className="text-sm font-medium truncate w-full">{user.username || 'Unknown'}</div>
              <div className="relative flex items-center justify-center ml-auto h-12 w-12">
                <FaHeart className="text-pink-500 text-xl h-12 w-12" />
                <span className="absolute text-xs text-white">{user.totalCoins || 0}</span>
              </div>
            </div>
          ))
        ) : (
          <p>{t.noParticipants}</p>
        )}
      </div>
    </div>
  );
};

export default SliderSections;

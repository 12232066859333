import {
  faCoins,
  faGlassCheers,
  faHeart,
  faLaptopCode,
  faMap,
  faMicrophone,
  faPercentage,
  faSignInAlt,
  faTicketAlt,
  faVoteYea
} from '@fortawesome/free-solid-svg-icons'; // Added new icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LowerBar from '../../PartyTicket/LowerBar';

const HomeScreen: React.FC = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();

  const translations = {
    english: {
      title: 'Pick your vibe! 🎉',
      party: 'Tickets',
      karaoke: 'Karaoke',
      djBattle: 'DJ Battle',
      checkIn: 'Check-In',
      earnCoins: 'Earn Coins',
      discounts: 'Deals',
      myTickets: 'My Tickets', // Added
      djRequest: 'DJ Request',
      composeDrink: 'Custom Drink (Soon)',
      match: 'Match (Soon)',
      dedication: 'Dedications',
      vote: 'Vote (Soon)',
      map: 'Map',
    },
    spanish: {
      title: '¡Elige tu onda! 🎉',
      party: 'Tickets',
      karaoke: 'Karaoke',
      djBattle: 'Batalla DJ',
      checkIn: 'Check-In',
      earnCoins: 'Gana Monedas',
      discounts: 'Ofertas',
      myTickets: 'Mis Entradas', // Added
      djRequest: 'Pedido DJ',
      composeDrink: 'Bebida (Pronto)',
      match: 'Match (Pronto)',
      dedication: 'Dedicatorias',
      vote: 'Głosowanie (Pronto)',
      map: 'Mapa',
    },
    polish: {
      title: 'Wybierz klimat! 🎉',
      party: 'Bilety',
      karaoke: 'Karaoke',
      djBattle: 'Bitwa DJ',
      checkIn: 'Check-In',
      earnCoins: 'Zarabiaj Monety',
      discounts: 'Rabaty',
      myTickets: 'Moje Bilety', // Added
      djRequest: 'DJ',
      composeDrink: 'Drink (Wkrótce)',
      match: 'Poznaj (Wkrótce)',
      dedication: 'Dedykacje',
      vote: 'Głosowanie (Wkrótce)',
      map: 'Mapa',
    },
  };

  const t = translations[language as keyof typeof translations];
  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black text-white flex flex-col items-center justify-center relative pt-24 md:pt-0 pb-24">
      {/* Spicy Message */}
      <h1 className="text-3xl sm:text-4xl text-center font-extrabold text-gradient mb-8 px-4">{t.title}</h1>

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 w-full max-w-4xl mx-auto px-4">
        {/* Karaoke Button */}
        <button
          onClick={() => navigate('/app/partyticket/rooms/66cc50ad5d4ada8c64b24616')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 transition-all p-5 sm:p-7 rounded-lg shadow-xl hover:scale-105 transform"
        >
          <FontAwesomeIcon icon={faMicrophone} className="text-3xl sm:text-4xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-lg sm:text-xl font-semibold">{t.karaoke}</span>
        </button>

        {/* Party Button */}
        <button
          onClick={() => navigate('/app/partyticket/events')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-teal-500 to-blue-600 hover:from-teal-600 hover:to-blue-700 transition-all p-5 sm:p-7 rounded-lg shadow-xl hover:scale-105 transform"
        >
          <FontAwesomeIcon icon={faTicketAlt} className="text-3xl sm:text-4xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-lg sm:text-xl font-semibold">{t.party}</span>
        </button>

        {/* Activate DJ Request Button */}
        <button
          onClick={() => navigate('/app/partyticket/dj-requests')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-indigo-500 to-blue-600 hover:from-indigo-600 hover:to-blue-700 transition-all p-5 sm:p-7 rounded-lg shadow-xl hover:scale-105 transform"
        >
          <FontAwesomeIcon icon={faLaptopCode} className="text-3xl sm:text-4xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-lg sm:text-xl font-semibold">{t.djRequest}</span>
        </button>

        {/* Dedication Button */}
        <button
          onClick={() => navigate('/app/partyticket/dedications')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-pink-600 to-red-600 hover:from-pink-700 hover:to-red-700 transition-all p-5 sm:p-7 rounded-lg shadow-xl hover:scale-105 transform"
        >
          <FontAwesomeIcon icon={faHeart} className="text-3xl sm:text-4xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-lg sm:text-xl font-semibold">{t.dedication}</span>
        </button>

        {/* Earn Coins Button */}
        <button
          onClick={() => navigate('/app/partyticket/earn-coins/66cc50ad5d4ada8c64b24616')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-yellow-500 to-orange-600 hover:from-yellow-600 hover:to-orange-700 transition-all p-5 sm:p-7 rounded-lg shadow-xl hover:scale-105 transform"
        >
          <FontAwesomeIcon icon={faCoins} className="text-3xl sm:text-4xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-lg sm:text-xl font-semibold">{t.earnCoins}</span>
        </button>

        {/* Discounts Button */}
        <button
          onClick={() => navigate('/app/partyticket/discounts/66cc50ad5d4ada8c64b24616')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-green-500 to-teal-600 hover:from-green-600 hover:to-teal-700 transition-all p-5 sm:p-7 rounded-lg shadow-xl hover:scale-105 transform"
        >
          <FontAwesomeIcon icon={faPercentage} className="text-3xl sm:text-4xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-lg sm:text-xl font-semibold">{t.discounts}</span>
        </button>
        {/* Check-In Button */}
        <button
          onClick={() => navigate('/app/check-in')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-gray-600 to-gray-800 hover:from-gray-700 hover:to-gray-900 transition-all p-5 sm:p-7 rounded-lg shadow-xl hover:scale-105 transform"
        >
          <FontAwesomeIcon icon={faSignInAlt} className="text-3xl sm:text-4xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-lg sm:text-xl font-semibold">{t.checkIn}</span>
        </button>

        {/* DJ Battle Button */}
        <button
          onClick={() => navigate('/app/partyticket/dj-battles/66cc50ad5d4ada8c64b24616')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-pink-500 to-purple-600 hover:from-pink-600 hover:to-purple-700 transition-all p-5 sm:p-7 rounded-lg shadow-xl hover:scale-105 transform"
        >
          <FontAwesomeIcon icon={faVoteYea} className="text-3xl sm:text-4xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-lg sm:text-xl font-semibold">{t.djBattle}</span>
        </button>

        {/* Coming Soon Buttons */}
        <div className="flex flex-col items-center justify-center bg-gray-700 opacity-60 p-5 sm:p-7 rounded-lg shadow-xl cursor-not-allowed">
          <FontAwesomeIcon icon={faGlassCheers} className="text-3xl sm:text-4xl text-gray-500 mb-2 sm:mb-3" />
          <span className="text-gray-500 text-lg sm:text-xl font-semibold">{t.composeDrink}</span>
        </div>
        <div className="flex flex-col items-center justify-center bg-gray-700 opacity-60 p-5 sm:p-7 rounded-lg shadow-xl cursor-not-allowed">
          <FontAwesomeIcon icon={faHeart} className="text-3xl sm:text-4xl text-gray-500 mb-2 sm:mb-3" />
          <span className="text-gray-500 text-lg sm:text-xl font-semibold">{t.match}</span>
        </div>
        <div className="flex flex-col items-center justify-center bg-gray-700 opacity-60 p-5 sm:p-7 rounded-lg shadow-xl cursor-not-allowed">
          <FontAwesomeIcon icon={faMap} className="text-3xl sm:text-4xl text-gray-500 mb-2 sm:mb-3" />
          <span className="text-gray-500 text-lg sm:text-xl font-semibold">{t.map}</span>
        </div>
      </div>

      <LowerBar />
    </div>
  );
};

export default HomeScreen;

import { Navigate, Route, Routes } from 'react-router-dom';
import BarDetail from './BarDetail';
import Bars from './Bars';
import BarsMap from './BarsMap';
import BuyCoins from './BuyCoins'; // Import the BarPromotions component
import BuyTicket from './BuyTicket'; // Import the RoomsList component
import ClubDetail from './ClubDetail';
import Clubs from './Clubs';
import ClubsMap from './ClubsMap';
import DJBattle from './DJBattle'; // Import the BarPromotions component
import DJBattles from './DJBattles'; // Import the BarPromotions component
import Dedications from './Dedications'; // Import the BarPromotions component
import EarnCoins from './EarnCoins'; // Import the BarPromotions component
import EventDetail from './EventDetail';
import Events from './FilteredEvents';
import FreeDrinks from './FreeDrinks'; // Import the BarPromotions component
import LowerBar from './LowerBar';
import Map from './Map';
import MenuItemDetail from './MenuItemDetail'; // Import the new component
import MyTickets from './MyTickets'; // Import the RoomsList component
import PlaylistDetail from './PlaylistDetail'; // Import PlaylistDetail component
import RoomDetail from './RoomDetail'; // Import RoomDetail component
import RoomsList from './RoomsList'; // Import the RoomsList component
import SongRequests from './SongRequests'; // Import the RoomsList component

const PartyTicket = () => {
  return (
    <>
      <Routes>
        <Route path="events" element={<Events />} />
        <Route path="event/:eventId" element={<EventDetail />} />
        <Route path="my-tickets" element={<MyTickets />} />
        <Route path="buyticket/:eventId" element={<BuyTicket />} />
        <Route path="dedications" element={<Dedications />} />
        <Route path="dj-battles/:venueId" element={<DJBattles />} /> {/* Add DJ Battles route */}
        <Route path="dj-battle/:battleId" element={<DJBattle />} /> {/* Add DJ Battles route */}
        <Route path="map" element={<Map />} />
        <Route path="bars/map" element={<BarsMap />} />
        <Route path="bars/view-bar/:barId" element={<BarDetail />} />
        <Route path="clubs/map" element={<ClubsMap />} />
        <Route path="clubs/view-club/:clubId" element={<ClubDetail />} />
        <Route path="bars" element={<Bars />} />
        <Route path="buycoins" element={<BuyCoins />} /> {/* Add the BuyCoins route */}
        <Route path="dj-requests" element={<SongRequests />} /> {/* Add the BuyCoins route */}
        <Route path="clubs" element={<Clubs />} />
        <Route path="menu-item/:menuItemId" element={<MenuItemDetail />} /> {/* Add this line */}
        <Route path="room/:roomId" element={<RoomDetail />} /> {/* Add RoomDetail route */}
        <Route path="earn-coins/:barId" element={<EarnCoins />} />
        <Route path="discounts/:barId" element={<FreeDrinks />} />
        <Route path="playlist/:playlistId" element={<PlaylistDetail />} /> {/* Add PlaylistDetail route */}
        <Route path="rooms/:venueId" element={<RoomsList />} /> {/* Update to include venueId */}
        <Route path="*" element={<Navigate to="events" />} />
      </Routes>
      <LowerBar />
    </>
  );
};

export default PartyTicket;

// src/components/PlaylistDetail/index.tsx
import React, { useRef, useState } from 'react';
import { FaArrowLeft, FaMusic, FaPlus, FaQrcode, FaSpinner, FaTrophy } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import translations from './translations';
import { extractVideoId } from './utils/youtubeHelpers';

import AddVideoForm from './components/AddVideoForm';
import AddVideoModal from './components/AddVideoModal';
import NowPlaying from './components/NowPlaying';
import ParticipantsModal from './components/ParticipantsModal';
import QRModal from './components/QRModal';
import UpNext from './components/UpNext';
import VideoList from './components/VideoList';

import { useAddVideo } from './hooks/useAddVideo';
import { useFreezeVotes } from './hooks/useFreezeVotes';
import { usePlaylistDetails } from './hooks/usePlaylistDetails';
import { useSendLike } from './hooks/usePlaylistInteraction';
import { useUpdateCurrentVideoIndex } from './hooks/useUpdateCurrentVideoIndex';
import { useUpdateVideoMetadata } from './hooks/useUpdateVideoMetadata';
import { useUserProfile } from './hooks/useUserProfile';

const PlaylistDetail: React.FC = () => {
  const { playlistId } = useParams<{ playlistId: string }>();
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const t = translations[language as keyof typeof translations];
  const navigate = useNavigate();
  const lastDisplayedSongId = useRef<string | null>(null);
  const currentUrl = window.location.href;

  // Use our custom hooks (fallback to empty string if token is falsy)
  const { data: playlist, isLoading } = usePlaylistDetails(playlistId, currentToken || '');
  const { data: userData } = useUserProfile(currentToken || '');
  const addVideoMutation = useAddVideo(playlistId as string, currentToken || '');
  const sendLikeMutation = useSendLike(playlistId as string, currentToken || '');
  const freezeVotesMutation = useFreezeVotes(playlistId as string, currentToken || '');
  const updateCurrentVideoIndexMutation = useUpdateCurrentVideoIndex(playlistId as string, currentToken || '');
  const updateVideoMetadataMutation = useUpdateVideoMetadata(playlistId as string, currentToken || '');

  const [newVideo, setNewVideo] = useState({ videoId: '' });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [qrModalOpen, setQrModalOpen] = useState<boolean>(false);
  const [modalOpenParticipants, setModalOpenParticipants] = useState<boolean>(false);
  // For editing, store the ID of the video being edited and its new URL
  const [editingVideoId, setEditingVideoId] = useState<string | null>(null);
  const [editedVideoUrl, setEditedVideoUrl] = useState('');

  const isAdmin = userData?.role === 'admin';

  // Notification for song change
  if (playlist) {
    const currentVideo = playlist.videos[playlist.currentVideoIndex];
    if (currentVideo && currentVideo._id !== lastDisplayedSongId.current) {
      lastDisplayedSongId.current = currentVideo._id;
      Swal.fire({
        html: `
          <div class="flex flex-col items-center justify-center text-white">
            <h2 class="text-4xl font-extrabold text-center animate-bounce">${t.currentVideo}</h2>
            <h3 class="text-2xl font-semibold mt-4">${currentVideo.title || t.unknown}</h3>
            <p class="text-lg mt-2">${t.requestedBy}: <span class="font-medium">${
          currentVideo?.addedBy?.username || t.unknown
        }</span></p>
          </div>
        `,
        background: 'rgb(31 41 55)',
        color: '#FFFFFF',
        timer: 10000,
        showConfirmButton: false,
      });
    }
  }

  const handlePasteClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/)|youtu\.be\/)[\w\-]+/;
      if (youtubeRegex.test(text)) {
        setNewVideo({ videoId: text });
        // Swal.fire({
        //   title: 'YouTube Link Pasted!',
        //   text: 'A YouTube link has been pasted into the field.',
        //   icon: 'success',
        //   background: '#000000',
        //   color: '#FFFFFF',
        //   timer: 2000,
        //   showConfirmButton: false,
        // });
      } else {
        Swal.fire({
          title: 'No Valid Link',
          text: 'Clipboard does not contain a valid YouTube link.',
          icon: 'error',
          background: '#000000',
          color: '#FFFFFF',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error('Clipboard access failed:', error);
      Swal.fire({
        title: 'Clipboard Access Denied',
        text: 'Please allow clipboard access in your browser settings.',
        icon: 'error',
        background: '#000000',
        color: '#FFFFFF',
        timer: 3000,
      });
    }
  };

  const unplayedVideos = playlist?.videos.filter(
    (_: any, index: number) => index >= (playlist.currentVideoIndex ?? 0) + 1,
  );

  const userSongCount = unplayedVideos
    ? unplayedVideos.filter((video: any) => video.addedBy._id.toString() === userData?._id.toString()).length
    : 0;

  // Handler for adding a new video.

  const handleAddVideo = async () => {
    const { videoId: videoUrl } = newVideo;
    if (!videoUrl) {
      Swal.fire({ title: t.error, text: t.fieldsRequired, icon: 'error' });
      return;
    }
    const videoId = extractVideoId(videoUrl);
    if (!videoId) {
      Swal.fire({ title: t.error, text: t.invalidUrl, icon: 'error' });
      return;
    }

    const additionalCost = userSongCount > 0 ? 100 * Math.pow(2, userSongCount - 1) : 2;

    addVideoMutation.mutate(
      { videoId },
      {
        onSuccess: () => {
          Swal.fire({ title: t.success, text: t.videoAdded, icon: 'success' });
          if (!isAdmin) {
            if (userSongCount > 0) {
              Swal.fire({
                title: t.success,
                text: `${additionalCost} Taki Coins deducted for adding an additional video.`,
                icon: 'info',
                timer: 2000,
                showConfirmButton: false,
              });
            } else {
              Swal.fire({
                title: t.success,
                text: t.coinsEarned, // Ensure t.coinsEarned is defined (e.g., "2 Taki Coins awarded.")
                icon: 'info',
                timer: 2000,
                showConfirmButton: false,
              });
            }
          }
          setNewVideo({ videoId: '' });
          setModalOpen(false);
        },
        onError: (error: any) => {
          const errorType = error?.type;
          const errorMessage = error?.message;
          if (errorType === 'NOT_ENOUGH_COINS') {
            Swal.fire({
              title: t.error,
              html: `
                <div>${t.notEnoughCoinsError}</div>
                <button id="buyCoinsBtn" class="mt-4 w-full py-2 bg-gradient-to-r from-green-500 to-blue-500 text-white rounded-lg shadow-md hover:from-green-600 hover:to-blue-600 transition-all duration-300">
                  ${t.buyCoins}
                </button>
              `,
              icon: 'error',
              showConfirmButton: false,
              didOpen: () => {
                const btn = document.getElementById('buyCoinsBtn');
                if (btn) {
                  btn.addEventListener('click', () => {
                    window.location.href = '/app/partyticket/buycoins';
                  });
                }
              },
            });
          } else {
            Swal.fire({
              title: t.error,
              text: errorMessage || t.addVideoError,
              icon: 'error',
            });
          }
        },
      },
    );
  };

  // Handler for sending a like.
  const sendLikeInteraction = (recipientId: string) => {
    sendLikeMutation.mutate(recipientId, {
      onSuccess: () => {
        Swal.fire({
          title: t.success,
          text: 'Like sent successfully!',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
      },
      onError: (error: any) => {
        Swal.fire({ title: t.error, text: error.message || 'Failed to send like.', icon: 'error' });
      },
    });
  };

  // Handler for freezing votes.
  const freezeVotes = () => {
    freezeVotesMutation.mutate(undefined, {
      onSuccess: (data: any) => {
        Swal.fire({
          title: 'Votes Frozen!',
          text: `Winner: ${data.winner.user.username} with ${data.winner.likes} likes.`,
          icon: 'success',
          background: '#000000',
          color: '#FFFFFF',
        });
      },
      onError: (error: any) => {
        Swal.fire({
          title: 'Error',
          text: error.message || 'Failed to freeze votes.',
          icon: 'error',
          background: '#000000',
          color: '#FFFFFF',
        });
      },
    });
  };

  // Admin-only: jump to another video by updating the current video index.
  const jumpToVideo = (index: number) => {
    if (!isAdmin || !playlistId || !currentToken) return;
    updateCurrentVideoIndexMutation.mutate(index, {
      onError: (error: any) => {
        console.error('Error updating video index:', error);
      },
    });
  };

  const skipCurrentVideo = () => {
    if (playlist) {
      updateCurrentVideoIndexMutation.mutate(playlist.currentVideoIndex + 1, {
        onSuccess: () => {
          if (!isAdmin) {
            Swal.fire({
              title: t.success,
              text: t.skipCost || 'Song skipped. 2 Taki Coins deducted.',
              icon: 'info',
              timer: 2000,
              showConfirmButton: false,
            });
          }
        },
        onError: (error: any) => {
          console.error('Error updating video index:', error);
        },
      });
    }
  };

  // Handler for updating (editing) a video’s metadata.
  const handleUpdateVideoMetadata = (videoId: string) => {
    if (!editedVideoUrl) {
      Swal.fire({ title: t.error, text: t.fieldsRequired, icon: 'error' });
      return;
    }
    updateVideoMetadataMutation.mutate(
      { videoId, videoUrl: editedVideoUrl },
      {
        onSuccess: () => {
          Swal.fire({ title: t.success, text: 'Video URL updated successfully.', icon: 'success' });
          setEditingVideoId(null);
          setEditedVideoUrl('');
        },
        onError: (error: any) => {
          Swal.fire({ title: t.error, text: error.message || 'Failed to update video URL.', icon: 'error' });
        },
      },
    );
  };

  if (isLoading) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <FaSpinner className="animate-spin text-4xl text-gray-400" />
        <p>{t.loading}</p>
      </div>
    );
  }

  if (!playlist) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>{t.playlistNotFound}</p>
      </div>
    );
  }

  // Define current, next, and remaining videos.
  const currentVideoObj = playlist.videos[playlist.currentVideoIndex] || null;
  const nextVideo = playlist.videos[playlist.currentVideoIndex + 1] || null;
  const remainingVideos = playlist.videos.slice(playlist.currentVideoIndex + 2) || [];

  // Prepare participants data.
  const updatedParticipants = playlist.participants.map((participant: any) => {
    const likesForParticipant = playlist.userInteractions.filter(
      (interaction: any) => interaction.type === 'like' && interaction.recipient === participant._id,
    );
    return {
      ...participant,
      hasLiked: playlist.userInteractions.some(
        (interaction: any) =>
          interaction.type === 'like' &&
          interaction.initiator === userData?._id &&
          interaction.recipient === participant._id,
      ),
      likes: likesForParticipant || [],
      canVote: !playlist.winner,
    };
  });

  // Determine if the current video can be skipped (if added by the current user or if admin)
  const canSkipCurrent = currentVideoObj && (isAdmin || currentVideoObj?.addedBy?._id === userData?._id) && nextVideo;

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 flex flex-col lg:flex-row lg:space-x-4 py-20">
      {/* Left Section: Playlist and Controls */}
      <div className="lg:w-1/2 flex flex-col space-y-6">
      <div className="w-full h-20 flex items-center justify-between px-4">
  {/* Return button on the left */}
  <button
    onClick={() => navigate(-1)}
    className="flex items-center bg-gray-700 text-white p-3 rounded-full hover:bg-gray-600 transition-all shadow-lg"
  >
    <FaArrowLeft  className="text-2xl" />
  </button>

  {/* Right buttons container */}
  <div className="flex items-center space-x-4">
    {playlist?.type === 'contest' && (
      <button
        onClick={() => setModalOpenParticipants(true)}
        className="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-4 py-2 rounded-md hover:from-purple-700 hover:to-pink-700 transition-all shadow-lg flex flex-row items-center justify-center h-12"
        title="View Leaderboard"
      >
        <FaTrophy className="mr-2 text-xl" />
        Vote!
      </button>
    )}
    <button
      onClick={() => setQrModalOpen(true)}
      className="bg-blue-600 text-white p-3 rounded-full hover:bg-blue-500 transition-all shadow-lg"
    >
      <FaQrcode className="text-2xl" />
    </button>
    <button
      onClick={() => setModalOpen(true)}
      className="bg-green-600 text-white p-3 rounded-full flex items-center justify-center hover:bg-green-500 transition-all shadow-lg"
    >
      <div className="relative">
        <FaMusic  className="text-2xl" />
        <FaPlus 
          className="absolute text-xs text-white top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full"
        />
      </div>
    </button>
  </div>
</div>
        <h2 className="text-3xl font-bold tracking-tight">{playlist.name}</h2>
        <div className="bg-gray-800 p-6 rounded-xl shadow-xl flex flex-col items-center space-y-4 hidden lg:flex">
          <h3 className="text-2xl font-semibold">{t.qrTitle}</h3>
          <QRCode value={currentUrl} size={200} />
        </div>
        <AddVideoForm
          newVideo={newVideo}
          setNewVideo={setNewVideo}
          handleAddVideo={handleAddVideo}
          addVideoLoading={addVideoMutation.isLoading}
          handlePasteClipboard={handlePasteClipboard}
          t={t}
        />
      </div>

      {/* Right Section: Playlist Details */}
      <div className="lg:w-1/2 flex flex-col space-y-6 lg:pt-10">
        <NowPlaying currentVideo={currentVideoObj} t={t} canSkip={canSkipCurrent} onSkip={skipCurrentVideo} />
        <UpNext
          nextVideo={nextVideo}
          t={t}
          jumpToVideo={isAdmin ? () => jumpToVideo(playlist.currentVideoIndex + 1) : undefined}
          enableEdit={isAdmin || playlist.videos[playlist.currentVideoIndex + 1]?.addedBy?._id === userData?._id}
          onEditVideo={(video) => {
            // Allow editing if admin or if the video was added by the current user.
            if (!isAdmin && video?.addedBy?._id !== userData?._id) {
              Swal.fire({
                title: t.error,
                text: 'You cannot edit this video. You can only edit your own videos.',
                icon: 'error',
              });
              return;
            }
            // Set up the edit form.
            setEditingVideoId(video._id);
            setEditedVideoUrl(`https://youtu.be/${video.videoId}`);
          }}
        />
        <VideoList
          remainingVideos={remainingVideos}
          t={t}
          startIndex={3}
          jumpToVideo={isAdmin ? jumpToVideo : undefined}
          onEditVideo={(video) => {
            // Allow editing if admin or if the video was added by the current user and is not currently playing.
            const isCurrent = playlist.currentVideoIndex === playlist.videos.findIndex((v: any) => v._id === video._id);
            if (!isAdmin && (video?.addedBy?._id !== userData?._id || isCurrent)) {
              Swal.fire({
                title: t.error,
                text: 'You cannot edit this video. You can only edit your own videos that are not currently playing.',
                icon: 'error',
              });
              return;
            }
            // Set up the edit form.
            setEditingVideoId(video._id);
            setEditedVideoUrl(`https://youtu.be/${video.videoId}`);
          }}
          userId={userData?._id}
          isAdmin={isAdmin}
          currentVideoId={currentVideoObj?._id}
        />

        {modalOpenParticipants && (
          <ParticipantsModal
            updatedParticipants={updatedParticipants}
            isAdmin={isAdmin}
            freezeVotes={freezeVotes}
            sendLikeInteraction={sendLikeInteraction}
            t={t}
            closeModal={() => setModalOpenParticipants(false)}
          />
        )}
      </div>

      {/* Mobile Add Video Modal */}
      {modalOpen && (
        <AddVideoModal
          newVideo={newVideo}
          setNewVideo={setNewVideo}
          handleAddVideo={handleAddVideo}
          addVideoLoading={addVideoMutation.isLoading}
          handlePasteClipboard={handlePasteClipboard}
          closeModal={() => setModalOpen(false)}
          songCount={userSongCount} // Pass the count here
          isAdmin={isAdmin}
        />
      )}

      {/* QR Modal for mobile */}
      {qrModalOpen && <QRModal currentUrl={currentUrl} t={t} closeModal={() => setQrModalOpen(false)} />}

      {/* Inline editing form for video URL */}
      {editingVideoId && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-900 p-6 rounded-xl shadow-2xl w-full max-w-md">
            <h3 className="text-2xl font-bold text-white mb-4 text-center">Edit Video URL</h3>
            <input
              type="text"
              value={editedVideoUrl}
              onChange={(e) => setEditedVideoUrl(e.target.value)}
              className="w-full p-3 rounded-lg bg-gray-800 text-white placeholder-gray-400 mb-6 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter new URL"
            />
            <div className="flex justify-between">
              <button
                onClick={() => {
                  setEditingVideoId(null);
                  setEditedVideoUrl('');
                }}
                className="w-1/2 mr-2 px-6 py-2 rounded-full bg-gradient-to-r from-red-500 to-red-700 text-white font-semibold shadow-md hover:from-red-600 hover:to-red-800 transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (editingVideoId) handleUpdateVideoMetadata(editingVideoId);
                }}
                className="w-1/2 ml-2 px-6 py-2 rounded-full bg-gradient-to-r from-green-400 to-blue-500 text-white font-semibold shadow-md hover:from-green-500 hover:to-blue-600 transition-all duration-300"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaylistDetail;

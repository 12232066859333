import React, { useState } from 'react';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import { FaChevronDown, FaChevronUp, FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import sun3RemovebgPrev from '../../assets/sun3RemovebgPrev.png';

interface SideMenuProps {
  open: boolean;
  toggle: () => void;
}

interface CollapsibleGroupProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
}

const CollapsibleGroup: React.FC<CollapsibleGroupProps> = ({ title, children, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);
  return (
    <section className="mt-3">
      <button
        onClick={() => setOpen(!open)}
        className="w-full flex items-center justify-start text-left text-sm font-bold text-white bg-transparent py-2 rounded-t transition-all hover:bg-gray-700"
      >
        <span className="mr-2">{title}</span>
        <span className="ml-auto mb-1">{open ? <FaChevronUp size={16} /> : <FaChevronDown size={16} />}</span>
      </button>
      {open && <div className="bg-transparent pl-4 pr-2 py-2">{children}</div>}
    </section>
  );
};

const SideMenu: React.FC<SideMenuProps> = ({ open, toggle }) => {
  const navigate = useNavigate();
  const { currentToken, expiration, clearAuthentication } = useAuth();
  const { language } = useLanguage();
  const isAuthenticated = currentToken && expiration && parseInt(expiration) - Date.now() > 1000;

  const handleLogout = () => {
    Swal.fire({
      title: 'Thank you',
      text: 'Hope to see you soon 🤗',
      showConfirmButton: false,
      timer: 3000,
      background: '#000000',
      color: '#FFFFFF',
    });
    toggle();
    clearAuthentication();
    navigate('/auth/login');
  };

  const menuItems = {
    english: {
      home: 'HOME',
      services: 'SERVICES',
      forIndividuals: 'FOR INDIVIDUALS',
      forBars: 'FOR BARS',
      forClubs: 'FOR CLUBS',
      forEventOrganizers: 'FOR EVENT ORGANIZERS',
      events: 'EVENTS',
      profile: 'PROFILE',
      myEvents: 'MY EVENTS',
      gallery: 'GALLERY',
      logout: 'LOGOUT',
      socialMedia: 'SOCIAL MEDIA',
      takitakikatowice: 'TAKI TAKI KATOWICE',
      login: 'LOGIN',
      register: 'REGISTER',
      robotHost: 'Robot Host',
      foreigners: 'FOREIGNERS',
      takiTicketing: 'Ticketing',
      takiDJRequest: 'DJ Request',
      takiRental: 'Rental',
      takiMarketing: 'Marketing',
      intelligencePlatform: 'INTELLIGENCE PLATFORM',
      venuesGroup: 'VENUES',
      musicCollectives: 'MUSIC COLLECTIVES',
    },
    polish: {
      home: 'STRONA GŁÓWNA',
      services: 'USŁUGI',
      forIndividuals: 'DLA OSÓB PRYWATNYCH',
      forBars: 'DLA BARÓW',
      forClubs: 'DLA KLUBÓW',
      forEventOrganizers: 'DLA ORGANIZATORÓW WYDARZEŃ',
      events: 'WYDARZENIA',
      profile: 'PROFIL',
      myEvents: 'MOJE WYDARZENIA',
      gallery: 'GALERIA',
      logout: 'WYLOGUJ SIĘ',
      socialMedia: 'MEDIA SPOŁECZNOŚCIOWE',
      takitakikatowice: 'TAKI TAKI KATOWICE',
      login: 'ZALOGUJ SIĘ',
      register: 'ZAREJESTRUJ SIĘ',
      robotHost: 'Robot Host',
      foreigners: 'FOREIGNERS',
      takiTicketing: 'Ticketing',
      takiDJRequest: 'DJ Request',
      takiRental: 'Rental',
      takiMarketing: 'Marketing',
      intelligencePlatform: 'INTELLIGENCE PLATFORM',
      venuesGroup: 'MIEJSCA',
      musicCollectives: 'KOLEKTYWY MUZYCZNE',
    },
    spanish: {
      home: 'INICIO',
      services: 'SERVICIOS',
      forIndividuals: 'PARA INDIVIDUOS',
      forBars: 'PARA BARES',
      forClubs: 'PARA CLUBES',
      forEventOrganizers: 'PARA ORGANIZADORES DE EVENTOS',
      events: 'EVENTOS',
      profile: 'PERFIL',
      myEvents: 'MIS EVENTOS',
      gallery: 'GALERÍA',
      logout: 'CERRAR SESIÓN',
      socialMedia: 'REDES SOCIALES',
      takitakikatowice: 'TAKI TAKI KATOWICE',
      login: 'INICIAR SESIÓN',
      register: 'REGISTRARSE',
      robotHost: 'Robot Host',
      foreigners: 'FOREIGNERS',
      takiTicketing: 'Ticketing',
      takiDJRequest: 'Solicitud al DJ',
      takiRental: 'Rental',
      takiMarketing: 'Marketing',
      intelligencePlatform: 'INTELLIGENCE PLATFORM',
      venuesGroup: 'LOCALES',
      musicCollectives: 'COLECTIVOS MUSICALES',
    },
  };

  const currentMenu = menuItems[language as keyof typeof menuItems];

  return (
    <>
      {/* Overlay for mobile when sidebar is open */}
      <div className={`fixed top-0 left-0 w-full h-full z-30 ${open ? 'block' : 'hidden'}`} onClick={toggle} />
      <aside
        className={`fixed top-0 left-0 w-60 h-full z-40 bg-black bg-opacity-70 backdrop-blur-lg transition-transform transform ${
          open ? 'translate-x-0' : '-translate-x-full'
        } flex items-start`}
      >
        <nav aria-label="Main Navigation" className="flex flex-col items-start p-6 mt-16 h-full overflow-y-auto pb-36">
          <img alt="Logo Taki" className="h-20" src={sun3RemovebgPrev} />
          <ul className="space-y-4">
            <li>
              <Link to="/home" title="Home" className="text-base text-white" onClick={toggle}>
                {currentMenu.home}
              </Link>
            </li>
            {!isAuthenticated && (
              <>
                <li>
                  <Link to="/auth/login" title="Login" className="text-base text-white" onClick={toggle}>
                    {currentMenu.login}
                  </Link>
                </li>
                <li>
                  <Link to="/auth/register" title="Register" className="text-base text-white" onClick={toggle}>
                    {currentMenu.register}
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link to="/app/partyticket" title="Events" className="text-base text-white" onClick={toggle}>
                {currentMenu.events}
              </Link>
            </li>
            {isAuthenticated && (
              <li>
                <Link to="/app/profile" title="Profile" className="text-base text-white" onClick={toggle}>
                  {currentMenu.profile}
                </Link>
              </li>
            )}
          </ul>

          {/* Intelligence Platform Group */}
          <CollapsibleGroup title={currentMenu.intelligencePlatform}>
            <ul className="mt-2 space-y-2">
              <li>
                <Link
                  to="/intelligent-platform"
                  title="Overview"
                  className="text-base text-white ml-4 block"
                  onClick={toggle}
                >
                  Overview
                </Link>
              </li>
              <li>
                <Link
                  to="/robot-host"
                  title="Robot Host"
                  className="text-base text-white ml-4 block"
                  onClick={toggle}
                >
                  {currentMenu.robotHost}
                </Link>
              </li>
              <li>
                <Link
                  to="/taki-ticketing"
                  title="Ticketing"
                  className="text-base text-white ml-4 block"
                  onClick={toggle}
                >
                  {currentMenu.takiTicketing}
                </Link>
              </li>
              <li>
                <Link
                  to="/djrequest"
                  title="DJ Request"
                  className="text-base text-white ml-4 block"
                  onClick={toggle}
                >
                  {currentMenu.takiDJRequest}
                </Link>
              </li>
              <li>
                <Link
                  to="/taki-rental"
                  title="Rental"
                  className="text-base text-white ml-4 block"
                  onClick={toggle}
                >
                  {currentMenu.takiRental}
                </Link>
              </li>
              <li>
                <Link
                  to="/taki-marketing"
                  title="Marketing"
                  className="text-base text-white ml-4 block"
                  onClick={toggle}
                >
                  {currentMenu.takiMarketing}
                </Link>
              </li>
            </ul>
          </CollapsibleGroup>

          {/* Venues Group */}
          <CollapsibleGroup title={currentMenu.venuesGroup}>
            <ul className="mt-2 space-y-2">
              <li>
                <Link
                  to="/takitakikatowice"
                  title="Taki Taki Katowice"
                  className="text-base text-white ml-4 block"
                  onClick={toggle}
                >
                  {currentMenu.takitakikatowice}
                </Link>
              </li>
            </ul>
          </CollapsibleGroup>

          {/* Music Collectives Group */}
          <CollapsibleGroup title={currentMenu.musicCollectives}>
            <ul className="mt-2 space-y-2">
              <li>
                <Link
                  to="/foreigners"
                  title="Foreigners"
                  className="text-base text-white ml-4 block"
                  onClick={toggle}
                >
                  {currentMenu.foreigners}
                </Link>
              </li>
            </ul>
          </CollapsibleGroup>

          {/* Festivals Group */}
          <CollapsibleGroup title="FESTIVALS">
            <ul className="mt-2 space-y-2">
              <li>
                <Link
                  to="/takiton"
                  title="Takiton Festival"
                  className="text-base text-white ml-4 block"
                  onClick={toggle}
                >
                  TAKITON
                </Link>
              </li>
              {/* Additional festival links can be added here */}
            </ul>
          </CollapsibleGroup>

          {/* For Individuals */}
          <CollapsibleGroup title={currentMenu.forIndividuals}>
            <ul className="mt-2 space-y-2">
              <li>
                <Link to="/services" title="Services" className="text-base text-white ml-4 block" onClick={toggle}>
                  {currentMenu.services}
                </Link>
              </li>
            </ul>
          </CollapsibleGroup>

          {/* For Business Owners */}
          <CollapsibleGroup title="FOR BUSINESS OWNERS">
            <ul className="mt-2 space-y-2">
              <li>
                <Link to="/forbars" title="For Bars" className="text-base text-white ml-4 block" onClick={toggle}>
                  {currentMenu.forBars}
                </Link>
              </li>
              <li>
                <Link to="/forclubs" title="For Clubs" className="text-base text-white ml-4 block" onClick={toggle}>
                  {currentMenu.forClubs}
                </Link>
              </li>
              <li>
                <Link
                  to="/foreventorganizers"
                  title="For Event Organizers"
                  className="text-base text-white ml-4 block"
                  onClick={toggle}
                >
                  {currentMenu.forEventOrganizers}
                </Link>
              </li>
            </ul>
          </CollapsibleGroup>

          {isAuthenticated && (
            <div className="mt-3 text-base text-white cursor-pointer" onClick={handleLogout}>
              {currentMenu.logout}
            </div>
          )}

          {/* Social Media Section */}
          <section className="mt-3">
            <ul className="flex space-x-4 mt-2 ml-4">
              <li>
                <a href="https://www.facebook.com/takitakikatowice" title="Facebook" className="text-white">
                  <FaFacebook size={24} />
                </a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@takitakikatowice" title="TikTok" className="text-white">
                  <FaTiktok size={24} />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/takitakientertainment" title="Instagram" className="text-white">
                  <FaInstagram size={24} />
                </a>
              </li>
              <li>
                <a href="https://chat.whatsapp.com/Kd6k8NtVI65H638LEiAY3Z" title="WhatsApp" className="text-white">
                  <FaWhatsapp size={24} />
                </a>
              </li>
            </ul>
          </section>
        </nav>
      </aside>
    </>
  );
};

export default SideMenu;

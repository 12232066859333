import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLanguage } from "Contexts/LanguageContext";
import { FaBehance, FaInstagram, FaSpotify, FaYoutube } from "react-icons/fa";
import { useForm, ValidationError } from "@formspree/react";

const premadeMessage = "Hello, I'm interested in learning more about Foreigners.";

const translations = {
  english: {
    page_title: "Foreigners - Futuristic Musical Collective",
    meta_description:
      "Discover Foreigners, a futuristic musical collective that shatters genre boundaries and slays the sound game. Experience a revolutionary fusion of exotic reggaeton, perreo, afro house, and latin tribal rhythms.",
    hero_title: "Foreigners",
    hero_description:
      "Break the limits of music with Foreigners. Our collective shatters genre boundaries and slays the sound game—uniting trailblazing DJs and visionary creative directors in a revolutionary fusion of exotic reggaeton, perreo, afro house, and latin tribal beats.",
    about_title: "About Foreigners",
    about_text:
      "Foreigners is a musical collective that brings together talents from diverse backgrounds to create a futuristic, dynamic, and unforgettable musical experience.",
    members_title: "Meet the Collective",
    final_cta_title: "Are you ready?",
    request_email: "Request via Email",
    request_whatsapp: "Request via WhatsApp",
    call_now: "Call Now",
    contact_form_title: "Get in Touch",
    contact_form_button: "Contact Form",
    contact_form_success: "Thank you for contacting us! We'll be in touch shortly.",
    contact_form_submit: "Submit",
  },
  spanish: {
    page_title: "Foreigners - Colectivo Musical Futurista",
    meta_description:
      "Descubre Foreigners, un colectivo musical futurista que rompe las barreras de los géneros y arrasa con el sonido. Experimenta una fusión revolucionaria de reggaetón exótico, perreo, afro house y tribal latino.",
    hero_title: "Foreigners",
    hero_description:
      "Rompe los límites de la música con Foreigners. Nuestro colectivo derriba las barreras de los géneros y arrasa con el sonido, uniendo DJs pioneros y directores creativos visionarios en una fusión revolucionaria de reggaetón exótico, perreo, afro house y ritmos latinos tribales.",
    about_title: "Acerca de Foreigners",
    about_text:
      "Foreigners es un colectivo musical que reúne talentos de diversos orígenes para crear una experiencia musical futurista, dinámica e inolvidable.",
    members_title: "Conoce al Colectivo",
    final_cta_title: "¿Estás listo?",
    request_email: "Solicitar por Correo",
    request_whatsapp: "Solicitar por WhatsApp",
    call_now: "Llama Ahora",
    contact_form_title: "Contáctanos",
    contact_form_button: "Formulario de Contacto",
    contact_form_success: "¡Gracias por contactarnos! Nos pondremos en contacto contigo pronto.",
    contact_form_submit: "Enviar",
  },
  polish: {
    page_title: "Foreigners - Futurystyczny Kolektyw Muzyczny",
    meta_description:
      "Poznaj Foreigners – futurystyczny kolektyw muzyczny, który przełamuje granice gatunków i rządzi dźwiękiem. Odkryj rewolucyjną fuzję egzotycznego reggaetonu, perreo, afro house i latynoskiego tribal.",
    hero_title: "Foreigners",
    hero_description:
      "Przekracz granice muzyki z Foreigners. Nasz kolektyw burzy ograniczenia gatunkowe i rządzi dźwiękiem, łącząc pionierskich DJ-ów z wizjonerskimi dyrektorami kreatywnymi w rewolucyjnej fuzji egzotycznego reggaetonu, perreo, afro house i latynoskiego tribal.",
    about_title: "O Foreigners",
    about_text:
      "Foreigners to kolektyw muzyczny zrzeszający talenty z różnych środowisk, tworzący futurystyczne, dynamiczne i niezapomniane doświadczenie muzyczne.",
    members_title: "Poznaj Kolektyw",
    final_cta_title: "Czy jesteś gotowy?",
    request_email: "Wyślij Email",
    request_whatsapp: "Wyślij WhatsApp",
    call_now: "Zadzwoń teraz",
    contact_form_title: "Skontaktuj się z nami",
    contact_form_button: "Formularz Kontaktowy",
    contact_form_success: "Dziękujemy za kontakt! Wkrótce się skontaktujemy.",
    contact_form_submit: "Wyślij",
  },
};

const members = [
  {
    name: "Kinder B",
    role: "DJ",
    bio: "Kinder B is a visionary DJ known for his innovative sound and dynamic performances.",
    image: "https://i.scdn.co/image/ab67616100005174911477e13730ea146a56f8c0",
    spotify: "https://open.spotify.com/artist/5jcedpjZNFVstmmDHizWsc",
    instagram: "https://www.instagram.com/kinderb_mp3/",
  },
  {
    name: "Jhoa Music",
    role: "DJ",
    bio: "Jhoa Music electrifies the crowd with his eclectic mixes and creative beats.",
    image: "https://i.imgur.com/gFl8Qdy.jpg",
    youtube: "https://www.youtube.com/@dj_jhoa",
    instagram: "https://www.instagram.com/dj_jhoa/",
  },
  {
    name: "Nicolas Tapias",
    role: "DJ",
    bio: "Nicolas Tapias creates unforgettable sets that captivate audiences.",
    image: "https://i.imgur.com/1UCfyyO.jpg",
    instagram: "https://www.instagram.com/nico_tapiasdj/",
  },
  {
    name: "ItsMaxine",
    role: "Creative Director",
    bio: "ItsMaxine is the creative mastermind behind Foreigners, shaping the collective’s unique vision and aesthetic.",
    image: "https://i.imgur.com/GkQimKV.jpg",
    behance: "https://www.behance.net/maxinecastill",
    instagram: "https://www.instagram.com/itsxmaxine/",
  },
];

// Reusable SocialButton component with futuristic styling
interface SocialButtonProps {
  url: string;
  label: string;
  icon: React.ReactNode;
}
const SocialButton: React.FC<SocialButtonProps> = ({ url, label, icon }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center space-x-2 transition-transform duration-300 hover:scale-110"
    >
      <div className="p-2 bg-gray-800 rounded-full border border-indigo-600 shadow-md">
        {icon}
      </div>
      <span className="text-sm font-semibold">{label}</span>
    </a>
  );
};

const ForeignersPage: React.FC = () => {
  const { language } = useLanguage();
  const lang = (["english", "spanish", "polish"].includes(language)
    ? language
    : "english") as "english" | "spanish" | "polish";
  const t = translations[lang];

  // State to control the visibility of the contact form modal
  const [contactFormModalOpen, setContactFormModalOpen] = useState(false);

  // Final call-to-action links (prefilled with a premade message)
  const emailLink = `mailto:takitakikatowice@gmail.com?subject=Foreigners Inquiry&body=${encodeURIComponent(
    premadeMessage
  )}`;
  const whatsappLink = `https://wa.me/48451110303?text=${encodeURIComponent(
    premadeMessage
  )}`;
  const callLink = "tel:+48451110303";

  return (
    <>
      <Helmet>
        <title>{t.page_title}</title>
        <meta name="description" content={t.meta_description} />
        <meta
          name="keywords"
          content="Foreigners, musical collective, futuristic, reggaeton, perreo, afro house, latin tribal, DJs, creative director, Instagram, Spotify, YouTube, Behance"
        />
        <link rel="canonical" href="https://yourwebsite.com/foreigners" />
      </Helmet>
      <div className="bg-gray-900 text-white">
        {/* Hero Section */}
        <section className="py-20 text-center px-4 pt-40">
          <h1 className="text-5xl md:text-6xl font-bold mb-4">{t.hero_title}</h1>
          <p className="max-w-2xl mx-auto text-xl md:text-2xl mb-8">{t.hero_description}</p>
        </section>

        {/* About Section */}
        <section className="py-12 px-4 bg-gray-800">
          <h2 className="text-4xl font-bold text-center mb-6">{t.about_title}</h2>
          <p className="max-w-3xl mx-auto text-center text-lg">{t.about_text}</p>
        </section>

        {/* Members Section */}
        <section className="py-12 px-4">
          <h2 className="text-4xl font-bold text-center mb-8">{t.members_title}</h2>
          <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {members.map((member, index) => (
              <div
                key={index}
                className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105"
              >
                <img src={member.image} alt={member.name} className="w-full h-60 object-cover" />
                <div className="p-4">
                  <h3 className="text-2xl font-bold">{member.name}</h3>
                  <p className="text-indigo-400 uppercase">{member.role}</p>
                  <p className="mt-2 text-lg">{member.bio}</p>
                  <div className="mt-4 flex flex-col space-y-2">
                    {member.spotify && (
                      <SocialButton
                        url={member.spotify}
                        label="Check on Spotify"
                        icon={<FaSpotify />}
                      />
                    )}
                    {member.youtube && (
                      <SocialButton
                        url={member.youtube}
                        label="Check on YouTube"
                        icon={<FaYoutube />}
                      />
                    )}
                    {member.behance && (
                      <SocialButton
                        url={member.behance}
                        label="Check on Behance"
                        icon={<FaBehance />}
                      />
                    )}
                    {member.instagram && (
                      <SocialButton
                        url={member.instagram}
                        label="Check on Instagram"
                        icon={<FaInstagram />}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Final Call-To-Action Section */}
        <section className="py-12 px-4 bg-gray-800 text-center">
          <h2 className="text-4xl font-bold mb-6">{t.final_cta_title}</h2>
          <div className="flex flex-col md:flex-row justify-center items-center gap-6">
            <a
              href={emailLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold transition-all duration-300 hover:opacity-90"
            >
              {t.request_email}
            </a>
            <a
              href={whatsappLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white font-bold transition-all duration-300 hover:opacity-90"
            >
              {t.request_whatsapp}
            </a>
            <a
              href={callLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-green-500 via-green-600 to-green-700 text-white font-bold transition-all duration-300 hover:opacity-90"
            >
              {t.call_now}
            </a>
            <button
              onClick={() => setContactFormModalOpen(true)}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 text-white font-bold transition-all duration-300 hover:opacity-90"
            >
              {t.contact_form_button}
            </button>
          </div>
        </section>

        {/* Contact Form Modal for Foreigners */}
        {contactFormModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-gray-800 p-6 rounded-lg w-96 relative">
              {/* Close Icon */}
              <button
                onClick={() => setContactFormModalOpen(false)}
                className="absolute top-2 right-2 text-xl text-gray-400 hover:text-white"
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-4">{t.contact_form_title}</h3>
              <ForeignersContactForm t={t} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

// ForeignersContactForm component using Formspree with endpoint "mldglnvg"
// The form includes only the fields: Name, Email, Phone, and Message (optional)
interface ForeignersContactFormProps {
  t: typeof translations["english"];
}
const ForeignersContactForm: React.FC<ForeignersContactFormProps> = ({ t }) => {
  const [state, handleSubmit] = useForm("mldglnvg");
  if (state.succeeded) {
    return <p className="text-green-400 mb-4">{t.contact_form_success}</p>;
  }
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        id="name"
        type="text"
        name="name"
        placeholder="Your Name"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <input
        id="email"
        type="email"
        name="email"
        placeholder="Your Email"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <input
        id="phone"
        type="tel"
        name="phone"
        placeholder="Your Phone Number"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <textarea
        id="message"
        name="message"
        placeholder="Your Message (optional)"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        rows={3}
      ></textarea>
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button
        type="submit"
        disabled={state.submitting}
        className="w-full px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
      >
        {t.contact_form_submit || "Submit"}
      </button>
    </form>
  );
};

export default ForeignersPage;
